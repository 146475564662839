<template>
  <div :class="`tw-mention-list items ${ hasGroupTitle ? 'has-group-title' : '' }`">
    <button
      :class="{
        'item': true,
        'is-selected': index === selectedIndex,
        'is-unmentionable': item.unmentionable,
        'is-group-title': item.isTitle
      }"
      v-for="(item, index) in showItems"
      :disabled="item.unmentionable"
      :key="index"
      @click="selectItem(index)"
    >
      {{ item.name }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'TwMentionList',
  props: {
    items: {
      type: Array,
      required: true
    },
    command: {
      type: Function,
      required: true
    },
    groupTitles: {
      type: Array,
      required: false,
      default: null
    }
  },

  data() {
    return {
      selectedIndex: -1
    };
  },
  computed: {
    showItems() {
      if(!this.groupTitles || this.groupTitles.length === 0) return this.items;

      const needTitles = [
        ...this.groupTitles.filter(title => {
          return this.items.map(item => item.groupId).includes(title.id)
        })
      ];
      let result = []
      for(const title of needTitles) {
        result.push({ ...title, isTitle: true, unmentionable: true });
        result = [ ...result.concat(this.items.filter(item => item.groupId === title.id)) ];
      }
      return result;
    },
    hasGroupTitle() {
      return this.groupTitles.length !== 0;
    }
  },
  watch: {
    items: {
      handler() {
        this.selectedIndex = this.searchMentionableItemIndex(-1);
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    searchMentionableItemIndex(startIndex, direction = 'down') {
      if(!Array.isArray(this.showItems) || this.showItems.length === 0) return;
      const isNoCandidate = this.showItems.every(item => item.unmentionable === true);
      if(isNoCandidate) return;

      let index = startIndex;
      if(direction === 'down') {
        do {
          index = (index + 1) % this.showItems.length;
        } while(this.showItems[index].unmentionable);
        return index;
      } else if (direction === 'up') {
        do {
          index = (index + this.showItems.length - 1) % this.showItems.length;
        } while(this.showItems[index].unmentionable);
        return index;
      }
    },
    onKeyDown({ event }) {
      if (event.key === 'ArrowUp') {
        this.upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        this.downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        this.enterHandler();
        return true;
      }

      return false;
    },

    upHandler() {
      this.selectedIndex = this.searchMentionableItemIndex(this.selectedIndex, 'up');
    },

    downHandler() {
      this.selectedIndex = this.searchMentionableItemIndex(this.selectedIndex);
    },

    enterHandler() {
      this.selectItem(this.selectedIndex);
    },

    selectItem(index) {
      const item = this.showItems[index];
      if(!item || item?.unmentionable) return
      
      this.command({ id: item.id, label: item.name });
    }
  }
};
</script>

<style lang="scss">
.tw-mention-list.items {
  position: relative;
  min-width: 120px;
  border-radius: 0.25rem;
  background: white;
  color: $color_black;
  overflow: hidden;
  font-size: 0.9rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0px 10px 20px rgba(0, 0, 0, 0.1);
  max-height: 460px;
  overflow-y: auto;

  &.has-group-title .item {
    padding: 0.2rem 0.5rem 0.2rem 1.5rem;
  }

  & .item {
    display: block;
    width: 100%;
    text-align: left;
    background: transparent;
    border: none;
    padding: 0.2rem 0.5rem;

    &:not(.is-unmentionable).is-selected,
    &:not(.is-unmentionable):hover {
      color: $color_dark_blue;
      background: rgba($color_dark_blue, 0.1);
      cursor: pointer;
    }

    &.is-unmentionable {
      color: $color_black;
      font-weight: 600;
      padding-left: 0.5rem;
    }

    &.is-group-title:not(:first-of-type) {
      border-top: 1px solid $color_gray_350;
      margin-top: 4px;
    }
  }
}

</style>
