<template src="./ProcessDetail.html"></template>

<script>
import _ from 'lodash';
import { ABLE_FLG, PROCESS_TYPE, PROCESS_OPERATION, RESPONSIBLE_FLG, CO_SUBMIT_FLG, USER_ROLE_CD } from 'lib-tw-common';
import { $api } from '@/store/ApiClient';
import mixinProcessDetail from '@/utils/mixinProcessDetail.js';
import schemas from '@/dictionaries/processes/fdcor.json';

export default {
  name: 'ProcessDetail',
  mixins: [mixinProcessDetail],
  data() {
    return {
      processId: this._processId.toUpperCase(),
      processType: PROCESS_TYPE.FDCOR,
      tradeManagement: null,
      schemas: schemas,
      rules: this.createRules(schemas.tables),
      couponInfo: {}, // COクーポン情報
      couponRequestTag: null, // COクーポン情報取得BFFのキャンセル用タグ
    };
  },
  methods: {
    async fetch(updateCommentOnly = false, requiresLatestProcessInFlow = undefined) {
      // bff_cr_04 CO申請詳細初期表示BFF
      // NOTE: 最新データを再取得し登録・変更ドロワーのみに適用する場合はrequiresLatestProcessInFlowを受け取る
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/co-request/{entityId}/{processId}/{processSeq}/initial',
          path: {
            entityId: this.entityId,
            processId: this.processId,
            processSeq: parseInt(this.processSeq)
          },
          query: {
            processTrx: this.versionNo,
            // フローの中で最新のプロセスのデータの再取得を依頼するフラグ
            requiresLatestProcessInFlow: requiresLatestProcessInFlow
          }
        },
        tag: this.pushCancelTag(),
      };

      await $api
        .request(params)
        .then(async res => {
          if (!this.checkEditPermission(res)) {
            return;
          }
          if(updateCommentOnly) {
            this.res.chats = res.chats;
          } else {
            if (requiresLatestProcessInFlow) {
              // 他プロセスで更新された最新データがあり再取得した場合は登録・変更ドロワーのみに適用
              this.cloneItems = this.cloneDeep(this.formatResponse(res));
            } else {
              // 通常時の初期表示
              if (!this.isArchive && res.tradeManagement.mailChatDocumentAbleFlg === ABLE_FLG.TRUE && !this.communicationDestinations.from) {
                this.getCommunicationDestinations();
              }
              this.res = this.formatResponse(res);
              this.cloneItems = this.cloneDeep(this.res);
              this.roles = {
                from: res.from,
                to: res.to,
                owner: res.owner
              };
              // Document signerプルダウン項目取得
              if (this.roles.from && this.roles.from.userRoleCd === USER_ROLE_CD.PIC) {
                this.$store.dispatch('GET_CO_DOCUMENT_SIGNERS', this.roles.from);
              }
              requestAnimationFrame(() => {
                this.tradeManagement = this.res.tradeManagement || {};
              });
            }
          }
          if (_.last(this.res.chats)) {
            this.readComment(_.last(this.res.chats).chatManageId);
          }
        })
        .catch(err => {
          if (err.isCanceled) {
            return;
          }
          this.$store.dispatch('SHOW_ALERT', err.message);
        });
    },
    // 初期表示レスポンス整形
    formatResponse(res) {
      // linkageGoodsTypeのレスポンスの階層をリクエストに合わせる
      res.goodsTypeMasterListForResponse = _.get(res, 'linkageGoodsLineType.goodsTypeMasterListForResponse') || [];
      res.linkageGoodsLineType = _.get(res, 'linkageGoodsLineType.goodsType') || [];
      // coSubmitFlagがレスポンスにないので付与する
      if (res.linkageGoodsLineCo) {
        res.linkageGoodsLineCo = _.map(res.linkageGoodsLineCo, goods => {
          return {
            ...goods,
            certificateOfOriginGrp: {
              coSubmitFlg: CO_SUBMIT_FLG.TRUE,
            }
          };
        });
      }
      return res;
    },
    // C/O申請画面でCo Document Signerに関する項目（Co Applicant Name以外）の入力がない場合は、「輸出船積依頼」・「船積書類作成」で入力されている値を入力する
    // ドロワーを開く場合にこのメソッドを呼び出す
    setCoDocumentSignerInfo() {
      // 全項目がCO申請で未入力の場合のみ「輸出船積依頼」・「船積書類作成」で入力されている値を入力する（CO申請プロセスにおいて未修正の状態であるため）
      const needSet =
        (!this.cloneItems.linkageFinalDocs.certificateOfOriginGrp.coSignerId1 && !this.cloneItems.linkageFinalDocs.certificateOfOriginGrp.coSignerName1)
        && (!this.cloneItems.linkageFinalDocs.certificateOfOriginGrp.coSignerTitle1)
        && (!this.cloneItems.linkageFinalDocs.certificateOfOriginGrp.coInvoiceSignerId1 && !this.cloneItems.linkageFinalDocs.certificateOfOriginGrp.coInvoiceSignerName1);
      if(!needSet) return;
      this.cloneItems.linkageFinalDocs.certificateOfOriginGrp.coSignerId1 = this.cloneItems.linkageFinalDocs.certificateOfOriginGrp.coSignerId1 || this.cloneItems.linkageFinalDocs.documentsGrp.coSignerId;
      this.cloneItems.linkageFinalDocs.certificateOfOriginGrp.coSignerName1 = this.cloneItems.linkageFinalDocs.certificateOfOriginGrp.coSignerName1 || this.cloneItems.linkageFinalDocs.documentsGrp.coSignerName;
      this.cloneItems.linkageFinalDocs.certificateOfOriginGrp.coSignerTitle1 = this.cloneItems.linkageFinalDocs.certificateOfOriginGrp.coSignerTitle1 || this.cloneItems.linkageFinalDocs.documentsGrp.coSignerTitle;
      this.cloneItems.linkageFinalDocs.certificateOfOriginGrp.coInvoiceSignerId1 = this.cloneItems.linkageFinalDocs.certificateOfOriginGrp.coInvoiceSignerId1 || this.cloneItems.linkageFinalDocs.documentsGrp.coInvoiceSignerId;
      this.cloneItems.linkageFinalDocs.certificateOfOriginGrp.coInvoiceSignerName1 = this.cloneItems.linkageFinalDocs.certificateOfOriginGrp.coInvoiceSignerName1 || this.cloneItems.linkageFinalDocs.documentsGrp.coInvoiceSignerName;
    },
    async approvalRequest(action, documents, overwriting = false) {
      // COクーポン情報取得BFFキャンセル
      if (this.couponRequestTag) {
        $api.cancelRequests(this.couponRequestTag);
        this.couponRequestTag = null;
      }
      // 商品明細COのチェック
      if (!this.cloneItems.linkageGoodsLineCo || !this.cloneItems.linkageGoodsLineCo.length || _.every(this.cloneItems.linkageGoodsLineCo, goods => {
        return goods.certificateOfOriginGrp.coSubmitFlg !== CO_SUBMIT_FLG.TRUE;
      })) {
        this.$store.dispatch('SHOW_ALERT', 'Please select at least one from Goods Line (CO).');
        return;
      }
      this.$store.commit('START_PROCESS');
      // 商品のreferenceNoGrpをセット
      this.setReferenceNoGrp();
      const isDeleteRequest = this.tradeManagement.eventCancel || action === PROCESS_OPERATION.T_SAVING_DEL;
      // 削除申請時は項目データを送信しない、バリデーションをしない
      if (isDeleteRequest || await this.checkValidate()) {
        // bff_cr_05 CO申請承認依頼BFF
        const params = {
          lslConfig: {
            serviceCode: 'tw-transaction-bff-api',
            apiCode: 'post_/v1/co-request/{entityId}/{processId}/{processSeq}',
            path: {
              entityId: this.entityId,
              processId: this.processId,
              processSeq: parseInt(this.processSeq)
            }
          },
          data: isDeleteRequest ? {
            meta: {
              tradingId: this.tradeManagement.tradingId,
              tradingFlowId: this.tradeManagement.tradingFlowId,
              updateDate: this.tradeManagement.updateDate,
              action: action,
              companyId: this.companyId
            },
            updateCommonItemsExclusiveFlag1: overwriting,
            updateCommonItemsEntitySpecificItemsExclusiveFlag2: overwriting,
            overwritePredecessorEntityExclusiveFlag3: overwriting,
            overwritingCommonItemsEntitySpecificItemsExclusiveFlag4: overwriting,
          } : _.omit({
            meta: {
              tradingId: this.tradeManagement.tradingId,
              tradingFlowId: this.tradeManagement.tradingFlowId,
              updateDate: this.tradeManagement.updateDate,
              action: action,
              companyId: this.companyId
            },
            updateCommonItemsExclusiveFlag1: overwriting,
            updateCommonItemsEntitySpecificItemsExclusiveFlag2: overwriting,
            overwritePredecessorEntityExclusiveFlag3: overwriting,
            overwritingCommonItemsEntitySpecificItemsExclusiveFlag4: overwriting,
            ...this.rejectHiddenGroups(),
            documents: documents,
          }, ['emailTransmissionHistories', 'chats', 'tradeManagement', 'requestApproveHisTrx', 'updateHistory'])
        };

        $api
          .request(params)
          // eslint-disable-next-line no-unused-vars
          .then(res => {
            // COクーポン情報をリセット
            this.couponInfo = {};
            this.$store.commit('END_PROCESS');
            setTimeout(() => {
              this.$store.dispatch('SHOW_COMPLETED');
            }, 500);
            if (this.$route.name.includes('EditProcess')) {
              this.$router.replace({
                name: `Process${_.capitalize(this.processId.replace(/\d/g, ''))}`,
                params: { entityId: this.entityId, processSeq: this.processSeq, _processId: this.processId.toLowerCase() }
              });
            }
            this.fetch();
          })
          .catch(err => {
            this.$store.commit('END_PROCESS');
            if (this.isConflict(err)) {
              this.$store.dispatch('SHOW_CONFIRM', err.message)
              .then(() => {
                this.approvalRequest(action, documents, true);
              })
              .catch(() => {});
            } else {
              this.$store.dispatch('SHOW_ALERT', err.message);
            }
          });
      } else {
        this.$store.commit('END_PROCESS');
        this.$store.dispatch('SHOW_ALERT', 'Please check the input and try again.');
      }
    },
    async save(action, documents, overwriting = false, isDraftPdf = false) {
      // COドラフトPDF発行申請の場合はisDraftPdfをtrueにして受け取る
      // Request Draftの場合はApply時と同様の商品明細COのチェック
      if (isDraftPdf && (!this.cloneItems.linkageGoodsLineCo || !this.cloneItems.linkageGoodsLineCo.length || _.every(this.cloneItems.linkageGoodsLineCo, goods => {
        return goods.certificateOfOriginGrp.coSubmitFlg !== CO_SUBMIT_FLG.TRUE;
      }))) {
        this.$store.dispatch('SHOW_ALERT', 'Please select at least one from Goods Line (CO).');
        return;
      }
      // COクーポン情報取得BFFキャンセル
      if (this.couponRequestTag) {
        $api.cancelRequests(this.couponRequestTag);
        this.couponRequestTag = null;
      }
      this.$store.commit('START_PROCESS');
      // 下書き仕様ののバリデーションにする
      // Request Draft押下時はApply時と同様のバリデーションを行う
      const normalRules = this.createRules(schemas.tables, false);
      if (!isDraftPdf) {
        this.rules = this.createRules(schemas.tables, true);
        this.draftValid = true;
      }

      if (await this.checkValidate()) {
        // bff_cr_02 CO申請下書き保存BFF
        // bff_cr_09 COドラフトPDF発行申請BFF
        const params = {
          lslConfig: {
            serviceCode: 'tw-transaction-bff-api',
            apiCode: `post_/v1/co-request/{entityId}/{processId}/{processSeq}/${isDraftPdf ? 'draft-pdf' : 'wk'}`,
            path: {
              entityId: this.entityId,
              processId: this.processId,
              processSeq: parseInt(this.processSeq)
            }
          },
          data: _.omit({
            meta: {
              tradingId: this.tradeManagement.tradingId,
              tradingFlowId: this.tradeManagement.tradingFlowId,
              updateDate: this.tradeManagement.updateDate,
              action: action,
              companyId: this.companyId
            },
            updateCommonItemsExclusiveFlag1: overwriting,
            updateCommonItemsEntitySpecificItemsExclusiveFlag2: overwriting,
            overwritePredecessorEntityExclusiveFlag3: overwriting,
            overwritingCommonItemsEntitySpecificItemsExclusiveFlag4: overwriting,
            ...this.rejectHiddenGroups(),
            documents: documents,
          }, ['emailTransmissionHistories', 'chats', 'tradeManagement', 'requestApproveHisTrx', 'updateHistory'])
        };

        $api
          .request(params)
          // eslint-disable-next-line no-unused-vars
          .then(res => {
            // COクーポン情報をリセット
            this.couponInfo = {};
            this.$store.commit('END_PROCESS');
            setTimeout(() => {
              this.$store.dispatch('SHOW_COMPLETED');
            }, 500);
            this.$router.replace({
              name: `Process${_.capitalize(this.processId.replace(/\d/g, ''))}`,
              params: { entityId: this.entityId, processSeq: this.processSeq, _processId: this.processId.toLowerCase() }
            });
            // 通常仕様のバリデーションに戻す
            this.rules = normalRules;
            this.draftValid = false;
            this.fetch();
          })
          .catch(err => {
            this.$store.commit('END_PROCESS');
            if (this.isConflict(err)) {
              this.$store.dispatch('SHOW_CONFIRM', err.message)
              .then(() => {
                // 通常仕様のバリデーションに戻す
                this.rules = normalRules;
                this.draftValid = false;
                this.save(action, documents, true, isDraftPdf);
              })
              .catch(() => {
                // 通常仕様のバリデーションに戻す
                this.rules = normalRules;
                this.draftValid = false;
              });
            } else {
              this.$store.dispatch('SHOW_ALERT', err.message);
              // 通常仕様のバリデーションに戻す
              this.rules = normalRules;
              this.draftValid = false;
            }
          });
      } else {
        this.$store.commit('END_PROCESS');
        this.$store.dispatch('SHOW_ALERT', 'Please check the input and try again.');
        // 通常仕様のバリデーションに戻す
        this.rules = normalRules;
        this.draftValid = false;
      }
    },
    approve(action, comment, to = false) {
      this.$store.commit('START_PROCESS');
      // bff_cr_06 CO申請承認BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'post_/v1/co-request/{entityId}/{processId}/{processSeq}/approve',
          path: {
            entityId: this.entityId,
            processId: this.processId,
            processSeq: parseInt(this.processSeq)
          }
        },
        data: {
          tradingId: this.tradeManagement.tradingId,
          tradingFlowId: this.tradeManagement.tradingFlowId,
          updateDate: this.tradeManagement.updateDate,
          action: action,
          companyId: this.companyId,
          comment: comment,
        }
      };

      $api
        .request(params)
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.$store.commit('END_PROCESS');
          setTimeout(async() => {
            this.$store.dispatch('SHOW_COMPLETED');
            if (to) {
              await new Promise((resolve) => setTimeout(resolve, 500));
              this.$router.push(to);
            }
          }, 500);
          if (!to) this.fetch();
        })
        .catch(err => {
          this.$store.commit('END_PROCESS');
          this.$store.dispatch('SHOW_ALERT', err.message);
        });
    },
    withdraw(action, comment, to = false) {
      this.$store.commit('START_PROCESS');
      // bff_cr_07 CO申請差戻・引戻・再確認依頼BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'post_/v1/co-request/{entityId}/{processId}/{processSeq}/re',
          path: {
            entityId: this.entityId,
            processId: this.processId,
            processSeq: parseInt(this.processSeq)
          }
        },
        data: {
          tradingId: this.tradeManagement.tradingId,
          tradingFlowId: this.tradeManagement.tradingFlowId,
          updateDate: this.tradeManagement.updateDate,
          action: action,
          companyId: this.companyId,
          comment: comment,
        }
      };

      $api
        .request(params)
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.$store.commit('END_PROCESS');
          setTimeout(async() => {
            this.$store.dispatch('SHOW_COMPLETED');
            if (to) {
              await new Promise((resolve) => setTimeout(resolve, 500));
              this.$router.push(to);
            }
          }, 500);
          if (!to) this.fetch();
        })
        .catch(err => {
          this.$store.commit('END_PROCESS');
          this.$store.dispatch('SHOW_ALERT', err.message);
        });
    },
    deleteDraft(comment) {
      this.$store.commit('START_PROCESS');
      // bff_cr_03 CO申請削除BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'post_/v1/co-request/{entityId}/{processId}/{processSeq}/delete',
          path: {
            entityId: this.entityId,
            processId: this.processId,
            processSeq: parseInt(this.processSeq)
          }
        },
        data: {
          tradingId: this.tradeManagement.tradingId,
          tradingFlowId: this.tradeManagement.tradingFlowId,
          updateDate: this.tradeManagement.updateDate,
          companyId: this.companyId,
          comment: comment,
        }
      };

      $api
        .request(params)
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.$store.commit('END_PROCESS');
          setTimeout(() => {
            this.$store.dispatch('SHOW_COMPLETED');
          }, 500);
          this.fetch();
        })
        .catch(err => {
          this.$store.commit('END_PROCESS');
          this.$store.dispatch('SHOW_ALERT', err.message);
        });
    },
    register(action, documents) {
      action = this.tradeManagement.responsibleFlg === RESPONSIBLE_FLG.FROM ? PROCESS_OPERATION.F_APPROVE_REQ : PROCESS_OPERATION.T_APPROVE_REQ;
      this.approvalRequest(action, documents);
    },
    async checkValidate() {
      const lkResult = await this.validationCheck('form');
      const tsResult = await this.tsValidationCheck(this.$refs.form);
      return tsResult && lkResult;
    },
  }
};
</script>

<style lang="scss" scoped src="./ProcessDetail.scss"></style>
