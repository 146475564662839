<template>
  <div class="tw_process_detail">
    <div class="left">
      <div class="back" @click="back">
        <img src="@/assets/images/icons/chevron_left_gray800.svg">Back
      </div>
      <div class="head">
        <div class="column" style="margin-right: 50px;">
          <tw-status-bar v-if="typeof tradeManagement.processProgressStatus === 'number'" :statusCode="tradeManagement.processProgressStatus" :approval="approvalProcess" />
          <tw-from-to :from="from" :to="to" />
        </div>
        <el-select v-if="isArchive" v-model="version" @change="changeVersion" placeholder="Select" style="width: 120px;margin-bottom: 22px">
          <el-option
            v-for="version in versions"
            :key="version.version"
            :label="version.version"
            :value="version.processTrx">
          </el-option>
        </el-select>
        <div class="column">
          <el-dropdown v-if="isCombined" class="button-original-tradings" trigger="click" placement="bottom-start" @command="showTradingDetails" :tabindex="-1">
            <tw-button  type="secondary" size="small">Original Tradings</tw-button>
            <el-dropdown-menu>
              <div class="dropdown-title">Select Flow</div>
              <el-dropdown-item v-for="(flow, index) in tradeManagement.parentFlowInfo" :key="index" :command="flow" :divided="index === 0">{{ flow.parentTradingId }}/{{ flow.parentFlowId }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <tw-button v-if="needApplyButton" type="primary" size="small" @click="approvalRequest">Apply</tw-button>
          <tw-button v-if="needRegisterButton" type="primary" size="small" @click="register">Register</tw-button>
          <tw-button v-if="needEditButton" type="secondary" size="small" icon="edit" @click="edit">Edit</tw-button>
          <el-dropdown trigger="click" placement="bottom-start" @command="onDropDownClick" :tabindex="-1">
            <tw-button class="menu_button" type="secondary" size="small" icon="menu">Menu</tw-button>
            <!-- <i class="el-dropdown-link ellipsis" /> -->
            <el-dropdown-menu>
              <el-dropdown-item command="tsv">TSV Download</el-dropdown-item>
              <el-dropdown-item v-if="processType === PROCESS_TYPE.ECREQ || processType === PROCESS_TYPE.ICREQ || processType === PROCESS_TYPE.FDDOC" command="ivPdf">I/V PDF Download</el-dropdown-item>
              <el-dropdown-item v-if="processType === PROCESS_TYPE.ECREQ || processType === PROCESS_TYPE.ICREQ || processType === PROCESS_TYPE.FDDOC" command="plPdf">P/L PDF Download</el-dropdown-item>
              <el-dropdown-item command="urlCopy">URL Copy</el-dropdown-item>
              <el-dropdown-item v-if="needReturnButton" command="return">Return</el-dropdown-item>
              <el-dropdown-item v-if="needWithdrawButton" command="withdraw">Withdraw</el-dropdown-item>
              <el-dropdown-item v-if="needCancelDeleteRequestButton" command="cancelDeleteRequest">Cancel deletion request</el-dropdown-item>
              <el-dropdown-item v-if="needFromDeleteButton" command="fromDeleteRequest">Delete</el-dropdown-item>
              <el-dropdown-item v-if="needToDeleteButton" command="toDeleteRequest">Delete</el-dropdown-item>
              <el-dropdown-item v-if="needDeleteSavedDataButton" command="deleteSavedData">Delete Saved Data</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

      <tw-approval-request v-if="needApprovalArea" :tradeManagement="tradeManagement" />

      <!-- チェックボックスグループ -->
      <tw-check-boxes v-if="needTypeofWorks" :items="checkItems" head="Types of Work" :isCheckBox="false" />

      <div class="sub_buttons">
        <!-- トグルボタン -->
        <tw-button type="secondary" size="small" class="toggle_button" :class="{ open: expand }" @click="openToggle">
          <img src="@/assets/images/icons/view_all.svg">
        </tw-button>
        <!-- グラフィカルボタン -->
        <tw-button v-if="needGraphical" type="secondary" icon="graphical" size="small" @click="showGraphical">Graphical Mode</tw-button>
      </div>

      <!-- タブ -->
      <div ref="tabs" class="tabs" style="width: 100%">
        <div class="tab" v-if="schemaTab1.length" :class="{active: activeTab === 0}" @click="switchTab(0)">Goods</div>
        <div class="tab" v-if="schemaTab4.length" :class="{active: activeTab === 3}" @click="switchTab(3)">Information</div>
        <div class="tab" v-if="schemaTab2.length" :class="{active: activeTab === 1}" @click="switchTab(1)">Players</div>
        <div class="tab" v-if="schemaTab3.length" :class="{active: activeTab === 2}" @click="switchTab(2)">Logistics</div>
        <div class="tab" v-if="hasLcdata" :class="{active: activeTab === 5}" @click="switchTab(5)">L/C</div>
        <div class="tab" v-if="schemaTab5.length" :class="{active: activeTab === 4}" @click="switchTab(4)">Undisclosed</div>
      </div>

      <table class="card">
        <!-- タブコンテンツ -->
        <table v-show="activeTab === 0" class="card" style="position: relative;z-index:2">
          <tw-process-list v-for="table in schemaTab1" :table="table" :key="table.name" :tab="0" />
        </table>
        <table v-show="activeTab === 3" class="card" style="position: relative;z-index:2">
          <tw-process-list v-for="table in schemaTab4" :table="table" :key="table.name" :tab="3" />
        </table>
        <table v-show="activeTab === 1" class="card" style="position: relative;z-index:2">
          <tw-process-list v-for="table in schemaTab2" :table="table" :key="table.name" :visibleIp="false" :tab="1" />
        </table>
        <table v-show="activeTab === 2" class="card" style="position: relative;z-index:2">
          <!-- Vessel Name -->
          <tr v-if="vesselName" class="vessel_name">
            <td colspan="3">
              <tw-process-group-list-item
                :item="vesselName.item"
                :tableName="vesselName.tableName"
                :groupName="vesselName.groupName"
                :serialIndexes="{}"
                :serialItems="{}"
                :tab="2"
              />
              <div style="display: flex">
                <tw-process-group-list-item
                  :item="voyageNo.item"
                  :tableName="voyageNo.tableName"
                  :groupName="voyageNo.groupName"
                  :serialIndexes="{}"
                  :serialItems="{}"
                  :tab="2"
                />
                <tw-process-group-list-item
                  v-if="s.checkTransportation('linkageTransportation.containerGrp')"
                  :item="vesselBookingNo.item"
                  :tableName="vesselBookingNo.tableName"
                  :groupName="vesselBookingNo.groupName"
                  :serialIndexes="{}"
                  :serialItems="{}"
                  :tab="2"
                />
              </div>
            </td>
          </tr>
          <tw-process-list v-for="table in schemaTab3" :table="table" :key="table.name" :tab="2" />
        </table>
        <table v-show="activeTab === 5" class="card" style="position: relative;border-radius: 0 0 6px 6px;z-index:2">
          <TwLcPoolProcessList v-if="hasLcdata" :initialOpen="false" :initSelectLc="false" :isProcess="true" :tab="5" />
        </table>
        <table v-show="activeTab === 4" class="card" style="position: relative;z-index:2">
          <template v-if="processType === PROCESS_TYPE.MIRIP">
            <TwIpRequestProcessList v-for="table in IpRequestSchema" :table="table" :key="table.name" :tab="4" />
          </template>
          <tw-process-list v-for="table in schemaTab5" :table="table" :key="table.name" :tab="4" />
        </table>

        <!-- <tw-process-list v-for="table in schemas.tables" :table="table" :key="table.name" /> -->
      </table>
    </div>

    <div class="right" :class="{delete: tradeManagement.eventCancel}">
      <div v-if="bcStatus === BC_CREATE_STATUS.REGISTERING" class="blockchain_status">
        <video src="@/assets/movies/bc_validating.mp4" autoplay playsinline muted></video>
      </div>
      <div v-else-if="bcStatus === BC_CREATE_STATUS.REGISTRATION_END" class="blockchain_status">
        <video src="@/assets/movies/bc_commited.mp4" autoplay playsinline muted></video>
      </div>

      <tw-communication @reconfirm="reconfirm"/>
      <tw-lc-history v-if="processType === PROCESS_TYPE.LCACCEPT" />
      <tw-permit-history v-if="processType === PROCESS_TYPE.ECPERMIT || processType === PROCESS_TYPE.ICPERMIT" />
      <tw-ip-dn-history v-if="processType === PROCESS_TYPE.MIIP" />
      <tw-booking-history v-if="processType === PROCESS_TYPE.TPATPRB" />
      <tw-bl-history v-if="processType === PROCESS_TYPE.TPRBL || processType === PROCESS_TYPE.TPBLI" />
      <tw-documents @update-attached-files="updateAttachedFiles"/>
      <tw-approval-list v-if="approvalProcess" />
      <tw-update-history :items="updateHistoryItems" :getStatus="getUpdateHistoryAction" />
    </div>
    <img v-if="tradeManagement.eventCancel" class="delete" src="@/assets/images/delete.png">

    <!-- グラフィカルモード -->
    <el-drawer :visible.sync="isShowGraphical" size="100%" :modal="false" :withHeader="false">
      <TwGraphicalNetwork v-if="isShowGraphical" :schemas="schemas" @show-graphical="showGraphical" />
    </el-drawer>

    <!-- IV帳票PDF -->
    <tw-iv-pdf v-if="printIvPdf" @after-print="printIvPdf = false" />
    <!-- PL帳票PDF -->
    <tw-pl-pdf v-if="printPlPdf" @after-print="printPlPdf = false" />
  </div>
</template>

<script>
import _ from 'lodash';
import {
  ABLE_FLG,
  BC_CREATE_STATUS,
  RESPONSIBLE_FLG,
  PROCESS_OPERATION,
  USER_ROLE_CD,
  TYPE_OF_WORKS,
  PROCESS_TYPE,
  DOCUMENT_EDIT_FLG,
  PROCESS_OPERATION_VARIABLES,
  PROCESS_PROGRESS_STATUS
} from 'lib-tw-common';
import { processTabType } from '@/dictionaries/processTabType.js';
import TwButton from '@/components/atoms/TwButton';
import TwStatusBar from '@/components/atoms/TwStatusBar';
import TwFromTo from '@/components/molecules/TwFromTo';
import TwProcessGroupListItem from '@/components/molecules/TwProcessGroupListItem';
import TwApprovalList from '@/components/organisms/TwApprovalList';
import TwCommunication from '@/components/organisms/TwCommunication';
import TwDocuments from '@/components/organisms/TwDocuments';
import TwProcessList from '@/components/organisms/TwProcessList';
import TwLcPoolProcessList from '@/components/organisms/TwLcPoolProcessList';
import TwApprovalRequest from '@/components/organisms/TwApprovalRequest';
import TwUpdateHistory from '@/components/organisms/TwUpdateHistory';
import TwLcHistory from '@/components/organisms/TwLcHistory';
import TwPermitHistory from '@/components/organisms/TwPermitHistory';
import TwIpDnHistory from '@/components/organisms/TwIpDnHistory';
import TwBookingHistory from '@/components/organisms/TwBookingHistory';
import TwBlHistory from '@/components/organisms/TwBlHistory';
import TwCheckBoxes from '@/components/atoms/TwCheckBoxes';
// import TwGraphicalNetwork from '@/components/templates/TwGraphicalNetwork';
import TwIvPdf from '@/components/templates/TwIvPdf';
import TwPlPdf from '@/components/templates/TwPlPdf';
import mixinProcessTsvUtils from '@/utils/mixinProcessTsvUtils.js';
import { computed } from "@vue/composition-api";

export default {
  name: 'TwProcessDetail',
  inject: ['s'],
  provide() {
    return {
      activeTab: computed(() => this.activeTab),
    }
  },
  mixins: [mixinProcessTsvUtils],
  props: {
    schemas: Object,
    isArchive: Boolean,
    versions: Array,
    versionNo: [String, Number],
    from: Object,
    to: Object,
    roles: Object,
    tradeManagement: Object,
    bcStatus: Number,
    approvalProcess: Boolean,
    processId: String,
    processType: String
  },
  components: {
    TwButton,
    TwStatusBar,
    TwFromTo,
    TwApprovalList,
    TwCommunication,
    TwDocuments,
    TwProcessList,
    TwLcPoolProcessList,
    TwIpRequestProcessList: () => import('@/components/organisms/TwIpRequestProcessList'),
    TwApprovalRequest,
    TwUpdateHistory,
    TwLcHistory,
    TwPermitHistory,
    TwIpDnHistory,
    TwBookingHistory,
    TwBlHistory,
    TwCheckBoxes,
    TwGraphicalNetwork: () => import('@/components/templates/TwGraphicalNetwork'),
    TwIvPdf,
    TwPlPdf,
    TwProcessGroupListItem,
  },
  data() {
    return {
      version: this.versionNo ? parseInt(this.versionNo) : null,
      BC_CREATE_STATUS: BC_CREATE_STATUS,
      PROCESS_TYPE,
      expand: false,
      checkItems: [],
      initialCheckItems: [ // チェックボックス仮データ
        { value: 'workTypeDocumentation', label: 'Documentation' },
        { value: 'workTypeCustomsClearance', label: 'Customs Clearance' },
        this.processId.startsWith('EC') ? { value: 'workTypeLoading', label: 'Loading' } : { value: 'workTypeDischarging', label: 'Discharging' },
        { value: 'workTypeBooking', label: 'Booking' },
        { value: 'workTypeMarineIp', label: 'Marine IP' }
      ],
      isShowGraphical: false,
      activeTab: 0,
      printIvPdf: false,
      printPlPdf: false,
      vesselName: null,
    };
  },
  computed: {
    updateHistoryItems() {
      return _.get(this, 's.res.updateHistory') || [];
    },
    hasInputItem() {
      return _.some(this.schemas.tables, table => {
        return _.some(table.groups, group => {
          return _.some(group.children, item => {
            return item[this.tradeManagement.responsibleFlg === RESPONSIBLE_FLG.FROM ? 'from' : 'to'];
          });
        });
      });
    },
    // Editボタンの表示可否を返却します
    // ブロックチェーン書き込み実行後・Delete後は非表示。担当者ロール以外のユーザーは非表示
    needEditButton() {
      // アーカイブ閲覧時は非表示
      if (this.isArchive) {
        return false;
      }

      // To側のボールで入力項目が無ければ非表示
      // if (this.tradeManagement.responsibleFlg === RESPONSIBLE_FLG.TO && !this.hasInputItem) {
      //   return false;
      // }

      return this.tradeManagement.editAbleFlg === ABLE_FLG.TRUE;
    },
    // Applyボタンの表示可否を返却します
    // 「承認プロセス」Delete申請時 To担当
    // 「承認プロセス」で通常の申請時 To担当者（To側にTo側限定の入力必須項目がない場合）
    //   →厳密に言うとプロセス変更画面にTo担当者の入力「必須」項目が無い場合だが、現状全部ない。

    needApplyButton() {
      // アーカイブ閲覧時は非表示
      if (this.isArchive) {
        return false;
      }
      // 「承認プロセス」Delete申請時以外は非表示
      if (this.tradeManagement.responsibleFlg === RESPONSIBLE_FLG.FROM) {
        // Fromにボールがあるときは非表示
        return false;
      }
      // TO承認依頼が操作可能であれば表示
      return this.tradeManagement.actions.includes(PROCESS_OPERATION.T_APPROVE_REQ);
    },
    // Registerボタンの表示可否を返却します
    // 非承認プロセス時 To担当者に表示
    needRegisterButton() {
      // アーカイブ閲覧時は非表示
      if (this.isArchive) {
        return false;
      }

      return this.tradeManagement.actions.includes(PROCESS_OPERATION.T_DONE);
    },
    // Returnボタンの表示可否を返却します
    needReturnButton() {
      // アーカイブ閲覧時は非表示
      if (this.isArchive) {
        return false;
      }

      // To担当者に表示
      return this.tradeManagement.actions.includes(PROCESS_OPERATION.T_RECONFIRMATION);
    },
    // Withdrawボタンの表示可否を返却します
    needWithdrawButton() {
      // アーカイブ閲覧時は非表示
      if (this.isArchive) {
        return false;
      }

      // Todo: APIのtradeManagement.actionsでAGREEDステータスのCO申請にPROCESS_OPERATION.F_PULL_BACKの値が返却されないよう修正されたら削除する
      // CO申請にPROCESS_PROGRESS_STATUS.DONEは存在しないためAGREEDの場合のみ非表示
      if (this.processType === PROCESS_TYPE.FDCOR
        && this.tradeManagement.processProgressStatus === PROCESS_PROGRESS_STATUS.AGREED) {
        return false;
      }

      return this.tradeManagement.actions.includes(PROCESS_OPERATION.F_PULL_BACK) || this.tradeManagement.actions.includes(PROCESS_OPERATION.T_PULL_BACK);
    },
    // FromDeleteボタンの表示可否を返却します
    needFromDeleteButton() {
      // アーカイブ閲覧時は非表示
      if (this.isArchive) {
        return false;
      }

      return this.tradeManagement.actions.includes(PROCESS_OPERATION.F_SAVING_DEL);
    },
    // ToDeleteボタンの表示可否を返却します
    needToDeleteButton() {
      // アーカイブ閲覧時は非表示
      if (this.isArchive) {
        return false;
      }

      return this.tradeManagement.eventCancel && this.tradeManagement.actions.includes(PROCESS_OPERATION.T_SAVING_DEL);
    },
    // To下書き削除ボタンの表示可否を返却します
    needDeleteSavedDataButton() {
      // アーカイブ閲覧時は非表示
      if (this.isArchive) {
        return false;
      }

      return !this.tradeManagement.eventCancel && this.tradeManagement.actions.includes(PROCESS_OPERATION.T_SAVING_DEL);
    },
    // 承認部品の表示可否を返却します
    needApprovalArea() {
      // アーカイブ閲覧時は非表示
      if (this.isArchive) {
        return false;
      }

      return this.tradeManagement.actions.includes(PROCESS_OPERATION.F_APPROVE) || this.tradeManagement.actions.includes(PROCESS_OPERATION.T_APPROVE) || this.tradeManagement.actions.includes(PROCESS_OPERATION.F_REMAND) || this.tradeManagement.actions.includes(PROCESS_OPERATION.T_REMAND);
    },
    // 削除取り消しボタンの表示可否を返却します
    needCancelDeleteRequestButton() {
      // アーカイブ閲覧時は非表示
      if (this.isArchive) {
        return false;
      }
      return this.tradeManagement.eventCancel && this.tradeManagement.actions.includes(PROCESS_OPERATION.F_PULL_BACK);
    },
    // Types of Work の表示可否 輸出通関プロセス詳細 輸入通関プロセス詳細
    needTypeofWorks() {
      return this.s.processType === PROCESS_TYPE.ECREQ ||  this.s.processType === PROCESS_TYPE.ICREQ;
    },
    // // グラフィカルモード表示ボタンの表示可否
    needGraphical() {
      // 正式版のプロセスのみ対応表示 = 簡易版プロセスは非表示
      return ![PROCESS_TYPE.COCOS, PROCESS_TYPE.TPBL].includes(this.s.processType)
    },

    // Goodsタブの中身
    schemaTab1() {
      let goods = this.s.cloneDeep(_.filter(this.schemas.tables, table => {
        return table.variableName.includes('GoodsLine');
      }));
      const ORDER = [
        'linkageGoodsLinePrice',
        'linkageGoodsLine',
        'importGoodsLinePriceSeparate',
        'linkageGoodsLineType',
        'linkageGoodsLineCo',
        'linkageGoodsLinePacking',
      ];

      goods = _.orderBy(goods, [o => {return _.findIndex(ORDER, name => name === o.variableName)}], ['asc']);

      // linkageGoodsLinePriceにAmountGrpを追加
      let schemas = this.s.cloneDeep(this.schemas);
      const goodsProcessType = processTabType.goods[this.processType];
      if (goodsProcessType) {
        let table = _.find(this.schemas.tables, { variableName: goodsProcessType.tableName });
        goods[1].extend = _.filter(table.groups, group => {
          return goodsProcessType.groups.includes(group.variableName);
        });
        goods[1].extendTableName = table.variableName;
        goods[1].extendName = table.name;
      }
      // goodsタブに他のテーブルの該当項目を追加
      _.forEach(schemas.tables, table => {
        if (table.variableName === 'importGoodsLinePriceSeparate') {
          // 輸入商品価格個別テーブルが重複して追加されないようにする（expensesGrpを含むため）
          return;
        }
        if (goodsProcessType) {
          table.groups = _.filter(table.groups, group => {
            const isAllHide = _.find(group.children, { hide: false }) === undefined; // グループ内の項目のhideが全てtrueだったら除外
            return processTabType.goods.allGroup.includes(group.variableName) && !goodsProcessType.groups.includes(group.variableName) && !isAllHide;
          });
        } else {
          table.groups = _.filter(table.groups, group => {
            const isAllHide = _.find(group.children, { hide: false }) === undefined; // グループ内の項目のhideが全てtrueだったら除外
            return processTabType.goods.allGroup.includes(group.variableName) && !isAllHide;
          });
        }
        if (table.groups && table.groups.length > 0) goods.push(table)
      });
      return goods;
    },
    // Players情報タブの中身
    schemaTab2() {
      return this.groupFilter('players');
    },
    // Logistics情報タブの中身
    schemaTab3() {
      return this.groupFilter('logistics');
    },
    // 共通情報タブの中身
    schemaTab4() {
      return this.groupFilter('information');
    },
    // エンティティ個別タブの中身
    schemaTab5() {
      return _.filter(this.schemas.tables, table => {
        if (this.processType === PROCESS_TYPE.MIRIP) {
          // IP発行依頼プロセスの場合 processSeparateにIP発行依頼個別テーブルの情報が配列で含まれている 外出しのため除外する
          return !table.variableName.includes('processSeparate') && table.variableName.includes('Separate');
        }
        if (table.variableName === 'importGoodsLinePriceSeparate') {
          // 輸入商品価格個別テーブルはGoodsタブに表示するため除外する
          return false;
        }
        return table.variableName.includes('Separate') || table.variableName.includes('processSeparate');
      });
    },
    // IP発行依頼個別テーブルの中身
    IpRequestSchema() {
      return _.filter(this.schemas.tables, table => {
        return table.variableName.includes('processSeparate');
      });
    },
    // L/Cタブの表示
    hasLcdata() {
      const lcdata = _.get(this, 's.res.linkageLc.lcpools[0].lcdata');
      return lcdata && Object.keys(lcdata).length > 0;
    },
    // 混載ボタン（Original Tradings）の表示可否
    isCombined() {
      // 荷主かつ、parentFlowInfoが服数件存在する場合
      return this.$store.getters.isShipper && this.tradeManagement.parentFlowInfo && this.tradeManagement.parentFlowInfo.length;
    },
  },
  created() {
    // アクティブタブの初期表示
    for (let i = 0; i < 5; i++) {
      if (!_.isEmpty(this[`schemaTab${i + 1}`])) {
        this.activeTab = i;
        return
      }
    }
  },
  mounted() {
    this.checkItems = this.initTypeOfWorks();
  },
  watch: {
    's.res': {
      handler() {
        this.checkItems = this.initTypeOfWorks();
      },
    },
    hasLcdata(val) {
      if (!val && this.activeTab === 5) {
        // L/Cタブ選択中に紐付け解除した場合は、Infoタブを選択する
        this.activeTab = 3;
      }
    }
  },
  methods: {
    back() {
      if (history.length > 2) {
        this.$router.go(-1);
      } else {
        this.$router.push('/');
      }
    },
    // From担当者かどうかを返却します
    isFromPic() {
      return this.roles.from && this.roles.from.userRoleCd === USER_ROLE_CD.PIC;
    },
    // To担当者かどうかを返却します
    isToPic() {
      return this.roles.to && this.roles.to.userRoleCd === USER_ROLE_CD.PIC;
    },
    // From承認者かどうかを返却します
    isFromApprover() {
      return this.roles.from && this.roles.from.userRoleCd === USER_ROLE_CD.APPROVER;
    },
    // To承認者かどうかを返却します
    isToApprover() {
      return this.roles.to && this.roles.to.userRoleCd === USER_ROLE_CD.APPROVER;
    },
    changeVersion(version) {
      if (version !== this.tradeManagement.processTrx) {
        this.$emit('change-version', this.version);
      }
    },
    edit() {
      this.$emit('edit');
    },
    // 承認依頼
    approvalRequest() {
      const action = PROCESS_OPERATION.T_APPROVE_REQ;
      if (this.tradeManagement.eventCancel) {
        this.$store
          .dispatch('SHOW_CONFIRM', `Are you sure you want to submit deletion request?`)
          .then(() => {
            this.$emit('approval-request', action, this.createPostDocuments());
          })
          .catch(() => {});
      } else {
        this.$emit('approval-request', action, this.createPostDocuments());
      }
    },
    register() {
      const action = this.tradeManagement.responsibleFlg === RESPONSIBLE_FLG.FROM ? PROCESS_OPERATION.F_DONE : PROCESS_OPERATION.T_DONE;
      if (this.tradeManagement.eventCancel && action === PROCESS_OPERATION.T_DONE) {
        this.$store
          .dispatch('SHOW_CONFIRM', `Are you sure you want to submit deletion request?`)
          .then(() => {
            this.$emit('register', action, this.createPostDocuments());
          })
          .catch(() => {});
      } else {
        this.$emit('register', action, this.createPostDocuments());
      }
    },
    save() {
      const action = this.tradeManagement.responsibleFlg === RESPONSIBLE_FLG.FROM ? PROCESS_OPERATION.F_SAVING : PROCESS_OPERATION.T_SAVING;
      this.$emit('save', action, this.createPostDocuments());
    },
    onDropDownClick(command) {
      if (command === 'urlCopy') {
        this.$copyText(location.href).then(() => {
          this.$message({
            message: 'Copied!',
            type: 'success'
          });
        }, () => {});
      } else if (command === 'tsv') {
        this.tsvDownload();
        // this.$emit('tsv-download');
      } else if (command === 'return') {
        const action = PROCESS_OPERATION.T_RECONFIRMATION;
        this.$store
          .dispatch('SHOW_CONFIRM', `Are you sure you want to return this data?`)
          .then(() => {
            this.$emit('withdraw', action);
          })
          .catch(() => {});
      } else if (command === 'withdraw') {
        const action = this.tradeManagement.actions.includes(PROCESS_OPERATION.F_PULL_BACK) ? PROCESS_OPERATION.F_PULL_BACK : PROCESS_OPERATION.T_PULL_BACK;
        this.$store
          .dispatch('SHOW_CONFIRM', `Are you sure you want to withdraw this data?`)
          .then(() => {
            this.$emit('withdraw', action);
          })
          .catch(() => {});

      } else if (command === 'fromDeleteRequest') {
        this.$store
          .dispatch('SHOW_CONFIRM', `Are you sure you want to submit deletion request?`)
          .then(() => {
            // From削除申請、下書き削除
            this.$emit('delete-draft');
          })
          .catch(() => {});
      } else if (command === 'toDeleteRequest') {
        this.$store
          .dispatch('SHOW_CONFIRM', `Are you sure you want to submit deletion request?`)
          .then(() => {
            // To削除申請
            const action = PROCESS_OPERATION.T_SAVING_DEL;
            this.$emit('approval-request', action, this.createPostDocuments());
          })
          .catch(() => {});
      } else if (command === 'deleteSavedData') {
        // To下書き削除
        this.$store
          .dispatch('SHOW_CONFIRM', `Are you sure you want to submit deletion request?`)
          .then(() => {
            this.$emit('delete-draft');
          })
          .catch(() => {});
      } else if (command === 'cancelDeleteRequest') {
        this.$store
          .dispatch('SHOW_CONFIRM', `Are you sure you want to cancel the deletion request?`)
          .then(() => {
            const action = PROCESS_OPERATION.F_PULL_BACK;
            this.$emit('withdraw', action);
          })
          .catch(() => {});
      } else if (command === 'ivPdf') {
        this.printIvPdf = true;
      } else if (command === 'plPdf') {
        this.printPlPdf = true;
      }
    },
    // 依頼区分のチェックボックスを初期化します
    initTypeOfWorks() {
      const typeOfWorks = _.get(this.s.res, 'processSeparate.typeOfWorksGrp');
      if (!typeOfWorks) {
        return [];
      }
      let ret = [];
      _.forEach(this.initialCheckItems, (o) => {
        if (typeOfWorks[o.value] === TYPE_OF_WORKS.ON) {
          ret.push(o);
        }
      });
      return ret;
    },
    openToggle() {
      this.expand = !this.expand;
      if (this.expand) this.$emit('openAll');
      else this.$emit('closeAll');
    },
    // グラフィカルドロワー表示
    showGraphical() {
      this.isShowGraphical = !this.isShowGraphical;
    },
    // 送信する帳票オブジェクトを作成
    createPostDocuments() {
      const docs = _.map(this.s.res.documents, doc => {
        return {
          documentArchiveId:doc.documentArchiveId,
          documentEditFlg: DOCUMENT_EDIT_FLG.NO_EDIT,
        }
      });
      return docs;
    },
    // Player, Logistics, informationタブの項目フィルタリング処理
    groupFilter(type, isTsv) {
      let schemas = this.s.cloneDeep(this.schemas);
      let tables = _.forEach(schemas.tables, table => {
        table.groups = _.filter(table.groups, group => {
          // Vessel NameをLogisticsタブのいちばん上に移動
          if (!isTsv && type === 'logistics' && group.variableName === 'logisticsLoadingGrp') {
            this.vesselName = {
              item: this.s.cloneDeep(_.find(group.children, {key: 'nameOfVessel'})),
              tableName: table.variableName,
              groupName: group.variableName,
            };
            this.voyageNo = {
              item: this.s.cloneDeep(_.find(group.children, {key: 'voyageNo'})),
              tableName: table.variableName,
              groupName: group.variableName,
            };
            group.children = _.reject(group.children, item => {
              return item.key === 'nameOfVessel' || item.key === 'voyageNo';
            });
          }
          if (!isTsv && type === 'logistics' && group.variableName === 'containerGrp') {
            this.vesselBookingNo = {
              item: this.s.cloneDeep(_.find(group.children, {key: 'vesselBookingNo'})),
              tableName: table.variableName,
              groupName: group.variableName,
            };
            group.children = _.reject(group.children, item => {
              return item.key === 'vesselBookingNo';
            });
          }
          // 項目がすべて非表示なら除外する
          if (_.every(group.children, item => item.hide)) {
            return false;
          }
          if (type === 'information') {
            // informationタブは、players, logisticsタブにないものを表示する
            return !(processTabType.players.includes(group.variableName) || processTabType.logistics.includes(group.variableName));
          } else {
            return processTabType[type].includes(group.variableName);
          }
        });
      });
      return this.groupReject(tables);
    },
    // Player, Logistics, informationタブの項目リジェクト処理
    groupReject(tables) {
      return _.reject(tables, table => {
        // goodsタブに表示するグループを除外
        table.groups = _.reject(table.groups, group => {
          return processTabType.goods.allGroup.includes(group.variableName);
        });

        // エンティティ個別タブに表示するテーブルと、groupsが空のテーブルを除外
        return table.variableName.includes('Separate') || table.variableName.includes('GoodsLine') || table.variableName.includes('processSeparate') || table.groups.length === 0;
      });
    },
    switchTab(index) {
      this.activeTab = index;
    },
    // Update History アクションラベル
    getUpdateHistoryAction(item) {
      const action = PROCESS_OPERATION_VARIABLES.find((i) => i.code === item.action)
      return action.label || ''
    },
    // 混載元フローの取引詳細を表示します
    showTradingDetails(flow) {
      this.$emit('show-trading-detail', flow);
    },
    // PB-273で追加
    reconfirm() {
      this.$emit('withdraw', PROCESS_OPERATION.T_RECONFIRMATION);
    },
    updateAttachedFiles() {
      this.$emit("update-attached-files");
    }
  },
};
</script>

<style lang="scss" scoped>
  .tw_process_detail {
    position: relative;
    display: flex;
    min-width: 1420px;
    background: $color_gray_100;
    @include card_shadow;
    border-radius: 6px;
    padding: 24px 20px 29px;
  }

  .left {
    width: calc(100% - 35% - 20px);
    min-width: 0;
  }

  .right {
    width: 35%;
    margin-left: 20px;
    flex-shrink: 0;
    padding-top: 36px;

    &.delete {
      padding-top: 99px;
    }
  }

  .head {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    .column {
      display: flex;
      align-items: flex-start;
      margin-bottom: 22px;

      &:last-child {
        justify-content: flex-end;
      }
    }

    .tw_status_bar {
      margin-right: 40px;
    }

    .tw_button {
      margin-right: 0;

      &.menu_button {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .tw_button + .el-dropdown {
      margin-left: 20px;
    }
  }

  .sub_buttons {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  table.card {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    box-shadow: 0;
    overflow: hidden;
    border-radius: 0 0 6px 6px;
  }

  img.delete {
    position: absolute;
    right: 0;
    top: 0;
  }

  .button-original-tradings {
    margin-right: 20px;
  }

  .dropdown-title {
    font-size: 12px;
    color: #3E3A39;
    padding: 0 20px;
  }

  .tw_from_to {
    margin-top: -17px;
  }
</style>
