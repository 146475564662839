<template>
  <div class="tw_approval_list">
    <tw-collapse :title="`${processType === PROCESS_TYPE.TPRBL ? 'Request B/L History' : 'B/L Info History'}`" :initialOpen="items && items.length > 0">
      <div class="approval_list">
        <div class="approval_list_item" v-for="(item, index) in items" :key="index">
          <header>
            <div v-if="item.communicationDate" class="column">
              <div class="left">
                <div class="user">
                  <figure class="avatar table"><img :src="item.createUserIcon"></figure>
                  <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
                    <div slot="content">{{item.createUserName}}</div>
                    <div class="from_name">{{item.createUserName}}</div>
                  </el-tooltip>
                </div>
                <div class="date">{{item.createDate | dateTimeFormat}}</div>
              </div>
              <div class="right">
                <div class="action" @click="openDrawer(item)">
                  <!-- TODO: BL発行依頼はBookingNoを表示 BL情報は将来的にBLNoを表示要望 Ph6ではBookingNoを表示 -->
                  <!-- <div class="bl_no">B/L No {{item.blNo}}</div> -->
                  <div v-show="item.vesselBookingNo" class="bl_no">Booking No {{item.vesselBookingNo}}</div>
                  <div v-show="item.blState">{{item.blState}}</div>
                </div>
                <div v-if="processType === PROCESS_TYPE.TPBLI" class="link" @click="xmlDownload(item)">
                  <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
                    <div slot="content">XML Download</div>
                    <div><img src="@/assets/images/icons/xml_icon.svg"></div>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </header>
        </div>
        <div v-if="!items.length" class="empty_message">{{$t('Message.NoResult1')}}</div>
      </div>
    </tw-collapse>

    <el-drawer :visible.sync="drawerShow" size="auto" :modal="false" :withHeader="false">
      <tw-bl-detail-inner v-if="drawerShow" @close="close" :item="item" :tradeManagement="tradeManagement" @xml-download="xmlDownload" />
      <div v-if="drawerShow" class="circle" @click="close" />
    </el-drawer>
  </div>
</template>

<script>
import _ from 'lodash';
import { $api } from '@/store/ApiClient';
import TwCollapse from '@/components/molecules/TwCollapse';
import TwBlDetailInner from '@/components/templates/TwBlDetailInner';
import { PROCESS_TYPE, BASE64_FLG } from 'lib-tw-common';

export default {
  name: 'TwBlHistory',
  components: {
    TwCollapse,
    TwBlDetailInner
  },
  inject: ['s'],
  data() {
    return {
      drawerShow: false,
      drawerItem: {},
      item: {},
      isDownLoading: false,
      PROCESS_TYPE: PROCESS_TYPE,
      // スタブ
      // items: [
      //   {
      //     communicationDate: '2022-12-30T00:00:00Z',
      //     entityId: '',
      //     processId: '',
      //     processSeq: '',
      //     processTrx: '',
      //     blMessageType: '',
      //     blState: 1,
      //     // blNo: '12345678901234567890', // Ph6では非対応
      //     blFilePath: '',
      //     carrierScac: '',
      //     vesselBookingNo: '1234567890',
      //     createUserId: '',
      //     createUserName: 'createUserName',
      //     createUserIcon: '',
      //     createDate: '2022-12-30T00:00:00Z'
      //   }
      // ],
    };
  },
  computed: {
    items() {
      return _.get(this, 's.res.blHistory', []);
    },
    tradeManagement() {
      return _.get(this, 's.res.tradeManagement', {});
    },
    processType() {
      return this.s.processType;
    }
  },
  created() {},
  methods: {
    xmlDownload(item) {
      // console.log(item);
      if (this.isDownLoading) return;
      this.isDownLoading = true;
      const query = {
        base64Flg: BASE64_FLG.OFF,
        communicationDate: item.communicationDate,
        entityId: item.entityId,
        processId: item.processId,
        processSeq: item.processSeq,
        processTrx: item.processTrx
      };
      const fileName = this.$options.filters.fileName(item.blFilePath);
      const apiCode = 'get_/v1/bl-info/inttra-xml/search';
      const serviceCode = 'tw-transaction-bff-api';
      $api
        .txtDownload(query, fileName, serviceCode, apiCode)
        .then(() => {
          this.isDownLoading = false;
        })
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
          this.isDownLoading = false;
        });
    },
    openDrawer(item) {
      this.item = item;
      this.drawerShow = true;
    },
    close() {
      this.drawerShow = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.tw_approval_list {
  margin-bottom: 16px;
  .approval_list {
    max-height: 410px;
    overflow-y: auto;
    padding: 0 16px;
  }
  .approval_list_item {
    border-bottom: 1px solid $color_gray_300;
    padding: 12px;
  }
  header {
    display: flex;
    justify-content: space-between;
    .column {
      display: flex;
      width: 100%;
    }
    .left {
      width: 112px;
      margin: 0 8px 4px 0;
      font-size: 12px;
      line-height: 20px;
      color: $color_black;
      .from_name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .user {
      display: flex;
      align-items: center;
    }
    .right {
      flex: auto;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .date,
    .action {
      font-size: 10px;
      line-height: 14px;
      color: $color_gray_600;
    }
    .action {
      color: $color_dark_blue;
      cursor: pointer;
      .bl_no {
        // overflow: hidden;
        // white-space: nowrap;
        // text-overflow: ellipsis;
        max-width: 120px;
      }
    }
    .link {
      display: flex;
      align-items: center;
      margin-left: auto;
      cursor: pointer;
    }
  }
  .empty_message {
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: $color_black;
    margin-top: 33px;
    margin-bottom: 20px;
  }
}
::v-deep .el-drawer__wrapper {
  background: rgba(0, 0, 0, 0.2);
  .el-drawer__body {
    width: auto;
    width: 880px;
    > .permit_detail_inner {
      padding: 30px 40px 30px 35px;
    }
  }
  .el-drawer,
  .el-drawer__body {
    overflow: visible;
  }

  .circle {
    position: absolute;
    top: 14px;
    left: -18px;
    width: 18px;
    height: 29px;
    background: $color_gray_800 url(../../assets/images/icons/chevron_right_white.svg) no-repeat 3px center;
    background-size: 16px auto;
    border-radius: 15px 0 0 15px;
    z-index: 11;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
}
</style>
