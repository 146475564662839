<template>
<div class="tw_communication">
  <div class="tabs">
    <div class="tab" :class="{active: activeTab === 0}" @click="switchTab(0)"><i class="chat" />Comment ({{chatCount}})</div>
    <div class="tab" :class="{active: activeTab === 1}" @click="switchTab(1)"><i class="mail" />Email ({{mailCount}})</div>
  </div>
  <tw-collapse :initialOpen="true">
    <tw-chat v-show="activeTab === 0" :limit="limit" @reconfirm="reconfirm" />
    <tw-mail v-show="activeTab === 1" />
  </tw-collapse>
</div>
  
</template>

<script>
import _ from 'lodash';
import TwCollapse from '@/components/molecules/TwCollapse';
import TwChat from '@/components/organisms/TwChat';
import TwMail from '@/components/organisms/TwMail';

export default {
  name: 'TwCommunication',
  inject: ['s'],
  props: {
    items: Object,
  },
  components: {
    TwCollapse,
    TwChat,
    TwMail,
  },
  data() {
    return {
      activeTab: 0,
      invalid: false,
      limit: 2000, // チャットの文字数制限
    }
  },
  computed: {
    chatCount() {
      return _.size(_.get(this, 's.res.chats'));
    },
    mailCount() {
      return  _.size(_.get(this, 's.res.emailTransmissionHistories'));
    }
  },
  methods: {
    switchTab(index) {
      this.activeTab = index;
    },
    // PB-273で追加
    reconfirm() {
      this.$emit('reconfirm');
    }
  }
};
</script>

<style lang="scss" scoped>
.tw_communication {
  position: relative;
  margin-bottom: 16px;

  .tabs {
    display: flex;

    .tab {
      position: relative;
      display: flex;
      align-items: center;
      height: 37px;
      width: 50%;
      padding-left: 12px;
      background: $color_gray_300;
      @include card_shadow;
      border-radius: 6px 6px 0px 0px;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      color: $color_gray_600;
      cursor: pointer;

      i {
        display: block;
        width: 20px;
        height: 20px;
        background: no-repeat center;
        background-size: 100% auto;
        margin-right: 8px;

        &.chat {
          background-image: url(../../assets/images/icons/icon_comment_gray600.svg);
        }

        &.mail {
          background-image: url(../../assets/images/icons/icon_mail_gray600.svg);
        }
      }

      &.active {
        background: #FFFFFF;
        color:$color_gray_800;
        z-index: 1;

        i.chat {
          background-image: url(../../assets/images/icons/icon_comment_gray800.svg);
        }

        i.mail {
          background-image: url(../../assets/images/icons/icon_mail_gray800.svg);
        }
      }

      &:not(.active):active {
        opacity: .65;
      }
    }
  } 
}

::v-deep .wrap {
  border-radius: 0 0 6px 6px;
}
</style>
