// eslint-disable-next-line import/prefer-default-export
export function alignAToZ(targetList, targetKey) {
  const sortFn = Intl.Collator('en').compare
  if (!targetKey) {
    return targetList.sort(sortFn);
  } else {
    const sortedBase = (targetList.map(item => item[targetKey])).sort(sortFn);
    let result = [];
    const targetListCopy = [...targetList]
    for (const targetVal of sortedBase) {
      const i = targetListCopy.findIndex(item => item[targetKey] === targetVal)
      result.push(targetListCopy[i]);
      targetListCopy.splice(i, 1)
    }
    return result;
  }
}