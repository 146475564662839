<template>
  <div class="new_mail">
    <header>
      <h1>Send Email</h1>
    </header>
    <el-form :model="form" :rules="rules" ref="form">
      <el-form-item label="To" prop="userIdTo" class="mail_to">
        <el-select v-model="form.userIdTo" filterable multiple placeholder="" clearable>
          <el-option
            v-for="user in users"
            :key="user.id"
            :label="user.name"
            :value="user.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Subject" prop="processMailTitle" class="mail_subject">
        <el-input type="text" v-model="form.processMailTitle" class="show_word_limit" show-word-limit maxlength="100"></el-input>
      </el-form-item>
        
      
      <el-form-item label="Body" prop="processMailBody" class="content">
        <el-input
          type="textarea"
          :rows="2"
          placeholder=""
          autosize
          v-model="form.processMailBody"
          show-word-limit
          maxlength="10000"
        />
      </el-form-item>
    </el-form>
    <footer>
      <table>
        <tr>
          <td>Trading/Flow ID</td>
          <td>{{tradeManagement.tradingFlowId}}</td>
        </tr>
        <tr>
          <td>Trading Name</td>
          <td>{{tradeManagement.tradingManageName}}</td>
        </tr>
        <tr>
          <td>Flow Name</td>
          <td>{{tradeManagement.tradingFlowName}}</td>
        </tr>
        <tr>
          <td>Invoice No</td>
          <td>{{invoiceNo}}</td>
        </tr>
        <tr>
          <td>Process</td>
          <td>{{s.processName}}</td>
        </tr>
      </table>
      <div style="display: flex;justify-content: flex-end">
        <tw-button type="primary" size="medium" @click="submitMail">Send</tw-button>
      </div>
    </footer>
  </div>
</template>

<script>
import _ from 'lodash';
import { $api } from '@/store/ApiClient';
import mixinValidation from '@/utils/mixinValidation.js';
import TwButton from '@/components/atoms/TwButton';

export default {
  name: 'TwNewMail',
  mixins: [mixinValidation],
  inject: ['s'],
  props: {
    data: Object
  },
  components: {
    TwButton,
  },
  data() {
    return {
      form: {
        userIdTo: [],
        processMailTitle: '',
        processMailBody: '',
      },
      rules: {
        userIdTo: [
          {
            lakeelMessageSourceFlg: true,
            validationId: ["tw-isNotEmpty","tw-isOneOrMore","tw-isMaxSender200"],
            validator: this.onEventValidation, trigger: 'change'
          }
        ],
        processMailTitle: [
          {
            lakeelMessageSourceFlg: true,
            validationId: ["tw-isNotEmpty", "tw-isMaxLength100"],
            validator: this.onEventValidation, trigger: 'blur'
          }
        ],
        processMailBody: [
          {
            lakeelMessageSourceFlg: true,
            validationId: ["tw-isNotEmpty", "tw-isMaxLength10000"],
            validator: this.onEventValidation, trigger: 'blur'
          }
        ],
      },
      editor: null,
    };
  },
  computed: {
    tradeManagement() {
      return _.get(this.s, 'res.tradeManagement');
    },
    invoiceNo() {
      return _.get(this.s, 'res.processHeader.invoiceNo');
    },
    url() {
      return location.href;
    },
    users() {
      const fromUsers = _.map((_.get(this, `s.communicationDestinations.from.users`) || []), user => {
        return {
          id: user.userIdTo,
          name: user.userNameTo,
        }
      });
      const toUsers = _.map((_.get(this, `s.communicationDestinations.to.users`) || []), user => {
        return {
          id: user.userIdTo,
          name: user.userNameTo,
        }
      });
      return _.uniqBy([...fromUsers, ...toUsers], 'id');
    },
  },
  methods: {
    async submitMail() {
      
      if (await this.validationCheck('form')) {
        this.$store.commit('START_PROCESS');
        // bff_tc_5 メール送信BFF
        const params = {
          lslConfig: {
            serviceCode: 'tw-transaction-bff-api',
            apiCode: 'post_/v1/common/send-mail',
          },
          data: {
            companyIdSender: this.$store.state.companyId,
            userIdTo: this.form.userIdTo,
            entityId: this.s.entityId,
            processId: this.s.processId,
            processSeq: this.tradeManagement.processSeq,
            processTrx: this.tradeManagement.processTrx,
            tradingId: this.tradeManagement.tradingId,
            tradingManageId: this.tradeManagement.tradingFlowId,
            tradingName: this.tradeManagement.tradingManageName,
            tradingManageName: this.tradeManagement.tradingFlowName,
            invoiceNo: this.invoiceNo,
            fromCompanyId: this.tradeManagement.from.fromCompanyId,
            fromSectionId: this.tradeManagement.from.fromSectionId,
            toCompanyId: this.tradeManagement.to.toCompanyId,
            toSectionId: this.tradeManagement.to.toSectionId,
            processMailTitle: this.form.processMailTitle,
            processMailBody: this.form.processMailBody
          }
        }

        $api.request(params)
        .then(res => {
          this.$store.commit('END_PROCESS');
          // レスポンスのメール送信履歴で上書き
          if (res.emailTransmissionHistories) {
            this.s.setMailHistory(res.emailTransmissionHistories);
          }
          setTimeout(() => {
            this.$store.dispatch('SHOW_COMPLETED');
          }, 500);
          this.$store.commit('SET_DRAWER', false);
          setTimeout(() => {
            this.s.mode = null;
          }, 300);
        })
        .catch(err => {
          this.$store.commit('END_PROCESS');
          this.$store.dispatch('SHOW_ALERT', err.message);
        });        
      } else {
        this.$store.commit('END_PROCESS');
        this.$store.dispatch('SHOW_ALERT', 'Please check the input and try again.');
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .ProseMirror {
    padding: 24px 0;
    min-height: 100%;

    p {
      margin: 0 0 1em;
    }
  }

  .editor, .editor > div {
    height: 100%;
  }

  ::v-deep .el-input__inner {
    background: none;
    box-shadow: none;
    outline: 0;
    border: 0;
    padding: 0;
  }

  ::v-deep .scroller {
    height: calc(100vh - 560px);
    
    .el-scrollbar__wrap {
      overflow-x: hidden;
      height: 100%;
    }

    .el-scrollbar__view {
      height: 100%;
    }

    .el-textarea {
      height: 100%;
    }
  }

  .el-select {
    display: block;
    width: 100%;
  }

  .el-select__tags {
    max-width: 100%;
  }

  .new_mail {
    width: 682px;
    position: relative;

    header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 0 0 24px;
      border-bottom: 1px solid $color_gray_300;

      h1 {
        font-weight: bold;
        font-size: 24px;
        line-height: 33px;
        margin: 0;
      }
    }

    .el-form-item {
      display: flex;
      margin: 0;
      padding: 24px 0 ;
      border-bottom: 1px solid $color_gray_300;

      &.content {
        display: block;
        line-height: 1;
        
        ::v-deep .el-form-item__label {
          display: none;
        }

        ::v-deep textarea {
          resize: none;
          background: none;
          border: 0;
          outline: 0;
          height: 100%;
          min-height: calc(100vh - 630px)!important;
          max-height: calc(100vh - 630px)!important;
          padding: 0;
        }
      }
    }

    ::v-deep .el-form-item__content {
      flex: 1;
    }

    ::v-deep .el-select__caret {
      display: none;
    }

    label.el-form-item__label {
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      display: inline-block;
    }

    .el-input:hover ::v-deep .el-input__inner {
      background: none;
    }

    .mail_to {
      ::v-deep label.el-form-item__label {
        width: 30px;
        text-align: left;
      }

      ::v-deep .el-form-item__error {
        left: -30px;
      }
    }

    .mail_subject {
      ::v-deep label.el-form-item__label {
        width: 62px;
        text-align: left;
      }

      ::v-deep .el-form-item__error {
        left: -62px;
      }
    }

    footer {
      padding: 24px 0;

      table {
        width: 100%;
        border-collapse: collapse;
        padding: 0;
        margin-bottom: 20px;
      }

      td {
        font-size: 14px;
        line-height: 20px;
        vertical-align: top;
        padding: 0;
        word-break: break-word;

        a {
          text-decoration: none;
          color: #5185C5;
        }
      }

      tr > td:first-child {
        min-width: 128px;
        color: #5E5873;
        padding: 0 12px 16px 0;
      }

      .from_to {
        display: flex;
        align-items: center;
      }
    }

    .to span, .from_to span {
      display: flex;
      align-items:center;
      font-size: 12px;
      line-height: 20px;
      color: $color_black;

      figure.avatar.table {
        margin-right: 8px;
      }
    }

  }
</style>