import _ from 'lodash';
import { $api } from '@/store/ApiClient';
import BigNumber from 'bignumber.js';
import { CO_SETTLEMENT_METHOD, CO_SUBMIT_FLG } from 'lib-tw-common';

export default {
  methods: {
    // 項目を連結して返却します
    joinItems(dict, table, grp = '', key = '') {
      return _.compact(dict.map(item => _.get(this.cloneItems, `${table}.${grp}Grp.${key}${item}`) || '')).join(' ');
    },
    // 取引先の名前と拠点名を連結して返却します
    getPlayerName(table, key) {
      // 名前+" "+拠点名
      const dict = [
        'Name',
        'BranchName',
      ];
      return this.joinItems(dict, table, key, key);
    },
    // 取引先の住所、国などを連結して返却します
    getPlayerAddress(table, key) {
      // 住所通り1+" "+住所通り2+" "+住所1+" "+住所2+" "+住所3+" "+住所4+" "+郵便番号+" "+国名+" "+都市名+" "+行政区画+" "+電話番号+" "+FAX番号
      const dict = [
        'AddressStreet1',
        'AddressStreet2',
        'Address1',
        'Address2',
        'Address3',
        'Address4',
        'PostalCode',
        'CountryName',
        'CityName',
        'CountrySubdivision',
        'Tel',
        'Fax',
      ];

      return this.joinItems(dict, table, key, key);
    },
    // 経由地１～５を連結して返却します
    getVia() {
      const dict = [
        'viaName1',
        'viaCountryName1',
        'viaName2',
        'viaCountryName2',
        'viaName3',
        'viaCountryName3',
        'viaName4',
        'viaCountryName4',
        'viaName5',
        'viaCountryName5',
      ];

      return this.joinItems(dict, 'linkageTransportation', 'logisticsVia');
    },
    // 原産地_輸送手段_便名／便区分を連結して返却します
    getFlightNameOrDivision() {
      // 本船名+" "+航海番号+" "+出発便フライトナンバー1+" "+出発便フライトナンバー2+" "+出発便フライトナンバー3+" "+出発便フライトナンバー4+" "+出発便フライトナンバー5
      const dict = [
        'logisticsLoadingGrp.nameOfVessel',
        'logisticsLoadingGrp.voyageNo',
        'airGrp.departureFlightNo1',
        'airGrp.departureFlightNo2',
        'airGrp.departureFlightNo3',
        'airGrp.departureFlightNo4',
        'airGrp.departureFlightNo5',
      ];

      return _.compact(dict.map(item => _.get(this.cloneItems, `linkageTransportation.${item}`) || '')).join(' ');
    },
    // 選択された商品明細COのデータを返却します
    getEffectiveGoodsLineCo () {
      return _.filter(this.cloneItems.linkageGoodsLineCo, goods => {
        return goods.certificateOfOriginGrp.coSubmitFlg === CO_SUBMIT_FLG.TRUE;
      });
    },
    // 商品明細COで選択された商品を返却します
    getEffectiveGoodsLine (effectiveGoodsLineCo) {
      return _.map(_.reduce(effectiveGoodsLineCo, (ret, goods) => {
        ret = [...ret, ..._.filter(this.cloneItems.linkageGoodsLine || [], goodsLine => {
          return goods.keyGrp.goodsLineSeq === goodsLine.keyGrp.goodsLineSeq;
        })];
        return ret;
      }, []), goods => {
        return {
          unit1: goods.quantityGrp.unit1,
          unitId1: goods.quantityGrp.unitId1,
          quantity1: goods.quantityGrp.quantity1,
        };
      });
    },
    // 商品明細COで選択された商品梱包を返却します
    getEffectiveGoodsLinePacking (effectiveGoodsLineCo) {
      return _.map(_.reduce(effectiveGoodsLineCo, (ret, goods) => {
        ret = [...ret, ..._.filter(this.cloneItems.linkageGoodsLinePacking || [], packing => {
          return goods.keyGrp.goodsLineSeq === packing.keyGrp.goodsLineSeq;
        })];
        return ret;
      }, []), goods => {
        return {
          weightUnit: goods.netGrossMeasurementGrp.weightUnit,
          weightUnitId: goods.netGrossMeasurementGrp.weightUnitId,
          netTotal: goods.netGrossMeasurementGrp.netTotal,
          grossTotal: goods.netGrossMeasurementGrp.grossTotal,
          packing: goods.netGrossMeasurementGrp.packing,
          packingId: goods.netGrossMeasurementGrp.packingId,
          numberOfPackages: goods.netGrossMeasurementGrp.numberOfPackages,
        };
      });
    },
    sumBy(list, key, unit) {
      if (!unit) {
        return null;
      }

      const total = _.compact(_.map(list, goods => {
        return goods[key];
      }));

      const result = BigNumber.sum.apply(null, total).dp(4).toNumber();
      return result ? result : result === 0 ? 0 : null;
    },
    // 商品明細COで選択された商品梱包の原産地_梱包数・荷姿を合算した文字列をセットします
    setCoNumberOfPackages() {
      // パッケージ数＋” ”＋荷姿名 荷姿が複数ある場合、同じ荷姿のパッケージ数を合算して「パッケージ数＋” ”＋荷姿名」 + ” ” + 「パッケージ数＋” ”＋荷姿名」とする
      if (_.isEmpty(this.cloneItems.linkageGoodsLineCo)) {
        return null;
      }
      if (_.isEmpty(this.cloneItems.linkageGoodsLinePacking)) {
        return null;
      }

      const effectiveGoodsLineCo = this.getEffectiveGoodsLineCo();
      const effectiveGoodsLinePacking = this.getEffectiveGoodsLinePacking(effectiveGoodsLineCo);

      // console.log(effectiveGoodsLineCo, effectiveGoodsLinePacking)

      const packingGroup = _.groupBy(effectiveGoodsLinePacking, packing => packing.packing);
      const result = _.compact(_.map(packingGroup, group => {
        if (_.get(group[0], 'packing')) {
          return (_.sumBy(group, o => _.get(o, 'numberOfPackages')) || 0) + ' ' + _.get(group[0], 'packing');
        }
        return null;
      })).join(' ');

      this.$set(this.cloneItems.linkageFinalDocs.certificateOfOriginGrp, 'coNumberOfPackages1', result);

      // Ph6で廃止予定
      this.$set(this.cloneItems.linkageFinalDocs.certificateOfOriginGrp, 'coPackingTypeId1', this.isSame(effectiveGoodsLinePacking, 'packingId') || null);
      this.$set(this.cloneItems.linkageFinalDocs.certificateOfOriginGrp, 'coPackingTypeName1', this.isSame(effectiveGoodsLinePacking, 'packing') || null);
    },
    // 数量と重量を連結した文字列を返却します
    setCoGrossQuantityOrWeight() {
      // "(NET)"+NET重量+" "+重量単位+" "+"(GROSS)"+GROSS重量+" "+重量単位（"NET"、"GROSS"の文字はそれぞれNET重量,GROSS重量に値がある場合のみ追記）
      if (_.isEmpty(this.cloneItems.linkageGoodsLineCo)) {
        return null;
      }
      if (_.isEmpty(this.cloneItems.linkageGoodsLinePacking)) {
        return null;
      }

      const effectiveGoodsLineCo = this.getEffectiveGoodsLineCo();
      const effectiveGoodsLine = this.getEffectiveGoodsLine(effectiveGoodsLineCo);
      const effectiveGoodsLinePacking = this.getEffectiveGoodsLinePacking(effectiveGoodsLineCo);

      const coWeightUnitId = this.isSame(effectiveGoodsLinePacking, 'weightUnitId') || null;
      const coWeightUnit = this.isSame(effectiveGoodsLinePacking, 'weightUnit') || null;
      const coNetWeight = this.sumBy(effectiveGoodsLinePacking, 'netTotal', coWeightUnit);
      const coGrossWeight = this.sumBy(effectiveGoodsLinePacking, 'grossTotal', coWeightUnit);
      const coQuantityUnitId = this.isSame(effectiveGoodsLine, 'unitId1') || null;
      const coQuantityUnit = this.isSame(effectiveGoodsLine, 'unit1') || null;
      const coQuantity = this.sumBy(effectiveGoodsLine, 'quantity1', coQuantityUnit);

      let coGrossQuantityOrWeight = '';
      coGrossQuantityOrWeight += coGrossQuantityOrWeight && (coNetWeight || coNetWeight === 0) ? ' ' : '';
      coGrossQuantityOrWeight += (coNetWeight || coNetWeight === 0) ? '(NET)' + coNetWeight + (coWeightUnit ? ' ' + coWeightUnit : '') : '';
      coGrossQuantityOrWeight += coGrossQuantityOrWeight && (coGrossWeight || coGrossWeight === 0) ? ' ' : '';
      coGrossQuantityOrWeight += (coGrossWeight || coGrossWeight === 0) ? '(GROSS)' + coGrossWeight + (coWeightUnit ? ' ' + coWeightUnit : '') : '';

      this.$set(this.cloneItems.linkageFinalDocs.certificateOfOriginGrp, 'coGrossQuantityOrWeight1', coGrossQuantityOrWeight);

      // Ph6で廃止予定
      this.$set(this.cloneItems.linkageFinalDocs.certificateOfOriginGrp, 'coWeight1', coGrossWeight);
      this.$set(this.cloneItems.linkageFinalDocs.certificateOfOriginGrp, 'coWeightUnitId1', coWeightUnitId);
      this.$set(this.cloneItems.linkageFinalDocs.certificateOfOriginGrp, 'coWeightUnit1', coWeightUnit);
      this.$set(this.cloneItems.linkageFinalDocs.certificateOfOriginGrp, 'coQuantity1', coQuantity);
      this.$set(this.cloneItems.linkageFinalDocs.certificateOfOriginGrp, 'coQuantityUnitId1', coQuantityUnitId);
      this.$set(this.cloneItems.linkageFinalDocs.certificateOfOriginGrp, 'coQuantityUnit1', coQuantityUnit);
    },
    // 商品明細COで選択された商品梱包の原産地_梱包数・荷姿を合算してセットします。数量、重量も合わせてセットします
    setCoGoodsLinePacking() {
      this.setCoNumberOfPackages();
      this.setCoGrossQuantityOrWeight();
      // console.log('setCodata');
      // console.log(this.cloneItems.linkageFinalDocs.certificateOfOriginGrp);
    },
    // Commercial Invoice情報群のRemarksを連結し、改行をスペースに変換した文字列を返却します
    getCoRemarks() {
      // Invoice備考1+" "+Invoice備考2+" "+Invoice備考3+" "+Invoice備考4+" "+Invoice備考5+" "+Invoice備考6+" "+Invoice備考7+" "+Invoice備考8+" "+Invoice備考9+" "+Invoice備考10
      if (!_.get(this.cloneItems, 'linkageFinalDocs.commercialInvoiceGrp')) {
        return null;
      }
      const str = this.joinItems(_.range(1,11).map(i => `commercialInvoiceRemarks${i}`), 'linkageFinalDocs', 'commercialInvoice');
      // 改行をスペースに変換
      return typeof str === 'string' ? str.replace(/\n/g, ' ') : str;
    },
    // COMMERCIAL INVOICE BODYから改行をスペースに変換した文字列を返却します
    getCoOthers() {
      const str = _.get(this.cloneItems, 'linkageFinalDocs.commercialInvoiceGrp.commercialInvoiceBody');
      // 改行をスペースに変換
      return typeof str === 'string' ? str.replace(/\n/g, ' ') : str;
    },
    // CO情報をセットします
    setCoInformation() {
      // セット時にバリデーションを走らせない
      const normalRules = this.createRules(this.schemas.tables, false);
      this.rules = null;
      this.draftValid = true;

      // CO情報共通の実装
      const certificateOfOriginGeneralGrp = {
        coExporterName: this.getPlayerName('linkageContract', 'seller'), // SELLERから転記する挙動
        coExporterAddress: this.getPlayerAddress('linkageContract', 'seller'), // SELLERから転記する挙動
        coConsigneeName: this.getPlayerName('linkageTransportation', 'consignee'), // CONSIGNEEから転記する挙動
        coConsigneeAddress: this.getPlayerAddress('linkageTransportation', 'consignee'), // CONSIGNEEから転記する挙動
        coInvoiceNo: _.get(this.cloneItems, 'linkageFinalDocs.commercialInvoiceGrp.commercialInvoiceNo'), // INVOICE NOを転記
        coInvoiceDate: _.get(this.cloneItems, 'linkageFinalDocs.commercialInvoiceGrp.commercialInvoiceDate'), // INVOICE DATEを転記
        coBlDate: _.get(this.cloneItems, 'linkageTransportation.blGrp.blDate') || _.get(this.cloneItems, 'linkageTransportation.logisticsLoadingGrp.loadingPortEtd') || null, // B/L DATE若しくはETDから転記
        coPortOfLoadingName: _.get(this.cloneItems, 'linkageTransportation.logisticsLoadingGrp.portOfLoadingName'), // PORT OF LOADING NAMEから転記
        coVia: this.getVia(), // 経由地１～５を集約し転記
        coPortOfDischargingName: _.get(this.cloneItems, 'linkageTransportation.logisticsDischargingGrp.portOfDischargingName'),
        coFlightNameOrDivision: this.getFlightNameOrDivision(), // 本船名、航海番号、航空関連の項目から転記
        coPaymentMethod: CO_SETTLEMENT_METHOD.TW01,
        coDocumentCategory: null, // 区分値を選択（日本産、外国産）
      };

      // CO情報個別
      const certificateOfOriginGrp = {
        coApplicationNo1: null,
        coNo1: null,
        coRemarks1: this.getCoRemarks(),
        coShippingMark1: _.get(this.cloneItems, 'linkageTransportation.packingTermsGrp.requestedShippingMark'), // REQUESTED_SHIPPING_MARKから転記
        coNumberOfPackages1: null,
        coPackingTypeId1: null,
        coPackingTypeName1: null,
        coOthers1: this.getCoOthers(),
        coGoodsName1: _.get(this.cloneItems, 'linkageContract.poNoDateGrp.contractGroupOfGoods'), 
        coGoodsCategory1: null,
        coGrossQuantityOrWeight1: null,
        coWeight1: null,
        coWeightUnitId1: null,
        coWeightUnit1: null,
        coQuantity1: null,
        coQuantityUnitId1: null,
        coQuantityUnit1: null,
        coPicName1: null,
        coPicTel1: null,
        coSignerId1: _.get(this.cloneItems, 'linkageFinalDocs.documentsGrp.coSignerId'), // Document情報から転記を想定（手入力可能とする想定）
        coSignerName1: _.get(this.cloneItems, 'linkageFinalDocs.documentsGrp.coSignerName'), // Document情報から転記を想定
        coSignerTitle1: _.get(this.cloneItems, 'linkageFinalDocs.documentsGrp.coSignerTitle'), //Document情報から転記を想定
        coInvoiceSignerId1: _.get(this.cloneItems, 'linkageFinalDocs.documentsGrp.coInvoiceSignerId'), // coSignerId1と同じ仕様
        coInvoiceSignerName1: _.get(this.cloneItems, 'linkageFinalDocs.documentsGrp.coInvoiceSignerName'), // coSignerName1と同じ仕様
        coApplicantId1: _.get(this.cloneItems, 'linkageFinalDocs.certificateOfOriginGrp.coApplicantId1'),
        coApplicantName1: _.get(this.cloneItems, 'linkageFinalDocs.certificateOfOriginGrp.coApplicantName1'),
        coPlace1: null,
      };

      this.$set(this.cloneItems.linkageFinalDocs, 'certificateOfOriginGeneralGrp', certificateOfOriginGeneralGrp);
      // console.log(this.cloneItems.linkageFinalDocs.certificateOfOriginGeneralGrp);

      this.$set(this.cloneItems.linkageFinalDocs, 'certificateOfOriginGrp', certificateOfOriginGrp);
      // 商品明細COで選択した商品から参照する値をセットする
      this.setCoGoodsLinePacking();
      // console.log(this.cloneItems.linkageFinalDocs.certificateOfOriginGrp);

      // 通常仕様のバリデーションに戻す
      setTimeout(() => {
        this.rules = normalRules;
        this.draftValid = false;

        // COクーポン情報取得処理を実行
        this.checkCoupon();
      });
    },
    // COクーポン情報取得処理
    checkCoupon() {
      if (_.get(this, 'couponInfo.loading')) return;
      // coSignerId1が設定されていない場合はクーポン情報を取得しない
      const coSignerId1 = _.get(this, 'cloneItems.linkageFinalDocs.certificateOfOriginGrp.coSignerId1');
      if (!(coSignerId1 && _.isString(coSignerId1) && this.couponInfo)) return;
      this.$set(this, 'couponInfo', { loading: true });

      // bff_cr_12 COクーポン残数取得BFF
      const tradeManagement = _.get(this, 'tradeManagement');
      const from = _.get(tradeManagement, 'from');
      const to = _.get(tradeManagement, 'to');
      const coApplicantId1 = _.get(this, 'cloneItems.linkageFinalDocs.certificateOfOriginGrp.coApplicantId1');
      this.couponRequestTag = this.pushCancelTag();
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/co-request/{entityId}/{processId}/{processSeq}/coupons',
          path: {
            entityId: tradeManagement.entityId, // エンティティID
            processId: tradeManagement.processId, // 業務プロセスID
            processSeq:  tradeManagement.processSeq, // プロセスSEQ
          },
          query: {
            tradingId: tradeManagement.tradingId, // 取引ID
            tradingFlowId: tradeManagement.tradingFlowId, // 取引フローID
            toCompanyId: to.toCompanyId, // To会社ID
            toSectionId: to.toSectionId, // ToセクションID
            toChamberOfCommerceId: to.toSectionShortName, // 商工会議所ID NOTE: データとしては送信先の「組織名短縮英語」 Toセクション短縮名のこと
            coSignerId: coSignerId1, // CO署名用ユーザID → CO情報群のSignerId
            coApplicantId: coApplicantId1, // CO申請者ユーザーID
            fromCompanyId: from.fromCompanyId, // From会社ID
          }
        },
        // COクーポン情報取得BFFのキャンセル用タグ
        tag: this.couponRequestTag,
      };

      $api
        .request(params)
        .then(res => {
          this.$set(this, 'couponInfo', { ...res, loading: false });
          this.couponRequestTag = null;
        })
        .catch(err => {
          this.$set(this, 'couponInfo', { loading: false });
          if (this.couponRequestTag && err.message) {
            this.$store.dispatch('SHOW_ALERT', err.message);
          }
          this.couponRequestTag = null;
        });
    }
  }
};
