import _ from 'lodash';
import { downLoadTsv } from '@/utils/tsv.js';
import ORDER from '@/dictionaries/goodsLineTypeOrder.json';
import lcPoolDetailSchema from '@/dictionaries/lcPool/lcPoolDetailSchema.json';
import { swiftExcludeItems, swiftExcludeItems799 } from '@/dictionaries/lcPool/lcPoolSwiftSchema.js';
import lcPoolCltSchema from '@/dictionaries/lcPool/lcPoolCltSchema.json';
import { PROCESS_TYPE } from 'lib-tw-common';

// Trade Managementで使用する項目
const FILTER_KEYS = {
  tradeManagement: 'Trade Management',
  tradingId: 'Trading ID',
  tradingFlowId: 'Trading Flow ID',
  tradingManageName: 'Trading Manage Name',
  tradingFlowName: 'Trading Flow Name',
  owner: 'Owner',
  ownerSectionId: 'Owner Section ID',
  ownerSectionShortName: 'Owner Section Short Name',
  ownerSectionName: 'Owner Section Name',
  ownerCompanyId: 'Owner Company ID',
  ownerCompanyShortName: 'Owner Company Short Name',
  ownerCompanyName: 'Owner Company Name',
  entityId: 'Entity ID',
  processId: 'Process ID',
  processSeq: 'Process Seq',
  processTrx: 'Process Trx',
  updateDate: 'Update Date',
  from: 'From',
  fromSectionId: 'From Section ID',
  fromSectionShortName: 'From Section Short Name',
  fromSectionName: 'From Section Name',
  fromCompanyId: 'From Company ID',
  fromCompanyShortName: 'From Company Short Name',
  fromCompanyName: 'From Company Name',
  to: 'To',
  toSectionId: 'To Section ID',
  toSectionShortName: 'To Section Short Name',
  toSectionName: 'To Section Name',
  toCompanyId: 'To CompanyId',
  toCompanyShortName: 'To Company Short Name',
  toCompanyName: 'To Company Name',
  mainFlowFlg: 'Main Flow Flg',
  haveSubFlowFlg: 'Have Sub Flow Flg',
};

/**
 * プロセスTSVダウンロード用MixIn
 */
export default {
  data() {
    return {
      tsvMaxDepth: 0,
    }
  },
  methods: {
    // TSVデータの元になる配列を生成します
    createTsvArray(ary, depth = 0, ancestors = []) {
      if (depth > this.tsvMaxDepth) {
        this.tsvMaxDepth = depth;
      }
      return _.reduce(ary, (ret, o) => {
        if (depth !== 0) {
          let row = new Array(depth);
          if (!_.isEmpty(o) && o.variableName) {
            // 空行の場合は代入しない
            _.times(depth, i => {
              row[i] = ancestors[i];
            });
          }

          let value = o.value;
          if (value && _.isString(value)) {
            if (value.includes('"')) {
              value = `'${value}'`;
            } else {
              value = `"${value}"`;
            }
          }
          if (!_.isEmpty(o) && _.isUndefined(value)) {
            value = `--- ${depth} ---`;
          }
          if (!_.isNumber(value) && !value) {
            value === '';
          }
          row.push(o.variableName);
          row.unshift(value);
          row.unshift(o.name || '');
          // row.unshift(_.isEmpty(o) ? '' : (ancestors[0] || ''));
          ret.push(row.join('\t'));
        }

        if (o.children && o.children.length) {
          ancestors = ancestors.slice(0, depth);
          ancestors.push( o.variableName || o.name);
          ret = ret.concat(this.createTsvArray(o.children, depth + 1, ancestors));
        }
        return ret;
      }, []);
    },
    // プロセス以外のJSONデータをTSV用データに変換します
    jsonToTsvData(data, depth = 0, ancestors = []) {
      if (depth > this.tsvMaxDepth) {
        this.tsvMaxDepth = depth;
      }
      return _.reduce(data, (ret, value, key) => {
        const label = FILTER_KEYS[key];
        if (!label) {
          return ret;
        }
        let row = new Array(depth);
        _.times(depth, i => {
          row[i] = ancestors[i];
        });
        row.push(key);
        if (!_.isObject(value)) {
          row.unshift(value);
        } else {
          row.unshift('---');
        }
        row.unshift(label);
        // row.unshift(ancestors);
        ret.push(row.join('\t'));
        if (_.isObject(value)) {
          ancestors = ancestors.slice(0, depth);
          ancestors.push(key);
          ret = ret.concat(this.jsonToTsvData(value, depth + 1, ancestors));
        }
        return ret;
      }, []);
    },
    // 情報群のデータを生成します
    getChildren(table, group) {
      let children = [...group.children];

      // 全プロセス共通で不要なchildrenを取り除く
      children = _.reject(children, item => {
        return [
          'coSignerId',
          'coSignerId1',
          'coSignerId2',
          'coSignerId3',
          'coSignerId4',
          'coSignerId5',
          'coInvoiceSignerId',
          'coInvoiceSignerId1',
          'coInvoiceSignerId2',
          'coInvoiceSignerId3',
          'coInvoiceSignerId4',
          'coInvoiceSignerId5',
          'coApplicantId',
          'coApplicantId1',
          'coApplicantId2',
          'coApplicantId3',
          'coApplicantId4',
          'coApplicantId5',
        ].includes(item.key);
      })

      if (group.variableName === 'containerInfoGrp') {
        // containerInfoGrpの時
        const arrayContainerSeals = _.get(this.s.res, `${table.variableName}.containerInfoGrp.arrayContainerSeals`) || [];
        const filtered = _.reject(children, item => {
          return item.key.startsWith('typeOfService');
        });
        children = [
          ..._.filter(children, item => {
            return item.key.startsWith('typeOfService');
          }),
          ..._.flatten(_.map(arrayContainerSeals, (o, i) => [{}].concat(_.map(filtered, item => { // 配列の先頭には空行を入れる
            return {
              label: item.label,
              key: item.key,
              value: _.get(o, `${item.key}`),
            };
          })).concat(i === arrayContainerSeals.length - 1 ? [{}] : [])))
        ];
      }

      return _.map(children, item => {
        let value = _.isUndefined(item.value) ? _.get(this.s.res, `${table.variableName}.${group.variableName}.${item.key}`) : item.value;
        return {
          name: item.label,
          variableName: item.key,
          value: value,
        };
      });
    },
    // 配列データの子項目を返却します
    getArrayChildren(targetAry, arrayGroups) {
      if (_.isEmpty(targetAry)) {
        return [];
      }
      return [..._.flatten(_.map(targetAry, (o, i) => [{}].concat(_.map(arrayGroups, group => { // 配列の先頭には空行を入れる
        return {
          ...group,
          children: _.map(group.children, item => {
            return {
              name: item.label,
              variableName: item.key,
              value: _.get(o, `${group.variableName}.${item.key}`),
            };
          })
        };
      })).concat(i === targetAry.length - 1 ? [{}] : [])))];
    },
    // 商品データを生成します
    getGoodsLineTsv(table) {
      const goodsLineIndexes = _.map(this.s.res.linkageGoodsLine, goods => {
        return _.get(goods, 'keyGrp.goodsLineSeq');
      });


      let children;
      if (table.variableName === 'linkageGoodsLineType') {
        // 商品属性の時
        const types = _.map(_.groupBy(_.get(this.s.res, `${table.variableName}`) || [], 'keyGrp.goodsLineSeq'), o => o);
        const goodsAry = _.sortBy(types, o => {
          const index = _.indexOf(goodsLineIndexes, _.get(o, 'keyGrp.goodsLineSeq'));
          return index === -1 ? undefined : index;
        });
        children = _.reduce(goodsAry, (ret, o, i) => {
          ret.push({});
          const types = _.map(_.orderBy(o, [goods => {
            const index = _.findIndex(ORDER, order => {
              return goods.goodsTypeMasterList.goodsTypeNm === order;
            });
            return index < 0 ? 1000 : index;
          }, 'keyGrp.seqNo'], ['asc', 'asc']), goods => {
            return {
              name: _.get(goods, 'goodsTypeMasterList.goodsTypeNm') || '',
              variableName: _.get(goods, 'goodsTypeMasterList.attrId') || '',
              value: _.get(goods, 'keyGrp.goodsValue'),
            };
          });
          ret = ret.concat(types);
          if (i === goodsAry.length - 1) {
            ret.push({});
          }
          return ret;
        }, []);
      } else {
        const goodsAry = _.sortBy(_.get(this.s.res, `${table.variableName}`) || [], o => {
          const index = _.indexOf(goodsLineIndexes, _.get(o, 'keyGrp.goodsLineSeq'));
          return index === -1 ? undefined : index;
        });
        children = this.getArrayChildren(goodsAry, table.groups);
      }

      return children;
    },
    // 配列になっている項目のデータを生成します
    getArrayData({table, target, rejectGroupsTarget, last}) {
      let children;
      const targetAry = _.get(this.s.res, target) || [];
      const groups = _.filter(table.groups, o => {
        return rejectGroupsTarget.includes(o.variableName);
      });
      const arrayGroups = _.reject(table.groups, o => {
        return rejectGroupsTarget.includes(o.variableName);
      });
      children = this.getArrayChildren(targetAry, arrayGroups);

      if (last) {
        return {
          ...table,
          children: [
            ..._.map(groups, group => {
              return {
                ...group,
                children: this.getChildren(table, group)
              };
            }),
            ...children,
          ]
        };
      }

      return {
        ...table,
        children: [
          ...children,
          ..._.map(groups, group => {
            return {
              ...group,
              children: this.getChildren(table, group)
            };
          })
        ]
      };
    },
    // プロセスのTSVデータを生成します
    createTsvData(tables, tabName) {
      return _.map(tables, table => {
        if (table.variableName.startsWith('linkageGoodsLine') || table.variableName === 'importGoodsLinePriceSeparate') {
          // 商品の時
          return {
            ...table,
            children:this.getGoodsLineTsv(table)
          }
        } else if (table.variableName === 'goodsLineSeparate') {
          // 輸出入許可書の商品個別テーブルの時
          return this.getArrayData({table, target: 'goodsLineSeparate.goods', rejectGroupsTarget: ['remarksGrp']});
        } else if (table.variableName === 'linkageMarineInsurance' && tabName === 'Information') {
          // 海上保険テーブルの時
          return this.getArrayData({table, target: 'linkageMarineInsurance.ippools', rejectGroupsTarget: ['insuranceTermsGrp', 'insurancePolicyGrp', 'forwarderIpGrp', 'remarksGrp'], last: true});
        } else if (table.variableName === 'marineInsuranceSeparate') {
          // 海上保険の海上保険個別テーブルの時
          return this.getArrayData({table, target: 'marineInsuranceSeparate.dnpools', rejectGroupsTarget: ['remarksGrp']});
        } else if (this.processType === PROCESS_TYPE.MIRIP && table.variableName === 'processSeparate') {
          // IP発行依頼個別テーブルの時
          return this.getArrayData({table, target: 'processSeparate.ipreqList', rejectGroupsTarget: []});
        } else {
          return {
            ...table,
            children: _.map(table.groups, group => {
              return {
                ...group,
                children: this.getChildren(table, group)
              };
            })
          };
        }
      });
    },
    // swift項目を作成
    createSwiftItems(selectLcItems, messageType) {
      let omitItems =  _.omitBy(selectLcItems, (value, key) => {
        // swift以外の項目を除外
        if (messageType === '799') {
          return swiftExcludeItems.includes(key) || swiftExcludeItems799.includes(key);
        } else {
          return swiftExcludeItems.includes(key);
        }
      });
      if (omitItems && Object.keys(omitItems).length) {
        // swift項目を除外したオブジェクトを配列に変換
        omitItems = Object.entries(omitItems).map(([key, value]) => ({key, value}));
        omitItems = _.chunk(omitItems, 3); // TagNo, TagName, TagValueの3つずつになるよう分割
        // TagNo, TagName, TagValueの順にソート
        omitItems = omitItems.map((items) => {
          return _.sortBy(items, (item) => {
            const key = item.key;
            if (key.endsWith('TagNo')) return 1;
            else if (key.endsWith('TagName')) return 2;
            else return 3;
          });
        });
        return _.map(omitItems, omitItem => {
          return {
            name:( _.get(omitItem[0], 'value') || '') + ' ' + (_.get(omitItem[1], 'value') || ''),
            variableName: _.get(omitItem[2], 'key') || '',
            value: _.get(omitItem[2], 'value'),
          };
        });
      } else return null;
    },
    // LCプールのTSVデータを生成します
    createLcData() {
      const lcItems = _.filter(lcPoolDetailSchema.groups, (group) => {
        return group.variableName === 'overview';
      });
      const groups = _.reduce(this.s.res.linkageLc.lcpools, (ret, selectLc, i) => {
        ret.push({});
        const messageType = _.get(selectLc, 'lcdata.messageType');
        if (messageType === 'CLT') {
          ret = ret.concat(
            _.map(lcPoolCltSchema.groups, group => {
              return {
                ...group,
                variableName: 'lcdata',
                children: _.map(group.children, item => {
                  return {
                    name: item.label,
                    variableName: item.key,
                    value: _.get(selectLc.lcdata, item.key) || _.get(selectLc.lcmeta, item.key),
                  };
                })
              };
            })
          );
        } else if (messageType) {
          let selectLcItems = JSON.parse(JSON.stringify(_.get(selectLc, 'lcdata')));
          ret = ret.concat([
            {
              name: 'L/C Details',
              variableName: 'lcData',
              children: this.createSwiftItems(selectLcItems, messageType)
            }
          ]);
        }

        ret = ret.concat(
          _.map(lcItems, group => {
            return {
              ...group,
              variableName: 'lcdata',
              children: _.map(group.children, item => {
                return {
                  name: item.label,
                  variableName: item.key,
                  value: _.get(selectLc.lcdata, item.key) || _.get(selectLc.lcmeta, item.key),
                };
              })
            };
          })
        );
        if (i === this.s.res.linkageLc.lcpools.length - 1) {
          ret.push({});
        }
        return ret;
      }, []);
      return [{
        name: 'L/C',
        variableName: 'linkageLc',
        children: groups,
      }];
    },
    // プロセスのTSVデータをダウンロードします
    tsvDownload() {
      let ary = [];
      if (this.schemaTab1.length) {
        const tabName = 'Goods';
        const goodsTabSchema = _.reduce(this.schemaTab1, (ret, o) => {
          if (o.variableName === 'linkageGoodsLine') {
            ret.unshift(o);
          } else {
            ret.push(o);
          }

          if (o.extend) {
            ret.unshift({
              name: o.extendName,
              variableName: o.extendTableName,
              groups: o.extend
            });
          }
          return ret;
        }, []);
        ary.push({
          name: tabName,
          children: this.createTsvData(goodsTabSchema, tabName),
        })
      }
      if (this.schemaTab4.length) {
        const tabName = 'Information';
        ary.push({
          name: tabName,
          children: this.createTsvData(this.schemaTab4, tabName),
        })
      }
      if (this.schemaTab2.length) {
        const tabName = 'Players';
        ary.push({
          name: tabName,
          children: this.createTsvData(this.schemaTab2, tabName),
        })
      }
      if (this.schemaTab3.length) {
        const tabName = 'Logistics';
        ary.push({
          name: tabName,
          children: this.createTsvData(this.groupFilter('logistics', {isTsv: true}), tabName),
        })
      }
      if (this.hasLcdata) {
        const tabName = 'L/C';
        ary.push({
          name: tabName,
          children: this.createLcData(),
        })
      }
      if (this.processType === PROCESS_TYPE.MIRIP) {
        // IP発行依頼個別
        const tabName = 'Undisclosed';
        ary.push({
          name: tabName,
          children: this.createTsvData(this.IpRequestSchema, tabName),
        })
      }
      if (this.schemaTab5.length) {
        const tabName = 'Undisclosed';
        ary.push({
          name: tabName,
          children: this.createTsvData(this.schemaTab5, tabName),
        })
      }
      const tsvData = [...this.jsonToTsvData({tradeManagement: this.s.res.tradeManagement}), ...this.createTsvArray(ary)];
      let header = 'Display Name\tValue\tLevel 0';
      _.times(this.tsvMaxDepth, i => {
        header += `\tLevel ${i + 1}`;
      });
      const tsvText = [header, ...tsvData].join('\n');
      downLoadTsv(tsvText, `${location.pathname.replace('/', '')}.tsv`);
    }
  },
};
