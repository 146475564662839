<template>
  <div class="checkboxes_container" :class="{ is_head: head, check_none: !isCheckBox }">
    <div v-if="head" class="head">{{ head }}</div>

    <div class="checkboxes">
      <el-checkbox-group v-if="isCheckBox"  v-model="temporaryValue" @change="change">
        <el-checkbox v-for="(item, index) in items" :label="item" :key="index">{{ item.label }}</el-checkbox>
      </el-checkbox-group>

      <div v-else class="text_only">
        <span v-for="(item, index) in items" :key="index">{{ item.label }}{{ items.length === index + 1 ? '' : ' / ' }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TwCheckBoxes',
  props: {
    head: {
      type: String,
      default: '', // 'Types of Work'など、省略可
    },
    value: {
      type: [String, Number, Boolean, Array], // v-model 親にチェック済のitemを配列で返す
      default: () => [],
    },
    items: {
      type: [Array, Object],
      default: () => [], // [{value: 1, label: '値1'}] 等の形式を想定
    },
    isCheckBox: {
      type: Boolean, // falseが渡ってくると、itemsのlabelのみ表示（プロセス詳細画面用）
      default: true, // 例：Documentation / Customs Clearance / Loading / Shipping / Marine IP
    }
  },
  computed: {
    temporaryValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    change(val) {
      // console.log(val)
    }
  }
};
</script>

<style lang="scss">
.checkboxes_container {
  width: 100%;
  display: flex;
  margin: 24px 0;

  .head {
    min-width: 75px;
    max-width: 75px;
    background: #EDEDF4;
    border-radius: 6px 0px 0px 6px;
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    padding: 8px 0;
  }
  &.is_head .checkboxes {
    border-radius: 0px 6px 6px 0px;
  }

  .checkboxes {
    border: 1px solid #EDEDF4;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: $color_white;
    display: flex;
    align-items: center;
    flex: auto;
    padding: 20px 12px 4px;

    .el-checkbox {
      border: 1px solid $color_gray_300;
      box-sizing: border-box;
      border-radius: 6px;
      padding: 10px 24px 10px 12px;
      margin: 0 8px 16px 0;
      &:last-of-type {
        margin-right: 0;
      }
      .el-checkbox__label {
        font-size: 14px;
        color: $color_gray_600;
      }
      .el-checkbox__inner {
        width: 24px;
        height: 24px;
        border-radius: 4px;
        box-shadow: none;
        background-color: $color_gray_100;
        border-color: $color_gray_600;
        &::after {
          transition: none;
        }
      }

      &.is-checked {
        border: 1px solid $color_dark_blue;
        .el-checkbox__label {
          color: $color_dark_blue;
        }
        .el-checkbox__inner {
          background-color: $color_dark_blue;
          border-color: $color_dark_blue;
          &::after {
            border: 2px solid $color_white;
            border-left: 0;
            border-top: 0;
            width: 4px;
            height: 10px;
            top: 4px;
            left: 8px;
          }
        }
      }
    }
  }

  &.check_none .checkboxes {
    padding: 16px 28px;
  }
  .text_only {
    font-size: 14px;
    color: $color_gray_800;
  }
}
</style>
