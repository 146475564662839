<template>
  <article ref="contents">
    <section ref="page1" class="pdfpage">
      <div ref="page1Inner" class="inner">
        <table>
          <thead>
            <template v-if="seller.sellerInfo">
              <tr>
                <td colspan="10"><b>{{splitInfo(seller.sellerInfo)[0] || ''}}</b></td>
              </tr>
              <tr>
                <td colspan="10" v-html="sanitize(splitInfo(seller.sellerInfo)[1] || '')" />
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="10"><b>{{seller.sellerName}}&nbsp;{{seller.sellerBranchName}}</b></td>
              </tr>
              <tr>
                <td colspan="10">
                  <div v-if="seller.sellerAddress1">{{seller.sellerAddress1}}</div>
                  <div v-if="seller.sellerAddress2">{{seller.sellerAddress2}}</div>
                  <div v-if="seller.sellerAddress3">{{seller.sellerAddress3}}</div>
                  <div v-if="seller.sellerAddress4">{{seller.sellerAddress4}}</div>
                  <div v-if="seller.sellerAddressStreet1 || seller.sellerAddressStreet2">
                    <span v-if="seller.sellerAddressStreet1">{{seller.sellerAddressStreet1}} </span>
                    <span v-if="seller.sellerAddressStreet2">{{seller.sellerAddressStreet2}}</span>
                  </div>
                  <span v-if="seller.sellerCityName">{{seller.sellerCityName}} </span>
                  <span v-if="seller.sellerCountrySubdivision">{{seller.sellerCountrySubdivision}} </span>
                  <span v-if="seller.sellerCountryName">{{seller.sellerCountryName}}</span>
                  <div v-if="seller.sellerPostalCode">Postal Code {{seller.sellerPostalCode}}</div>
                  {{telFax(seller.sellerTel, seller.sellerFax)}}
                </td>
              </tr>
            </template>
            <tr>
              <td colspan="10" rowspan="2"><h1>INVOICE</h1></td>
            </tr>

          </thead>

          <tbody>
            <tr>
              <td colspan="5" rowspan="2"><b>Buyer's Name &amp; Address</b>
                <div v-if="buyer.buyerInfo">
                  <span style="font-weight: bold">{{splitInfo(buyer.buyerInfo)[0] || ''}}</span><br>
                  <span v-html="sanitize(splitInfo(buyer.buyerInfo)[1] || '')" />
                </div>
                <div v-else>
                  <span style="font-weight: bold">{{buyer.buyerName}}&nbsp;{{buyer.buyerBranchName}}</span><br>
                  <div v-if="buyer.buyerAddress1">{{buyer.buyerAddress1}}</div>
                  <div v-if="buyer.buyerAddress2">{{buyer.buyerAddress2}}</div>
                  <div v-if="buyer.buyerAddress3">{{buyer.buyerAddress3}}</div>
                  <div v-if="buyer.buyerAddress4">{{buyer.buyerAddress4}}</div>
                  <div v-if="buyer.buyerAddressStreet1 || buyer.buyerAddressStreet2">
                    <span v-if="buyer.buyerAddressStreet1">{{buyer.buyerAddressStreet1}} </span>
                    <span v-if="buyer.buyerAddressStreet2">{{buyer.buyerAddressStreet2}}</span>
                  </div>
                  <span v-if="buyer.buyerCityName">{{buyer.buyerCityName}} </span>
                  <span v-if="buyer.buyerCountrySubdivision">{{buyer.buyerCountrySubdivision}} </span>
                  <span v-if="buyer.buyerCountryName">{{buyer.buyerCountryName}}</span>
                  <div v-if="buyer.buyerPostalCode">Postal Code {{buyer.buyerPostalCode}}</div>
                  {{telFax(buyer.buyerTel, buyer.buyerFax)}}
                </div>
              </td>
              <td colspan="2"><b>I/V No</b><br>{{invoiceNo}}</td>
              <td colspan="3"><b>I/V Date</b><br>{{invoiceDate | utcOffsetDateFormat}}</td>
            </tr>
            <tr>
              <td colspan="5"><b>Your Ref No</b><br>{{yourRefNo}}</td>
            </tr>
            <tr>
              <td colspan="5"><b>Port of Loading</b><br>{{portOfLoading}}</td>
              <td colspan="5"><b>Port of Discharging</b><br>{{portOfDischarging}}</td>
            </tr>
            <tr>
              <td colspan="5"><b>Via</b>
                <template v-for="via in vias">
                  <!-- eslint-disable-next-line vue/require-v-for-key -->
                  <div>{{via}}</div>
                </template>
              </td>
              <td colspan="5"><b>Vessel</b><br>{{vessel}}</td>
            </tr>
            <tr>
              <td colspan="5"><b>B/L Date</b><br>{{blDate | utcOffsetDateFormat}}</td>
              <td colspan="5"><b>Voyage No</b><br>{{voyageNo}}</td>
            </tr>
            <tr>
              <td colspan="5"><b>Delivery Terms Name</b><br>{{deliveryTermsText}}</td>
              <td colspan="5"><b>Price Quotation Name</b><br>{{priceQuotationName}}</td>
            </tr>
            <tr>
              <td colspan="10"><b>Payment Terms1, Terms2</b><br>{{paymentTerms}}</td>
            </tr>
            <tr>
              <td colspan="2"><h2>Marks &amp; NOS.</h2></td>
              <td colspan="8"><h2>Description (Goods Details)</h2></td>
            </tr>
            <tr>
              <td colspan="2" rowspan="2">
                <template v-if="shippingMarks.length === 1">
                  <h3>Shipping Mark</h3><div v-html="sanitize(shippingMarks[0].value)" />
                </template>
              </td>
              <td colspan="8" rowspan="1" class="goods_table_wrap">
                <TwIvPdfGoodsLineTable :items="goodsLine1" :page="-1" :hasOtherExpenses="hasOtherExpenses" :expenses="expensesGrp" />
              </td>
            </tr>
            <tr>
              <td colspan="6" rowspan="1"><div class="invoice_body" ref="invoiceBody" v-html="sanitize(invoiceBody)" /></td>
              <td colspan="2" rowspan="1"></td>
            </tr>
          </tbody>
        </table>
      </div>

    </section>
    <template v-for="(attach, index) in attachPages">
      <!-- eslint-disable-next-line vue/require-v-for-key -->
      <section ref="attach" class="pdfpage">
        <div class="inner">
          <table>
            <thead>
              <template v-if="seller.sellerInfo">
                <tr>
                  <td colspan="10"><b>{{splitInfo(seller.sellerInfo)[0] || ''}}</b></td>
                </tr>
                <tr>
                  <td colspan="10" v-html="sanitize(splitInfo(seller.sellerInfo)[1] || '')" />
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="10"><b>{{seller.sellerName}}&nbsp;{{seller.sellerBranchName}}</b></td>
                </tr>
                <tr>
                  <td colspan="10">
                    <div v-if="seller.sellerAddress1">{{seller.sellerAddress1}}</div>
                    <div v-if="seller.sellerAddress2">{{seller.sellerAddress2}}</div>
                    <div v-if="seller.sellerAddress3">{{seller.sellerAddress3}}</div>
                    <div v-if="seller.sellerAddress4">{{seller.sellerAddress4}}</div>
                    <div v-if="seller.sellerAddressStreet1 || seller.sellerAddressStreet2">
                      <span v-if="seller.sellerAddressStreet1">{{seller.sellerAddressStreet1}} </span>
                      <span v-if="seller.sellerAddressStreet2">{{seller.sellerAddressStreet2}}</span>
                    </div>
                    <span v-if="seller.sellerCityName">{{seller.sellerCityName}} </span>
                    <span v-if="seller.sellerCountrySubdivision">{{seller.sellerCountrySubdivision}} </span>
                    <span v-if="seller.sellerCountryName">{{seller.sellerCountryName}}</span>
                    <div v-if="seller.sellerPostalCode">Postal Code {{seller.sellerPostalCode}}</div>
                    {{telFax(seller.sellerTel, seller.sellerFax)}}
                  </td>
                </tr>
              </template>
              <tr>
                <td colspan="10" rowspan="2"><h1>INVOICE ATTACHED SHEET</h1></td>
              </tr>
              <tr>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td colspan="5" rowspan="2"><b>Buyer's Name &amp; Address</b>
                  <div v-if="buyer.buyerInfo">
                    <span style="font-weight: bold">{{splitInfo(buyer.buyerInfo)[0] || ''}}</span><br>
                    <span v-html="sanitize(splitInfo(buyer.buyerInfo)[1] || '')" />
                  </div>
                  <div v-else>
                    <span style="font-weight: bold">{{buyer.buyerName}}&nbsp;{{buyer.buyerBranchName}}</span><br>
                    <div v-if="buyer.buyerAddress1">{{buyer.buyerAddress1}}</div>
                    <div v-if="buyer.buyerAddress2">{{buyer.buyerAddress2}}</div>
                    <div v-if="buyer.buyerAddress3">{{buyer.buyerAddress3}}</div>
                    <div v-if="buyer.buyerAddress4">{{buyer.buyerAddress4}}</div>
                    <div v-if="buyer.buyerAddressStreet1 || buyer.buyerAddressStreet2">
                      <span v-if="buyer.buyerAddressStreet1">{{buyer.buyerAddressStreet1}} </span>
                      <span v-if="buyer.buyerAddressStreet2">{{buyer.buyerAddressStreet2}}</span>
                    </div>
                    <span v-if="buyer.buyerCityName">{{buyer.buyerCityName}} </span>
                    <span v-if="buyer.buyerCountrySubdivision">{{buyer.buyerCountrySubdivision}} </span>
                    <span v-if="buyer.buyerCountryName">{{buyer.buyerCountryName}}</span>
                    <div v-if="buyer.buyerPostalCode">Postal Code {{buyer.buyerPostalCode}}</div>
                    {{telFax(buyer.buyerTel, buyer.buyerFax)}}
                  </div>
                </td>
                <td colspan="2"><b>I/V No</b><br>{{invoiceNo}}</td>
                <td colspan="3"><b>I/V Date</b><br>{{invoiceDate | utcOffsetDateFormat}}</td>
              </tr>
              <tr>
                <td colspan="5"><b>Your Ref No</b><br>{{yourRefNo}}</td>
              </tr>
              <tr>
                <td colspan="2"><h2>Marks &amp; NOS.</h2></td>
                <td colspan="8"><h2>Description (Goods Details)</h2></td>
              </tr>
              <tr>
                <td colspan="2" rowspan="2">
                  <h3 v-if="attach.shippingMarks && attach.shippingMarks.length">Shipping Mark</h3>
                  <!-- eslint-disable-next-line vue/require-v-for-key -->
                  <div v-for="mark in attach.shippingMarks" class="shipping_mark" style="padding-bottom: 1em">
                    <div v-html="sanitize(mark.value)" />
                  </div>
                </td>
                <td colspan="8" rowspan="2" class="goods_table_wrap">
                  <TwIvPdfGoodsLineTable :items="attach.goodsLine" :page="index" :hasOtherExpenses="hasOtherExpenses" :last="attach.last" :expenses="expensesGrp" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </template>
  </article>
</template>

<script>
import _ from 'lodash';
import printStyles from '@/assets/styles/printStyles';
import { FREE_CHARGE_FLG_VARIABLES, PROCESS_TYPE } from 'lib-tw-common';
import { formatQuantity, formatCurrency } from '@/utils/searchUtil.js';
import TwIvPdfGoodsLineTable from '@/components/molecules/TwIvPdfGoodsLineTable';

export default {
  name: 'TwIvPdf',
  inject: ['s'],
  components: {
    TwIvPdfGoodsLineTable,
  },
  data() {
    return {
      goodsLineData: [],
      goodsLine1: [],
      attachPages: [],
      shippingMarks: [],
      hasOtherExpenses: false,
      renderPage: 0,
    };
  },
  computed: {
    seller() {
      return _.get(this.s.res, 'linkageContract.sellerGrp') || {};
    },
    buyer() {
      return _.get(this.s.res, 'linkageContract.buyerGrp') || {};
    },
    invoiceNo() {
      if (this.s.processType === PROCESS_TYPE.ECREQ) {
        return _.get(this.s.res, 'linkageExportCustoms.exCustomClearanceGrp.exCustomInvoiceNo') || '';
      } else if (this.s.processType === PROCESS_TYPE.ICREQ) {
        return _.get(this.s.res, 'linkageImportCustoms.importCustomsClearanceGrp.imCustomInvoiceNo') || '';
      }
      // 書類作成・書類送付プロセス
      return _.get(this.s.res, 'linkageFinalDocs.commercialInvoiceGrp.commercialInvoiceNo') || '';
    },
    invoiceDate() {
      if (this.s.processType === PROCESS_TYPE.ECREQ) {
        return _.get(this.s.res, 'linkageExportCustoms.exCustomClearanceGrp.exCustomInvoiceDate') || '';
      } else if (this.s.processType === PROCESS_TYPE.ICREQ) {
        return _.get(this.s.res, 'linkageImportCustoms.importCustomsClearanceGrp.imCustomInvoiceDate') || '';
      }
      // 書類作成・書類送付プロセス
      return _.get(this.s.res, 'linkageFinalDocs.commercialInvoiceGrp.commercialInvoiceDate') || '';
    },
    yourRefNo() {
      return _.get(this.s.res, 'linkageContract.poNoDateGrp.yourReferenceNo') || '';
    },
    portOfLoading() {
      const port = _.get(this.s.res, 'linkageTransportation.logisticsLoadingGrp.portOfLoadingName');
      const country = _.get(this.s.res, 'linkageTransportation.logisticsLoadingGrp.countryOfLoadingName');
      return this.delimit(port, country);
    },
    portOfDischarging() {
      const port = _.get(this.s.res, 'linkageTransportation.logisticsDischargingGrp.portOfDischargingName');
      const country = _.get(this.s.res, 'linkageTransportation.logisticsDischargingGrp.countryOfDischargingName');
      return this.delimit(port, country);
    },
    vias() {
      const vias = _.map(_.range(1, 6), n => {
        const port = _.get(this.s.res, `linkageTransportation.logisticsViaGrp.viaName${n}`);
        const country = _.get(this.s.res, `linkageTransportation.logisticsViaGrp.viaCountryName${n}`);
        if (port || country) {
          return this.delimit(port, country);
        }
        return '';
      });
      return _.compact(vias);
    },
    vessel() {
      return _.get(this.s.res, 'linkageTransportation.logisticsLoadingGrp.nameOfVessel') || '';
    },
    blDate() {
      return _.get(this.s.res, 'processHeader.blDate') || '';
    },
    voyageNo() {
      return _.get(this.s.res, 'linkageTransportation.logisticsLoadingGrp.voyageNo') || '';
    },
    deliveryTermsText() {
      return _.get(this.s.res, 'linkageContract.deliveryTermsGrp.deliveryTermsText') || '';
    },
    priceQuotationName() {
      return _.get(this.s.res, 'linkageContract.priceQuotationGrp.priceQuotationName') || '';
    },
    paymentTerms() {
      const terms1 = _.get(this.s.res, 'linkageContract.paymentTermsGrp.paymentTerms1') || '';
      const terms2 = _.get(this.s.res, 'linkageContract.paymentTermsGrp.paymentTerms2') || '';
      return this.delimit(terms1, terms2);
    },
    invoiceBody() {
      if (this.s.processType === PROCESS_TYPE.ECREQ) {
        return _.get(this.s.res, 'linkageExportCustoms.exCustomClearanceGrp.exCustomInvoiceBody') || '';
      } else if (this.s.processType === PROCESS_TYPE.ICREQ) {
        return _.get(this.s.res, 'linkageImportCustoms.importCustomsClearanceGrp.imCustomInvoiceBody') || '';
      }
      // 書類作成・書類送付プロセス
      return _.get(this.s.res, 'linkageFinalDocs.commercialInvoiceGrp.commercialInvoiceBody') || '';
    },
    expensesGrp() {
      return _.get(this.s.res, 'linkageContract.expensesGrp') || {};
    },
  },
  created() {
    this.hasOtherExpenses = !!_.compact(_.filter(this.expensesGrp, (value, key) => {
      return /expenses\d+Amount/.test(key) || /expenses\d+Title/.test(key);
    })).length;

    this.shippingMarks = this.createShippingMarks();
    if (this.shippingMarks.length > 1) {
      this.attachPages.push({
        shippingMarks: this.shippingMarks,
        goodsLine: [],
      });
    }
    this.goodsLineData = this.createGoodsLineData();
  },
  mounted() {
    this.$nextTick(this.renderShippingMark());
  },
  methods: {
    // shippingMarkをレンダリングします
    renderShippingMark() {
      const currentPage = this.attachPages.length && this.$refs.attach[this.renderPage];

      if (currentPage && currentPage.scrollHeight > currentPage.clientHeight) {
        const diff = currentPage.scrollHeight - currentPage.clientHeight;
        const marks = currentPage.querySelectorAll('.shipping_mark');
        const heights = [...marks].map(el => el.clientHeight);
        // console.log(currentPage.scrollHeight, currentPage.clientHeight);
        // console.log(diff, heights);

        const count = this.count(heights, diff);
        // console.log(count)
        this.attachPages.push({
          shippingMarks: _.last(this.attachPages).shippingMarks.splice(count),
          goodsLine: [],
        });
        this.renderPage = this.renderPage + 1;
        // 再帰呼び出し
        this.$nextTick(this.renderShippingMark);
      } else {
        this.renderPage = 0;
        if (_.size(this.goodsLineData) === 1) {
          this.goodsLine1 = this.goodsLineData;
        } else if (_.size(this.goodsLineData) > 1) {
          this.$set(this.attachPages, 0, {
            ...this.attachPages[0],
            goodsLine: this.goodsLineData,
          });
        }

        this.$nextTick(this.renderGoodsLine);
      }
    },
    // goodsLineをレンダリングします
    renderGoodsLine() {
      const currentPage = this.attachPages.length && this.$refs.attach[this.renderPage];
      if (currentPage && currentPage.scrollHeight > currentPage.clientHeight) {
        const diff = currentPage.scrollHeight - currentPage.clientHeight;
        const goodsTrs = currentPage.querySelectorAll('.goods_tr');
        const heights = this.pairs([...goodsTrs].map(el => el.clientHeight));
        // console.log(currentPage.scrollHeight, currentPage.clientHeight);
        // console.log(diff, heights);

        const count = this.count(heights, diff);
        this.renderPage = this.renderPage + 1;
        this.$set(this.attachPages, this.renderPage, {
          ...this.attachPages[this.renderPage],
          goodsLine: this.attachPages[this.renderPage - 1].goodsLine.splice(count),
        });
        // 再帰呼び出し
        this.$nextTick(this.renderGoodsLine);
      } else {
        if (this.attachPages.length) {
          this.$set(this.attachPages, this.renderPage, {
            ...this.attachPages[this.renderPage],
            last: true,
          });
        }
        this.$nextTick(this.renderExpenses);
      }
    },
    // expensesをレンダリングします
    renderExpenses() {
      const currentPage = this.attachPages.length && this.$refs.attach[this.renderPage];
      if (currentPage && currentPage.scrollHeight > currentPage.clientHeight) {
        this.$set(this.attachPages, this.renderPage, {
          ...this.attachPages[this.renderPage],
          last: false,
        });
        this.renderPage = this.renderPage + 1;
        this.$set(this.attachPages, this.renderPage, {
          ...this.attachPages[this.renderPage],
          last: true,
        });
        this.$nextTick(this.renderInvoiceBody);
      } else if (this.hasOtherExpenses && _.size(this.goodsLineData) < 2) {
        // this.renderPage = this.renderPage + 1;
        this.$set(this.attachPages, this.renderPage, {
          ...this.attachPages[this.renderPage],
          last: true,
        });
        this.$nextTick(this.renderInvoiceBody);
      } else {
        this.$nextTick(this.renderInvoiceBody);
      }
    },
    // Invoice Bodyをレンダリングします
    renderInvoiceBody() {
      const currentPage = this.$refs.page1;

      if (currentPage && currentPage.scrollHeight > currentPage.clientHeight) {
        const diff = currentPage.scrollHeight - currentPage.clientHeight;
        const height = this.$refs.invoiceBody.clientHeight - diff;
        const style = window.getComputedStyle(this.$refs.invoiceBody);
        const lineHeight = parseInt(style.getPropertyValue('line-height'));
        const lineClamp = Math.floor(height / lineHeight);
        this.$refs.invoiceBody.style.webkitLineClamp = lineClamp;
        this.$refs.invoiceBody.style.height = Math.floor(lineHeight * lineClamp) + 'px';
      } else {
        this.$refs.invoiceBody.style.height = Math.floor(currentPage.clientHeight - this.$refs.page1Inner.clientHeight) + 'px';
      }

      this.$nextTick(this.print);
    },
    print() {
      const contents = this.$refs.contents.innerHTML;
      const printWindow = window.open('', '', 'height=400,width=800');
      const afterPrint = () => {
        printWindow.removeEventListener('afterprint', afterPrint);
        printWindow.removeEventListener('unload', afterPrint);
        printWindow.close();
        this.$emit('after-print');
      };
      printWindow.document.write(`<html><head><title>IV_${this.invoiceNo}</title>`);
      printWindow.document.write(printStyles);
      printWindow.document.write('</head><body >');
      printWindow.document.write(contents);
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      printWindow.addEventListener('afterprint', afterPrint, false);
      printWindow.addEventListener('unload', afterPrint, false);
      printWindow.print();
    },
    delimit(str1, str2, delimiter = ',') {
      if (str1 && str2) {
        return `${str1}${delimiter} ${str2}`;
      } else if (str1) {
        return str1;
      } else if (str2) {
        return str2;
      } else {
        return '';
      }
    },
    telFax(tel, fax) {
      if (tel && fax) {
        return `TEL ${tel} / Fax ${fax}`;
      } else if (tel) {
        return `TEL ${tel}`;
      } else if (fax) {
        return `FAX ${fax}`;
      } else {
        return '';
      }
    },
    sanitize(str) {
      return this.$options.filters.newlines(str);
    },
    // 商品表示用配列を生成します
    createGoodsLineData() {
      if (!this.s.res.linkageGoodsLine) {
        return [];
      }
      return _.map(this.s.res.linkageGoodsLine, goods => {
        const price = _.find(this.s.res.linkageGoodsLinePrice, p => {
          return p.keyGrp.goodsLineSeq === goods.keyGrp.goodsLineSeq;
        });
        return {
          goodsId: _.get(goods, 'goodsGrp.goodsId') || '',
          goodsShortName: _.get(goods, 'goodsGrp.goodsShortName') || '',
          quantity1: formatQuantity(goods.quantityGrp.quantity1),
          quantity2: formatQuantity(goods.quantityGrp.quantity2),
          unit1: _.get(goods, 'quantityGrp.unit1') || '',
          unit2: _.get(goods, 'quantityGrp.unit2') || '',
          currency: _.get(price, 'priceGrp.currency') || '',
          price: `${formatCurrency(price.priceGrp.price, null, this.s.getCurrencyMinorUnit(price.priceGrp.currencyId))}`,
          amount: formatCurrency(price.amountGrp.amount, null, this.s.getCurrencyMinorUnit(price.priceGrp.currencyId)),
          hsCode: this.s.getHsCode(goods.keyGrp.goodsLineSeq) || '',
          goodsRefNo1: _.get(goods, 'goodsGrp.goodsRefNo1') || '',
          goodsRefNo2: _.get(goods, 'goodsGrp.goodsRefNo2') || '',
          countryOfOriginName: _.get(goods, 'goodsGrp.countryOfOriginName') || '',
          noCommercialFlag:  _.get(_.find(FREE_CHARGE_FLG_VARIABLES, {code: _.get(price, 'commercialGrp.noCommercialFlag')}), 'label') || null,
        }
      })
    },
    // ShippingMarkのデータを生成します
    createShippingMarks() {
      if (!this.s.res.linkageGoodsLinePacking) {
        return [];
      }
      const goodsLinePacking = _.map(this.s.res.linkageGoodsLinePacking, packing => {
        return {
          shippingMark1: packing.netGrossMeasurementGrp.shippingMark1,
          shippingMark2: packing.netGrossMeasurementGrp.shippingMark2,
          shippingMark3: packing.netGrossMeasurementGrp.shippingMark3,
          shippingMark4: packing.netGrossMeasurementGrp.shippingMark4,
          shippingMark5: packing.netGrossMeasurementGrp.shippingMark5,
          shippingMark6: packing.netGrossMeasurementGrp.shippingMark6,
          shippingMark7: packing.netGrossMeasurementGrp.shippingMark7,
          shippingMark8: packing.netGrossMeasurementGrp.shippingMark8,
          shippingMark9: packing.netGrossMeasurementGrp.shippingMark9,
          shippingMark10: packing.netGrossMeasurementGrp.shippingMark10,
          goodsLineSeq: packing.keyGrp.goodsLineSeq,
          packingLineSeq: packing.keyGrp.packingLineSeq,
          packingSublineSeq: packing.keyGrp.packingSublineSeq,
        }
      });

      const ordered = _.orderBy(goodsLinePacking, ['goodsLineSeq', 'packingLineSeq', 'packingSubLineSeq'], ['asc', 'asc', 'asc']);
      return _.reduce(ordered, (ret, p) => {
        ret = ret.concat(_.compact(this.filterKeys(p, 'shippingMark')));
        return ret;
      }, []);
    },
    filterKeys(obj, keyword) {
      return _.map(obj, (value, key) => {
        if (!key.includes(keyword) || !value) {
          return null;
        }
        return {
          label: key,
          value: value,
        };
      });
    },
    // 配列の値を2つずつ合算した配列を返却します
    pairs(ary) {
      return _.reduce(ary, (ret, n, i) => {
        if (i % 2 === 1) {
          ret.push(ary[i - 1] + n);
        }
        return ret;
      }, []);
    },
    // ページ内に収めるために除外するレコード位置を返却します
    count(ary, diff) {
      const index = _.findLastIndex(ary, n => {
        diff -= n;
        if (diff <= 0) {
          return true;
        }
        return false;
      });
      return index;
    },
    splitInfo(value) {
      const ary = value.split('\n');
      const name = ary.shift();
      return [name, ary.join('\n')];
    },
  }
};
</script>

<style lang="scss" scoped>
</style>
