<template>
  <table class="tw_from_to">
    <tr>
      <th style="padding-right: 40px;">from</th>
      <!-- <th class="ship" rowspan="2">
        <img src="@/assets/images/ship.png">
      </th> -->
      <th>to</th>
    </tr>
    <tr>
      <td>
        <span style="max-width: 225px;white-space:normal;word-break: break-word;padding-right: 40px;">{{from.fromCompanyShortName}}</span>
      </td>
      <td>
        <span style="max-width: 225px;white-space:normal;word-break: break-word;">{{to.toCompanyShortName}}</span>
      </td>
    </tr>
    <tr>
      <td>
        <span style="max-width: 225px;white-space:normal;word-break: break-word;padding-top: 8px;padding-right: 40px;">{{from.fromSectionShortName}}</span>
      </td>
      <td>
        <span style="max-width: 225px;white-space:normal;word-break: break-word;padding-top: 8px;">{{to.toSectionShortName}}</span>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: 'TwFromTo',
  props: {
    from: Object,
    to: Object
  },
  data() {
    return {

    };
  },
  computed: {

  },
};
</script>

<style lang="scss">
  .tw_from_to {
    border-collapse: collapse;
    border-spacing: 0;

    th, td {
      padding: 0;
    }

    th {
      height: 17px;
      font-weight: 500;
      font-size: 10px;
      line-height: 11px;
      color: $color_gray_600;
      vertical-align: middle;
      text-align: center;
    }

    // .ship {
    //   width: 48px;

    //   img {
    //     display: block;
    //     width: 25px;
    //     margin: 0 auto;
    //   }
    // }

    td {
      vertical-align: top;

      span {
        display: flex;
        align-items:center;
        font-size: 12px;
        line-height: 15px;
        color: $color_black;
        box-sizing: content-box;
      }
    }
  }
</style>
