import { render, staticRenderFns } from "./ProcessDetail.html?vue&type=template&id=d4511ec2&scoped=true&"
import script from "./ProcessICPERMIT.vue?vue&type=script&lang=js&"
export * from "./ProcessICPERMIT.vue?vue&type=script&lang=js&"
import style0 from "./ProcessDetail.scss?vue&type=style&index=0&id=d4511ec2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4511ec2",
  null
  
)

export default component.exports