<template>
  <div class="tw_approval_request">
    <h2>Approval Request</h2>
    <el-form :model="form" :rules="rules" ref="form" label-position="top">
      <template v-if="isBookingCancel">
        <div class="approval_message">You have received a <span class="warning">Booking Cancel Request.</span></div>
      </template>
      <template v-else>
        <div class="approval_message" v-if="tradeManagement.eventCancel">You have received a request for deletion approval.</div>
        <div class="approval_message" v-else>You have received a request for approval.</div>
      </template>
      <el-form-item label="Return or Accept" prop="action">
        <el-radio-group v-model="form.action" @change="setRule">
          <el-radio :label="'0'" border>Return</el-radio>
          <el-radio :label="'1'" border>Accept</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item v-if="form.action === '0'" ref="commentR" label="Comment" prop="commentR">
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 6}"
          :rows="1"
          placeholder=""
          v-model="form.commentR"
          show-word-limit
          maxlength="200"
        />
      </el-form-item>

      <el-form-item v-else ref="comment" label="Comment" prop="comment">
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 6}"
          :rows="1"
          placeholder=""
          v-model="form.comment"
          show-word-limit
          maxlength="200"
        />
      </el-form-item>
    </el-form>
    <footer>
      <tw-button type="primary" size="medium" @click="commit">OK</tw-button>
    </footer>
  </div>
</template>

<script>
import _ from 'lodash';
import { RESPONSIBLE_FLG, PROCESS_OPERATION, PROCESS_TYPE, BOOKING_CANCEL_FLG } from 'lib-tw-common';
import mixinValidation from '@/utils/mixinValidation.js';
import TwButton from '@/components/atoms/TwButton';

export default {
  name: 'TwApprovalRequest',
  mixins: [mixinValidation],
  inject: ['s'],
  props: {
    tradeManagement: Object,
  },
  components: {
    TwButton,
  },
  data() {
    return {
      form: {
        action: null,
        updateDate: null,
        comment: '',
        commentR: ''
      },
      rules: {
        action: [
          {// 'tw-isNotEmptyThenZero'
            validationId: ['tw-isNotEmpty'],
            lakeelMessageSourceFlg: true,
            validator: this.onEventValidation, trigger: 'change',
            relation: {
              enemy: 'comment',
              enemyTargetName: 'Comment'
            }
          }
        ],
        commentR: [
          {
            lakeelMessageSourceFlg: true,
            validationId: ['tw-isNotEmpty', 'tw-isString', 'tw-isMaxLength200'],
            validator: this.onEventValidation, trigger: 'blur',
          }
        ],
        comment: [
          {
            lakeelMessageSourceFlg: true,
            validationId: ['tw-isString', 'tw-isMaxLength200'],
            validator: this.onEventValidation, trigger: 'blur',
          }
        ],
      },
      dirty: false,
    }
  },
  computed: {
    // Booking依頼プロセスかつBookingキャンセル依頼の承認かどうか
    isBookingCancel() {
      const bookingCancelFlg = _.get(this, 's.res.bookingCancelFlg');
      const processType = _.get(this, 's.processType');
      const responsibleFlg = _.get(this, 's.tradeManagement.responsibleFlg');
      return processType === PROCESS_TYPE.TPATPRB && bookingCancelFlg === BOOKING_CANCEL_FLG.ON && responsibleFlg === RESPONSIBLE_FLG.FROM;
    },
  },
  methods: {
    setRule() {
      if (this.form.action === '0' || !this.dirty) {
        this.form.commentR = this.form.comment;
      } else {
        this.form.comment = this.form.commentR;
      }
      this.dirty = true;
    },
    async commit() {
      if (await this.validationCheck('form')) {
        let action;
        const comment = this.form.action === '0' ? this.form.commentR : this.form.comment;
        if (parseInt(this.form.action) === 0) {
          this.$store
          .dispatch('SHOW_CONFIRM', `Are you sure you want to return this data?`)
          .then(() => {
            // 差戻
            if (this.s.tradeManagement.responsibleFlg === RESPONSIBLE_FLG.FROM) {
              action = PROCESS_OPERATION.F_REMAND;
            } else {
              action = PROCESS_OPERATION.T_REMAND;
            }
            this.s.withdraw(action, comment, { name: 'Approval' });
          })
          .catch(() => {});
        } else {
          if (this.s.tradeManagement.responsibleFlg === RESPONSIBLE_FLG.FROM) {
            action = PROCESS_OPERATION.F_APPROVE;
          } else {
            action = PROCESS_OPERATION.T_APPROVE;
          }

          if (this.isBookingCancel) {
            // Bookingキャンセル依頼の承認の場合はアラートを表示する
            this.$store
              .dispatch('SHOW_CONFIRM', `Are you sure to send a Booking Cancel Request?`)
              .then(() => {
                this.s.approve(action, comment, { name: 'Approval' });
              })
              .catch(() => {});
          } else {
            this.s.approve(action, comment, { name: 'Approval' });
          }
        }
      } else {
        this.$store.dispatch('SHOW_ALERT', 'Please check the input and try again.');
      }
    },
  }

};
</script>

<style lang="scss" scoped>
.tw_approval_request {
  background: $color_white;
  @include card_shadow;
  border-radius: 6px;
  margin-bottom: 30px;
  padding: 20px 32px 23px;

  h2 {
    height: 44px;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    margin: 0 0 24px;
    border-bottom: 1px solid $color_gray_300;
  }

  .approval_message {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 12px;
  }

  .el-form-item {
    padding-bottom: 24px;
    margin-bottom: 0;
  }

  .el-radio {
    margin-bottom: 0;
  }

  ::v-deep label[for=action] {
    display: none;
  }

  ::v-deep {
    label[for=comment], label[for=commentR] {
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 8px;
      padding-bottom: 0;
    }
  }

  footer {
    display: flex;
    justify-content: flex-end;
  }

  .warning {
    color: $color_warning;
  }
}
</style>
