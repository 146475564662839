import _ from 'lodash';
import { IP_SUBMIT_FLG, IP_REQUEST_TYPE, TRANSPORTATION, IPDN_CONVEYANCE, REQUEST_IP_TRADE_CATEGORY } from 'lib-tw-common';

const ipRequestData = {
  ipreqInformationGrp: {
    ipApplicationNo: null,
    actionType: 1,
    requestType: null,
    registrationNo: null,
    ipreqInsuranceCompanyName: null,
    ipreqAssuredsEtc: null,
    ipreqOpOcProvNo: null,
    ipreqInvoiceNo: null,
    ipreqCargoAmountInsuredCurrencyCode: null,
    ipreqCargoAmountInsured: null,
    ipreqDutyEtcAmountInsuredCurrencyCode: null,
    ipreqDutyEtcAmountInsured: null,
    ipreqInsurancePaymentPlace: null,
    mainConveyance: null,
    ipreqLocalVesselOrConveyance: null,
    ipreqInteriorPortCodeOrPlaceOfLoadingCode: null,
    ipreqInteriorPortNameOrPlaceOfLoadingName: null,
    ipreqImoNo: null,
    ipreqVesselName: null,
    ipreqPortOfLoadingCode: null,
    ipreqPortOfLoadingName: null,
    ipreqSailingOnOrAbout: null,
    ipreqTransshipmentLocationCodeOrPortOfDischargeCode: null,
    ipreqTransshipmentLocationNameOrPortOfDischargeName: null,
    ipreqFinalDestinationCode: null,
    ipreqFinalDestinationName: null,
    ipreqDescriptionOfGoods01: null,
    ipreqQuantity1_01: null,
    ipreqUnitCodeOfQuantity1_01: null,
    ipreqQuantity2_01: null,
    ipreqUnitCodeOfQuantity2_01: null,
    ipreqHsCode01: null,
    ipreqDescriptionOfGoods02: null,
    ipreqQuantity1_02: null,
    ipreqUnitCodeOfQuantity1_02: null,
    ipreqQuantity2_02: null,
    ipreqUnitCodeOfQuantity2_02: null,
    ipreqHsCode02: null,
    ipreqDescriptionOfGoods03: null,
    ipreqQuantity1_03: null,
    ipreqUnitCodeOfQuantity1_03: null,
    ipreqQuantity2_03: null,
    ipreqUnitCodeOfQuantity2_03: null,
    ipreqHsCode03: null,
    ipreqDescriptionOfGoods04: null,
    ipreqQuantity1_04: null,
    ipreqUnitCodeOfQuantity1_04: null,
    ipreqQuantity2_04: null,
    ipreqUnitCodeOfQuantity2_04: null,
    ipreqHsCode04: null,
    ipreqDescriptionOfGoods05: null,
    ipreqQuantity1_05: null,
    ipreqUnitCodeOfQuantity1_05: null,
    ipreqQuantity2_05: null,
    ipreqUnitCodeOfQuantity2_05: null,
    ipreqHsCode05: null,
    ipreqDescriptionOfGoods06: null,
    ipreqQuantity1_06: null,
    ipreqUnitCodeOfQuantity1_06: null,
    ipreqQuantity2_06: null,
    ipreqUnitCodeOfQuantity2_06: null,
    ipreqHsCode06: null,
    ipreqDescriptionOfGoods07: null,
    ipreqQuantity1_07: null,
    ipreqUnitCodeOfQuantity1_07: null,
    ipreqQuantity2_07: null,
    ipreqUnitCodeOfQuantity2_07: null,
    ipreqHsCode07: null,
    ipreqDescriptionOfGoods08: null,
    ipreqQuantity1_08: null,
    ipreqUnitCodeOfQuantity1_08: null,
    ipreqQuantity2_08: null,
    ipreqUnitCodeOfQuantity2_08: null,
    ipreqHsCode08: null,
    ipreqDescriptionOfGoods09: null,
    ipreqQuantity1_09: null,
    ipreqUnitCodeOfQuantity1_09: null,
    ipreqQuantity2_09: null,
    ipreqUnitCodeOfQuantity2_09: null,
    ipreqHsCode09: null,
    ipreqDescriptionOfGoods10: null,
    ipreqQuantity1_10: null,
    ipreqUnitCodeOfQuantity1_10: null,
    ipreqQuantity2_10: null,
    ipreqUnitCodeOfQuantity2_10: null,
    ipreqHsCode10: null,
    ipreqLcNo: null,
    ipreqInformationOfLc: null,
    ipreqUpRate: null,
    ipreqDutyRate: null,
    incoterms: null,
    amountCurrencyId: null,
    ipInvoiceAmount: null,
    ipInvoiceAmountRate: null,
    freightChargesCurrencyId: null,
    freightCharges: null,
    freightRate: null,
    ipreqAccountee: null,
    keyCode1: null,
    keyCode2: null,
    keyCode3: null,
    keyCode4: null,
    keyCode5: null,
    keyCode6: null,
    keyCode7: null,
    keyCode8: null,
    keyCode9: null,
    keyCode10: null,
    requestRemarks: null,
    formNoPattern: null,
    tradeCategory: null,
    ipDeclarationDate: null,
    insuranceCompanysComment: null,
    message: null,
    ipreqNumbersOfPoliciesIssued: null,
    ipreqDnType: null,
    ipreqInsurancePolicyNo: null,
    ipOwnerCompanyId: null,
    ipOwnerSectionId: null,
    dnOwnerCompanyId: null,
    dnOwnerSectionId: null,
    requestDatetime: null,
    ipreqStatus: null,
    ipSubmitFlg: 1,
    ipSubmitAbleFlg: 0,
    requestCancelAbleFlg: 0
  },
  ipreqSpecialConditionsGrp: {
    ipreqClaimAgent: null,
    ipreqInsuranceConditions: null,
    ipreqCoInsuranceCompany01: null,
    ipreqCoInsuranceShare01: null,
    ipreqCoInsuranceCompany02: null,
    ipreqCoInsuranceShare02: null,
    ipreqCoInsuranceCompany03: null,
    ipreqCoInsuranceShare03: null,
    ipreqCoInsuranceCompany04: null,
    ipreqCoInsuranceShare04: null,
    ipreqCoInsuranceCompany05: null,
    ipreqCoInsuranceShare05: null,
    ipreqCoInsuranceCompany06: null,
    ipreqCoInsuranceShare06: null,
    ipreqCoInsuranceCompany07: null,
    ipreqCoInsuranceShare07: null
  }
};

// New IP Request
export const createIpRequestData = res => {
  let ipRequest = _.cloneDeep(ipRequestData);
  // プロセスから転記する項目
  // プロセスのFrom, Toから転記
  const toCompanyName = _.get(res, 'tradeManagement.to.toCompanyName', null);
  // ipreqInsuranceCompanyNameは最大15桁なので、超えている場合は切り捨てる
  ipRequest.ipreqInformationGrp.ipreqInsuranceCompanyName = _.isString(toCompanyName) ? toCompanyName.substring(0, 15) : null;
  ipRequest.ipreqInformationGrp.ipOwnerCompanyId = _.get(res, 'tradeManagement.from.fromCompanyId', null);
  ipRequest.ipreqInformationGrp.ipOwnerSectionId = _.get(res, 'tradeManagement.from.fromSectionId', null);
  ipRequest.ipreqInformationGrp.dnOwnerCompanyId = _.get(res, 'tradeManagement.from.fromCompanyId', null);
  ipRequest.ipreqInformationGrp.dnOwnerSectionId = _.get(res, 'tradeManagement.from.fromSectionId', null);

  // 輸出通関.EX CUSTOM INVOICE NO.から転記（輸出通関が空の場合は輸入通関のI/V NO、輸入通関も空の場合は決済のI/V NOから転記）
  ipRequest.ipreqInformationGrp.ipreqInvoiceNo = _.get(res, 'linkageExportCustoms.exCustomClearanceGrp.exCustomInvoiceNo', null) || _.get(res, 'linkageImportCustoms.importCustomsClearanceGrp.imCustomInvoiceNo', null) || _.get(res, 'imCustomClearanceGrp.commercialInvoiceGrp.commercialInvoiceNo', null);

  // 輸送.INTERIOR PORT ID OR PLACE OF LOADINGから転記
  ipRequest.ipreqInformationGrp.ipreqInteriorPortCodeOrPlaceOfLoadingCode = _.get(res, 'linkageTransportation.logisticsLoadingGrp.interiorPortIdOrPlaceOfLoadingId', null);
  const interiorPortNameOrPlaceOfLoadingName = _.get(res, 'linkageTransportation.logisticsLoadingGrp.interiorPortNameOrPlaceOfLoadingName', null);

  // ipreqInteriorPortNameOrPlaceOfLoadingName最大36桁なので、超えている場合は切り捨てる
  ipRequest.ipreqInformationGrp.ipreqInteriorPortNameOrPlaceOfLoadingName = _.isString(interiorPortNameOrPlaceOfLoadingName) ? interiorPortNameOrPlaceOfLoadingName.substring(0, 36) : null;

  // 輸送.IMO NO.から転記
  const imoNo = _.get(res, 'linkageTransportation.containerGrp.imoNo', null);
  // ipreqImoNo最大9桁なので、超えている場合は切り捨てる
  ipRequest.ipreqInformationGrp.ipreqImoNo = _.isString(imoNo) ? imoNo.substring(0, 9) : null;

  // 輸送.NAME OF VESSELから転記
  const nameOfVessel = _.get(res, 'linkageTransportation.logisticsLoadingGrp.nameOfVessel', null);
  // ipreqVesselName最大30桁なので、超えている場合は切り捨てる
  ipRequest.ipreqInformationGrp.ipreqVesselName = _.isString(nameOfVessel) ? nameOfVessel.substring(0, 30) : null;

  // 輸送.PORT OF LOADINGから転記
  ipRequest.ipreqInformationGrp.ipreqPortOfLoadingCode = _.get(res, 'linkageTransportation.logisticsLoadingGrp.portOfLoadingId', null);
  const portOfLoadingName = _.get(res, 'linkageTransportation.logisticsLoadingGrp.portOfLoadingName', null);
  // ipreqPortOfLoadingName最大36桁なので、超えている場合は切り捨てる
  ipRequest.ipreqInformationGrp.ipreqPortOfLoadingName = _.isString(portOfLoadingName) ? portOfLoadingName.substring(0, 36) : null;

  // 輸送.PORT OF DISCHARGINGから転記
  ipRequest.ipreqInformationGrp.ipreqTransshipmentLocationCodeOrPortOfDischargeCode = _.get(res, 'linkageTransportation.logisticsDischargingGrp.portOfDischargingId', null);
  const portOfDischargeName = _.get(res, 'linkageTransportation.logisticsDischargingGrp.portOfDischargingName', null);
  // ipreqTransshipmentLocationNameOrPortOfDischargeName最大36桁なので、超えている場合は切り捨てる
  ipRequest.ipreqInformationGrp.ipreqTransshipmentLocationNameOrPortOfDischargeName = _.isString(portOfDischargeName) ? portOfDischargeName.substring(0, 36) : null;

  // 輸送.FINAL DESTINATIONから転記
  ipRequest.ipreqInformationGrp.ipreqFinalDestinationCode = _.get(res, 'linkageTransportation.logisticsDeliveryGrp.finalDestinationId', null);
  const finalDestinationName = _.get(res, 'linkageTransportation.logisticsDeliveryGrp.finalDestinationName', null);
  // ipreqFinalDestinationName最大36桁なので、超えている場合は切り捨てる
  ipRequest.ipreqInformationGrp.ipreqFinalDestinationName = _.isString(finalDestinationName) ? finalDestinationName.substring(0, 36) : null;

  // LC.L/C NO.から転記
  ipRequest.ipreqInformationGrp.ipreqLcNo = _.get(res, 'linkageLc.lcGrp.lcNo', null);

  // 契約.PRICE QUOTATION IDから転記
  ipRequest.ipreqInformationGrp.incoterms = _.get(res, 'linkageContract.priceQuotationGrp.priceQuotationId', null);

  // NOTE: Ph7UAT要望での転記項目の追加
  // 輸送.TRANSPORTATION GROUP OF GOODSから転記
  ipRequest.ipreqInformationGrp.ipreqDescriptionOfGoods01 = _.get(res, 'linkageTransportation.transportationGoodsGrp.transportationGroupOfGoods', null);
  // 輸送.SHIPPER NAMEから転記
  ipRequest.ipreqInformationGrp.ipreqAssuredsEtc = _.get(res, 'linkageTransportation.shipperGrp.shipperName', null);
  // 海上保険.INSURANCE OPEN POLICY NO.から転記
  ipRequest.ipreqInformationGrp.ipreqOpOcProvNo = _.get(res, 'linkageMarineInsurance.insurancePolicyGrp.insuranceOpenPolicyNo', null);

  // MAIN CONVEYANCE 転記元：Transportation区分から
  // Vessel(Bulk)→VESSEL
  // Vessel(Container)→VESSEL
  // Aircraft→AIRCRAFT
  // Complex→転記なし
  const transportation = _.get(res, 'linkageTransportation.transferTermsGrp.transportation', null);
  if (transportation === TRANSPORTATION['VESSEL(BULK)'] || transportation === TRANSPORTATION['VESSEL(CONTAINER)']) {
    ipRequest.ipreqInformationGrp.mainConveyance = IPDN_CONVEYANCE.VESSEL;
  } else if (transportation === TRANSPORTATION.AIRCRAFT) {
    ipRequest.ipreqInformationGrp.mainConveyance = IPDN_CONVEYANCE.AIRCRAFT;
  }

  return ipRequest;
};

// Trade Category変更時の転記処理 NOTE: Ph7UAT要望での転記項目の追加
export const changeTradeCategory = (val, index, cloneItems) => {
  let ipRequest = _.get(cloneItems, `processSeparate.ipreqList[${index}]`);

  if (val === REQUEST_IP_TRADE_CATEGORY.EXPORT) {
    // ①ユーザーが「Trade Category」にExportを選択した場合
    // 輸出通関.EXPORT CUSTOMS TOTAL QUANTITYから転記
    ipRequest.ipreqInformationGrp.ipreqQuantity1_01 = _.get(cloneItems, 'linkageExportCustoms.exCustomsAmountGrp.exCustomsTotalQuantity', null);
    // 輸出通関.EXPORT CUSTOMS TOTAL QUANTITY UNIT IDから転記
    ipRequest.ipreqInformationGrp.ipreqUnitCodeOfQuantity1_01 = _.get(cloneItems, 'linkageExportCustoms.exCustomsAmountGrp.exCustomsTotalQuantityUnitId', null);
    // 輸出通関.EXPORT CUSTOMS TOTAL AMOUNTから転記
    ipRequest.ipreqInformationGrp.ipInvoiceAmount = _.get(cloneItems, 'linkageExportCustoms.exCustomsAmountGrp.exCustomsTotalAmount', null);
    // 輸出通関.CURRENCY IDから転記
    ipRequest.ipreqInformationGrp.amountCurrencyId = _.get(cloneItems, 'linkageExportCustoms.exCustomsAmountGrp.exCustomsCurrencyId', null);
  } else if (val === REQUEST_IP_TRADE_CATEGORY.IMPORT) {
    // ②ユーザーが「Trade Category」にImportを選択した場合
    // 輸入通関.IMPORT CUSTOMS TOTAL QUANTITYから転記
    ipRequest.ipreqInformationGrp.ipreqQuantity1_01 = _.get(cloneItems, 'linkageImportCustoms.imCustomsAmountGrp.imCustomsTotalQuantity', null);
    // 輸入通関.IMPORT CUSTOMS TOTAL QUANTITY UNIT IDから転記
    ipRequest.ipreqInformationGrp.ipreqUnitCodeOfQuantity1_01 = _.get(cloneItems, 'linkageImportCustoms.imCustomsAmountGrp.imCustomsTotalQuantityUnitId', null);
    // 輸入通関.IMPORT CUSTOMS TOTAL AMOUNTから転記
    ipRequest.ipreqInformationGrp.ipInvoiceAmount = _.get(cloneItems, 'linkageImportCustoms.imCustomsAmountGrp.imCustomsTotalAmount', null);
    // 輸入通関.CURRENCY IDから転記
    ipRequest.ipreqInformationGrp.amountCurrencyId = _.get(cloneItems, 'linkageImportCustoms.imCustomsAmountGrp.imCustomsCurrencyId', null);
  }
}

// Copy IP Request
export const copyIpRequestData = original => {
  let ipRequest = _.cloneDeep(original);
  ipRequest.ipreqInformationGrp.ipApplicationNo = null;
  ipRequest.ipreqInformationGrp.actionType = 1;
  ipRequest.ipreqInformationGrp.requestType = null;
  ipRequest.ipreqInformationGrp.registrationNo = null;
  ipRequest.ipreqInformationGrp.insuranceCompanysComment = null;
  ipRequest.ipreqInformationGrp.requestDatetime = null;
  ipRequest.ipreqInformationGrp.ipreqStatus = null;
  ipRequest.ipreqInformationGrp.ipSubmitFlg = 1;
  ipRequest.ipreqInformationGrp.ipSubmitAbleFlg = 0;
  ipRequest.ipreqInformationGrp.requestCancelAbleFlg = 0;
  return ipRequest;
};

// From担当者 Apply 必須チェック
export const checkFromRequired = async cloneItems => {
  let errorA = false;
  let messagesA = [];
  let errorB = false;
  let messagesB = [];
  // ipreqListをチェック
  const ipreqList = _.get(cloneItems, 'processSeparate.ipreqList', []);
  await _.forEach(ipreqList, item => {
    const ipSubmitFlg = _.get(item, 'ipreqInformationGrp.ipSubmitFlg');
    const actionType = _.get(item, 'ipreqInformationGrp.actionType');
    const ipApplicationNo = _.get(item, 'ipreqInformationGrp.ipApplicationNo'); // 画面表示: Request No
    // console.log(ipSubmitFlg, actionType, ipApplicationNo)
    // Submit「ON」でActionに値がない（③）
    if (ipSubmitFlg === IP_SUBMIT_FLG.ON && !actionType) {
      errorA = true;
      if (ipApplicationNo) {
        messagesA.push(ipApplicationNo);
      }
    }
    // Submit「OFF」でActionに値がある（Amend/Cancel）（④）
    if (ipSubmitFlg === IP_SUBMIT_FLG.OFF && (actionType === IP_REQUEST_TYPE.AMEND || actionType === IP_REQUEST_TYPE.CANCEL)) {
      errorB = true;
      if (ipApplicationNo) {
        messagesB.push(ipApplicationNo);
      }
    }
  });
  // console.log(errorA, errorB)
  // console.log(messagesA, messagesB)
  if (errorA || errorB) {
    let errorMessages = [];
    if (errorA) {
      const errorMessagesA = messagesA.length ? 'Request No ' + messagesA.join(', ') + ': ' : '';
      errorMessages.push(errorMessagesA + 'Action is required.');
    }
    if (errorB) {
      const errorMessagesB = messagesB.length ? 'Request No ' + messagesB.join(', ') + ': ' : '';
      errorMessages.push(errorMessagesB + 'Setting Submit is mandatory if Action is selected.');
    }
    // console.log(errorMessages.join('<br>'))
    throw errorMessages.join('<br>');
  } else return;
};

// From担当者 Apply 任意チェック
export const checkFromAny = async cloneItems => {
  let isError = false;
  // ipreqListをチェック
  const ipreqList = _.get(cloneItems, 'processSeparate.ipreqList', []);
  isError = _.some(ipreqList, item => {
    const ipSubmitFlg = _.get(item, 'ipreqInformationGrp.ipSubmitFlg');
    const actionType = _.get(item, 'ipreqInformationGrp.actionType');
    const registrationNo = _.get(item, 'ipreqInformationGrp.registrationNo');
    // Submit「OFF」でActionに値がない、Registration Noに値がない（⑤）
    return ipSubmitFlg === IP_SUBMIT_FLG.OFF && !actionType && !registrationNo;
  });
  if (isError) throw 'Insurance Policy Request data with Submit turned off will not be registered and will be deleted.';
  else return;
};

// To担当者 Save,Apply 必須チェック Submit「ON」でIPREQ Statusがない（⑥）
export const checkToRequiredIpreqStatus = async cloneItems => {
  let errorA = false;
  let messagesA = [];
  // ipreqListをチェック
  const ipreqList = _.get(cloneItems, 'processSeparate.ipreqList', []);
  await _.forEach(ipreqList, item => {
    const ipSubmitFlg = _.get(item, 'ipreqInformationGrp.ipSubmitFlg');
    const ipreqStatus = _.get(item, 'ipreqInformationGrp.ipreqStatus');
    const ipApplicationNo = _.get(item, 'ipreqInformationGrp.ipApplicationNo'); // 画面表示: Request No
    // Submit「ON」でIPREQ Statusがない（⑥）
    if (ipSubmitFlg === IP_SUBMIT_FLG.ON && !ipreqStatus) {
      errorA = true;
      if (ipApplicationNo) {
        messagesA.push(ipApplicationNo);
      }
    }
  });
  if (errorA) {
    let errorMessages = [];
    const errorMessagesA = messagesA.length ? 'Request No ' + messagesA.join(', ') + ': ' : '';
    errorMessages.push(errorMessagesA + 'IPREQ Status is required.');
    // console.log(errorMessages.join('<br>'))
    throw errorMessages.join('<br>');
  } else return;
};

// To担当者 Apply 必須チェック Submit「ON」でregistrationNoに値がない
export const checkToRequiredRegistrationNo = async cloneItems => {
  let errorB = false;
  let messagesB = [];
  // ipreqListをチェック
  const ipreqList = _.get(cloneItems, 'processSeparate.ipreqList', []);
  await _.forEach(ipreqList, item => {
    const ipSubmitFlg = _.get(item, 'ipreqInformationGrp.ipSubmitFlg');
    const registrationNo = _.get(item, 'ipreqInformationGrp.registrationNo');
    const ipApplicationNo = _.get(item, 'ipreqInformationGrp.ipApplicationNo'); // 画面表示: Request No
    // Submit「ON」でregistrationNoに値がない
    if (ipSubmitFlg === IP_SUBMIT_FLG.ON && !registrationNo) {
      errorB = true;
      if (ipApplicationNo) {
        messagesB.push(ipApplicationNo);
      }
    }
  });
  if (errorB) {
    let errorMessages = [];
    const errorMessagesB = messagesB.length ? 'Request No ' + messagesB.join(', ') + ': ' : '';
    errorMessages.push(errorMessagesB + 'Registration No is required.');
    // console.log(errorMessages.join('<br>'))
    throw errorMessages.join('<br>');
  } else return;
};
