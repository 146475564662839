<template>
  <div class="booking_detail_inner" ref="inner">
    <header>
      <h1>Booking Confirmation</h1>
    </header>

    <tw-button v-if="res" type="secondary" size="small" class="toggle_button" :class="{ open: expand }" @click="openToggle">
      <img src="@/assets/images/icons/view_all.svg">
    </tw-button>

    <table class="card">
      <template v-if="res">
        <TwPreviewJson
          :items="res"
          title="Booking Confirmation"
          :initOpen="true"
          :getLabel="getLabel"
        />
      </template>
    </table>

    <tw-to-top v-if="mounted" :container="$refs.inner" class="detail-to-top" />
  </div>
</template>

<script>
import _ from 'lodash';
import { $api } from '@/store/ApiClient';
import TwPreviewJson from '@/components/molecules/TwPreviewJson';
import TwToTop from '@/components/atoms/TwToTop';
import TwButton from '@/components/atoms/TwButton';
// import bookingWebhook from '@/../public/bookingWebhook.json'; // スタブ

export default {
  name: 'TwBookingDetailInner',
  components: {
    TwPreviewJson,
    TwToTop,
    TwButton,
  },
  inject: ['s'],
  props: {
    item: Object,
  },
  data() {
    return {
      res: null,
      expand: true,
      mounted: false,
    };
  },
  computed: {
  },
  created() {
    this.fetch();
  },
  mounted() {
    this.mounted = true;
  },
  methods: {
    async fetch() {
      // スタブ
      // this.res = bookingWebhook;

      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/inttra-json/search/{entityId}/{processId}/{processSeq}/{processTrx}/{bookingSeq}',
          path: {
            entityId: this.s.tradeManagement.entityId,
            processId: this.s.tradeManagement.processId,
            processSeq: this.s.tradeManagement.processSeq,
            processTrx: this.item.processTrx,
            bookingSeq: this.item.bookingSeq,
          }
        }
      };
      $api.request(params)
      .then(res => {
        const jsonData = _.get(res, 'inttraJsonDetail.jsonData');
        if (_.isString(jsonData)) this.res = JSON.parse(jsonData);
        else this.res = jsonData;
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    openToggle() {
      this.expand = !this.expand;
      if (this.expand) this.$emit('openAll');
      else this.$emit('closeAll');
    },
    getLabel(str) {
      if (typeof str !== 'string') return str;
      str = str.charAt(0).toUpperCase() + str.split(/(?=[A-Z])/).join(' ').slice(1);
      // 例外処理（キャメルケースとなっていないKey等）TODO: 増えたら辞書化する
      return (
        str
          .replace(/(Inttra|I N T T R A)/, 'INTTRA')
          .replace(/I D/, 'ID')
          .replace(/ Id/, ' ID')
          .replace(/IDentifier/, 'Identifier')
          .replace(/Si Due/, 'SI Due')
          .replace(/Vgm/, 'VGM')
          .replace(/Bl /, 'BL ')
          .replace(/Ems/, 'EMS')
          .replace(/Imo/, 'IMO')
          .replace(/U S D/, 'USD')
          .replace(/Outof/, 'Out Of')
      );
    },
  }
}
</script>

<style lang="scss" scoped>
.booking_detail_inner {
  padding: 30px 35px;
  position: relative;
  height: 100vh;
  overflow-y: scroll;
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      font-size: 24px;
      margin-top: 8px;
    }
  }
  table.card {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    box-shadow: 0;
    overflow: hidden;
    margin-bottom: 24px;
  }
}
</style>
