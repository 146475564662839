<template>
  <div class="pdf_viewer">
    <header>
      <span @click="$emit('close-pdf-viewer')">
        Close
      </span>
      <img src="@/assets/images/icons/icon_close_white.svg" @click="$emit('close-pdf-viewer')">
    </header>
    <embed v-if="src !== '@'" :src="src">
    <div v-else class="loading">
      <img src="@/assets/images/loading.gif">
    </div>
  </div>
</template>

<script>
export default {
  name: 'TwPdfViewer',
  props: {
    src: String
  },
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
  .pdf_viewer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: black;
    z-index: 1000;

    header {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      height: 40px;
      padding-right: 20px;
      font-size: 40px;
      color: white;

      > span {
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
      }

      img {
        display: inline-block;
        vertical-align: middle;
        margin-left: 4px;
        cursor: pointer;
      }
    }

    embed {
      width: 100%;
      height: calc(100% - 40px);
    }

    .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: calc(100% - 40px);

      img {
        display: block;
        filter: invert(100%);
      }
    }
  }
</style>