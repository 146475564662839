<template>
  <div class="tw_approval_list permit_history">
    <tw-collapse title="L/C" :initialOpen="items && items.length > 0">
      <div class="approval_list">
        <div class="approval_list_item" v-for="(item, index) in items" :key="index">
          <header>
            <div v-if="item.updateUserName" class="column">
              <div class="left">
                <div class="user">
                  <figure class="avatar table"><img :src="item.updateUserIcon"></figure>
                  <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
                    <div slot="content">{{item.updateUserName}}</div>
                    <div class="from_name">{{item.updateUserName}}</div>
                  </el-tooltip>
                </div>
                <div class="date">{{item.updateDate | dateTimeFormat}}</div>
              </div>
              <div class="right">
                <div class="action" :class="{ linking: checkLinking(item.lcLinkFlg) }" @click="openDrawer(item)">
                  <div>L/C No {{item.documentaryCreditNumber}}</div>
                  <div>{{item.lcType}}</div>
                </div>
                <div class="link">
                  <img v-if="checkLinking(item.lcLinkFlg)" src="@/assets/images/icons/link_chain_on.svg">
                  <img v-else src="@/assets/images/icons/link_chain_off.svg">
                </div>
              </div>
            </div>
          </header>
        </div>
        <div v-if="!items.length" class="empty_message">{{$t('Message.NoResult1')}}</div>
      </div>
    </tw-collapse>

    <el-drawer :visible.sync="drawerShow" size="auto" :modal="false" :withHeader="false">
      <tw-lc-archive-detail-inner v-if="drawerShow" @close="close" :item="item" :tradeManagement="tradeManagement" />
      <div v-if="drawerShow" class="circle" @click="close" />
    </el-drawer>
  </div>
</template>

<script>
import _ from 'lodash';
import TwCollapse from '@/components/molecules/TwCollapse';
import TwLcArchiveDetailInner from '@/components/templates/TwLcArchiveDetailInner';
import { LC_LINK_FLG } from 'lib-tw-common';

export default {
  name: 'TwLcHistory',
  components: {
    TwCollapse,
    TwLcArchiveDetailInner,
  },
  inject: ['s'],
  data() {
    return {
      drawerShow: false,
      drawerItem: {},
      item: {},
      LC_LINK_FLG: LC_LINK_FLG,
    };
  },
  computed: {
    items() {
      let lcHistory = _.get(this, 's.res.lcHistory', []);
      if (lcHistory.length === 0) return [];
      // processTrx、lcLinkFlg別にグループ化しlcTypeをマージ
      lcHistory = _.map(_.groupBy(lcHistory, o => {
        return `${o.processTrx}_${o.lcLinkFlg}`;
      }), o => {
        return {
          ..._.head(o),
          messageType: _.uniq(_.map(o, 'messageType')),
          lcType: _.uniq(_.map(o, 'lcType')).sort().reverse().join(' '),
        };
      });
      // processTrx降順、lcLinkFlg降順で返却
      return _.orderBy(lcHistory, ['processTrx', 'lcLinkFlg'], ['desc', 'desc']);
    },
    tradeManagement() {
      return _.get(this, 's.res.tradeManagement', {});
    },
  },
  created() {},
  methods: {
    openDrawer(item) {
      if (this.checkLinking(item.lcLinkFlg)) {
        this.item = item;
        this.drawerShow = true;
      }
    },
    close() {
      this.drawerShow = false;
    },
    checkLinking(flg) {
      return flg === String(LC_LINK_FLG.LINKED) || flg === LC_LINK_FLG.LINKED;
    }
  }
};
</script>

<style lang="scss" scoped>
.tw_approval_list {
  margin-bottom: 16px;
  .approval_list {
    max-height: 410px;
    overflow-y: auto;
    padding: 0 16px;
  }
  .approval_list_item {
    border-bottom: 1px solid $color_gray_300;
    padding: 12px;
  }
  header {
    display: flex;
    justify-content: space-between;
    .column {
      display: flex;
      width: 100%;
    }
    .left {
      width: 112px;
      margin: 0 8px 4px 0;
      font-size: 12px;
      line-height: 20px;
      color: $color_black;
      .from_name {
        white-space:nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .user {
      display: flex;
      align-items: center;
    }
    .right {
      flex: auto;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .date, .action {
      font-size: 10px;
      line-height: 14px;
      color: $color_gray_600;
    }
    .action {
      &.linking {
        color: $color_dark_blue;
        cursor: pointer;
      }
    }
    .link {
      display: flex;
      align-items: center;
      margin-left: auto;
    }
  }
  .empty_message {
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: $color_black;
    margin-top: 33px;
    margin-bottom: 20px;
  }
}
::v-deep .el-drawer__wrapper {
  background: rgba(0, 0, 0, 0.2);
  .el-drawer__body {
    width: auto;
    width: 1082px;
    >.permit_detail_inner {
      padding: 30px 40px 30px 35px;
    }
  }
  .el-drawer,
  .el-drawer__body {
    overflow: visible;
  }

  .circle {
    position: absolute;
    top: 14px;
    left: -18px;
    width: 18px;
    height: 29px;
    background: $color_gray_800 url(../../assets/images/icons/chevron_right_white.svg) no-repeat 3px center;
    background-size: 16px auto;
    border-radius: 15px 0 0 15px;
    z-index: 11;
    cursor: pointer;
    &:hover {
      opacity: .5;
    }
  }
}
</style>
