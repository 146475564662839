<template>
  <div class="ipdn_detail_inner ipdn_history pool_detail" ref="inner">
    <header>
      <h1>
        <!-- {{$t('Label.L/C File')}} -->
        L/C
      </h1>
    </header>

    <tw-button v-if="res && hasLcdata" type="secondary" size="small" class="toggle_button" :class="{ open: expand }" @click="openToggle">
      <img src="@/assets/images/icons/view_all.svg">
    </tw-button>

    <table class="card">
      <template v-if="res && hasLcdata">
        <TwLcPoolProcessList :initialOpen="true" :initSelectLc="true" :isProcess="true" ref="processList" />
      </template>
    </table>
    <div v-if="res && hasLcdata" class="card _linking">
      <div class="item">
        <div class="title">Trading/FlowID</div>
        <div class="value">{{getTradingFlowId()}}</div>
      </div>
      <div class="item">
        <div class="title">Trading Name</div>
        <div class="value">{{tradeManagement.tradingManageName}}</div>
      </div>
      <div class="item">
        <div class="title">Flow Name</div>
        <div class="value">{{tradeManagement.tradingFlowName}}</div>
      </div>
      <!-- TODO: Ph3で非表示に 次Ph以降で対応 -->
      <!-- <div class="item">
        <div class="title">Invoice No</div>
        <div class="value">{{res.invoiceNo}}</div>
      </div> -->
      <div class="item process">
        <div class="title">Process</div>
        <div class="value link" @click="link">{{getProcessName()}}</div>
      </div>
      <div class="item">
        <div class="title">From - To</div>
        <div class="value flex">
          <div class="company">
            <figure class="avatar">
              <img :src="tradeManagement.from.fromCompanyIcon" v-default-src="'user'">
            </figure>
            <el-tooltip placement="bottom" popper-class="mcu header_tooltip" :tabindex="-1">
              <div slot="content">
                <div class="company_name">{{tradeManagement.from.fromCompanyName}}<br />{{tradeManagement.from.fromSectionName}}</div>
              </div>
              <p class="name">{{tradeManagement.from.fromCompanyShortName}}</p>
            </el-tooltip>
          </div>
          <div class="while">-</div>
          <div class="company">
            <figure class="avatar">
              <img :src="tradeManagement.to.toCompanyIcon" v-default-src="'user'">
            </figure>
            <el-tooltip placement="bottom" popper-class="mcu header_tooltip" :tabindex="-1">
              <div slot="content">
                <div class="company_name">{{tradeManagement.to.toCompanyName}}<br />{{tradeManagement.to.toSectionName}}</div>
              </div>
              <p class="name">{{tradeManagement.to.toCompanyShortName}}</p>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>

    <tw-to-top v-if="mounted" :container="$refs.inner" class="detail-to-top" />
  </div>
</template>

<script>
import _ from 'lodash';
import { $api } from '@/store/ApiClient';
import TwToTop from '@/components/atoms/TwToTop';
import TwButton from '@/components/atoms/TwButton';
import TwLcPoolProcessList from '@/components/organisms/TwLcPoolProcessList';
import { processNames, } from '@/dictionaries/map.js';

export default {
  name: 'TwLcArchiveDetailInner',
  components: {
    TwToTop,
    TwButton,
    TwLcPoolProcessList,
  },
  provide() {
    return {
      s: this,
      activeTab: null
    }
  },
  props: {
    item: Object,
    tradeManagement: Object,
  },
  data() {
    return {
      res: null,
      expand: true,
      mounted: false,
    };
  },
  computed: {
    // L/Cタブの表示
    hasLcdata() {
      const lcdata = _.get(this, 'res.linkageLc.lcpools[0].lcdata');
      return lcdata && Object.keys(lcdata).length > 0;
    },
  },
  created() {
    this.fetch();
  },
  mounted() {
    this.mounted = true;
  },
  methods: {
    cloneDeep(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    async fetch() {
      // アーカイブ詳細取得 疎通確認
      // bff_lc_2 LC接受プロセス詳細初期表示BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/lc_acceptance/{entityId}/{processId}/{processSeq}/initial',
          path: {
            entityId: this.tradeManagement.entityId,
            processId: this.tradeManagement.processId,
            processSeq: parseInt(this.tradeManagement.processSeq)
          },
          query: {
            processTrx: this.item.processTrx,
          }
        }
      };

      $api.request(params)
      .then(res => {
        res.linkageLc.lcpools = res.linkageLc.lcpools;
        this.res = res;
      })
      .catch(err => {
        this.$store.dispatch('SHOW_ALERT', err.message);
      });
    },
    openToggle() {
      this.expand = !this.expand;
      if (this.expand) this.$emit('openAll');
      else this.$emit('closeAll');
    },
    // プロセス詳細へ遷移
    link() {
      // プロセス詳細小窓から開いていた場合は、ドロワーを閉じる
      this.$emit('close');
      return;
    },
    getTradingFlowId() {
      const tradingId = this.tradeManagement.tradingId;
      const flowId = this.tradeManagement.tradingFlowId;
      return `${tradingId ? tradingId + '/' : ''}${flowId || ''}`;
    },
    getProcessName() {
      return _.get(processNames[this.tradeManagement.processId.replace(/\d/g, '')], 'nameEn', '');
    },
  }
}
</script>

<style lang="scss" scoped>
.ipdn_detail_inner {
  padding: 30px 35px;
  position: relative;
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .delete {
      margin: -30px 32px 0 auto;
    }

    &.deleted {
      flex-wrap: wrap;
      .drawer.el-dropdown {
        // width: 100%;
        .el-dropdown-link.ellipsis {
          margin: 0 16px 0 auto;
        }
      }
    }
  }
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .column {
      height: 41px;
      display: flex;
      align-items: center;
      margin-bottom: 22px;
      &:last-child {
        justify-content: flex-end;
      }
    }
    .tw_entity_icon {
      margin-right: 8px;
    }
    .title {
      font-weight: bold;
      font-size: 24px;
      line-height: 35px;
      margin-right: 8px;
    }
    .tw_status_icon {
      margin-right: 24px;
    }
    .tw_button {
      margin-right: 24px;
    }
  }
  table.card {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    box-shadow: 0;
    overflow: hidden;
    margin-bottom: 20px;
    // box-shadow: 0px 4.11278px 8.22556px rgba(170, 170, 204, 0.5);
    &.excustom {
      margin-bottom: 32px;
      ::v-deep .tw_process_list > .inner {
        display: none;
      }
    }
  }
  .card._linking {
    margin: 24px 0;
    padding: 24px;
    box-shadow: none;
    border: 1px solid $color_gray_300;
    border-radius: 6px;
    .item {
      display: flex;
      font-size: 14px;
      margin-bottom: 24px;
      &:hover {
        background: white;
        .value {
          color: $color_black;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      .title {
        width: 40%;
        color: $color_gray_800;
      }
      .value.flex {
        display: flex;
        align-items: center;
        .while {
          margin: 0 16px;
        }
      }
      .company {
        display: flex;
        align-items: center;
        figure.avatar {
          width: 32px;
          height: 32px;
          margin-right: 8px;
        }
        .name {
          max-width: 58px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &.process {
        .value {
          color: $color_dark_blue;
          &.link {
            cursor: pointer;
          }
        }
      }
    }
  }

  &.ipdn_history {
    height: 100vh;
    overflow-y: scroll;
  }
}

.attach_links {
  font-size: 14px;
  line-height: 20px;
  .file {
    position: relative;
    margin-top: 8px;
    padding-left: 16px;
    padding-right: 48px;
    border-radius: 6px;
    padding-top: 12px;
    padding-bottom: 12px;
    background: $color_gray_100;

    &:first-child {
      margin-top: 12px;
    }

    .inner {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .close_times {
      position: absolute;
      width: 24px;
      height: 24px;
      top: 50%;
      right: 16px;
      margin-top: -12px;
      cursor: pointer;
    }

    a.file_name {
      max-width: 240px;
      color: $color_dark_blue;
      margin-right: 10px;
      cursor: pointer;
      text-decoration: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.attach {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
.attachment {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 40px;
  padding: 16px;

  .choose_file {
    font-size: 12px;
    line-height: 18px;
    >button {
      margin-left: auto;
      display: block;
    }
    >span {
      color: $color_dark_blue;
      cursor: pointer;
    }

    .file_input {
      position: absolute;
      width: 1px;
      height: 1px;
      z-index: -1;
      pointer-events: none;
    }
  }

  .droparea {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 138px;
    margin: 16px 0 8px;
    background: $color_gray_100;
    border-radius: 6px;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    color: $color_dark_blue;
    pointer-events: none;
    z-index: 2;
    img {
      display: block;
      width: 84px;
      height: auto;
      margin-bottom: 9px;
    }
  }

  &.enter > * {
    pointer-events: none;
  }

  &.enter .droparea {
    border: 1px dashed $color_dark_blue;
  }
}
.buttons {
  display: flex;
  justify-content: right;
  padding: 24px;
}
</style>
<style lang="scss">
.el-dropdown-menu.el-popper.permit_drawer {
  z-index: 3000 !important;
}
</style>
