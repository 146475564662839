<template>
  <div class="bl_detail_inner" ref="inner">
    <header>
      <h1>{{`${processType === PROCESS_TYPE.TPRBL ? 'Request B/L' : 'B/L Info'} XML File`}}</h1>
      <el-dropdown v-if="res" class="drawer" trigger="click" placement="bottom" @command="onDropDownClick" :tabindex="-1">
        <tw-button class="menu_button" type="secondary" size="small" icon="menu">Menu</tw-button>
        <el-dropdown-menu class="permit_drawer nowrap">
          <el-dropdown-item command="xml">XML Download</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </header>

    <tw-button v-if="res" type="secondary" size="small" class="toggle_button" :class="{ open: expand }" @click="openToggle">
      <img src="@/assets/images/icons/view_all.svg">
    </tw-button>

    <table class="card">
      <template v-if="res">
        <TwPreviewJson
          :items="res"
          title="B/L"
          :initOpen="true"
          :getLabel="getLabel"
        />
      </template>
    </table>

    <tw-to-top v-if="mounted" :container="$refs.inner" class="detail-to-top" />
  </div>
</template>

<script>
import _ from 'lodash';
import { $api } from '@/store/ApiClient';
import TwPreviewJson from '@/components/molecules/TwPreviewJson';
import TwToTop from '@/components/atoms/TwToTop';
import TwButton from '@/components/atoms/TwButton';
import { PROCESS_TYPE, BASE64_FLG } from 'lib-tw-common';

// modules
import { XMLParser } from 'fast-xml-parser';
// TODO: B/Lスタブ用
// import axios from 'axios';

export default {
  name: 'TwBlDetailInner',
  components: {
    TwPreviewJson,
    TwToTop,
    TwButton
  },
  inject: ['s'],
  props: {
    item: Object
  },
  data() {
    return {
      res: null,
      expand: true,
      mounted: false,
      PROCESS_TYPE: PROCESS_TYPE
    };
  },
  computed: {
    processType() {
      return this.s.processType;
    }
  },
  created() {
    this.fetch();
  },
  mounted() {
    this.mounted = true;
  },
  methods: {
    async fetch() {
      // スタブB/L用（実際はBFFからファイルを取得）
      // axios.get('../../../IFTMCS_1000343156_20221026064952.xml').then(res => {
      //   console.log(res);
      //   this.getBlData(res.data);
      // });

      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/bl-info/inttra-xml/search',
          query: {
            base64Flg: BASE64_FLG.OFF,
            communicationDate: this.item.communicationDate,
            entityId: this.item.entityId,
            processId: this.item.processId,
            processSeq: this.item.processSeq,
            processTrx: this.item.processTrx
          },
          responseType: 'arraybuffer'
        }
      };
      $api
        .request(params)
        .then(res => {
          this.getBlData(res);
        })
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
        });
    },
    // B/Lファイル → JSON変換
    getBlData(data) {
      const reader = new FileReader();
      reader.onload = () => {
        this.xmlParse(reader.result);
        // this.xmlParse(this.closeTagCheck(reader.result));
      };
      reader.readAsText(new Blob([data], { type: 'text/xml' }));
    },
    // 終了タグなしチェック
    // closeTagCheck(xml) {
    //   const openPattern = /<([a-zA-Z1-9:]+)([^>]*)>/g;
    //   let found = openPattern.exec(xml);
    //   // 開始タグ検索
    //   while (found) {
    //     const tagName = found[1];
    //     const tagAttr = found[2];
    //     // 終了タグがあるか判定
    //     if (!xml.match(`</${tagName}>`)) {
    //       // 終了タグがない場合は<>を置換する
    //       xml = xml.replace(`<${tagName}${tagAttr}>`, `&lt;${tagName}${tagAttr}&gt;`);
    //       console.warn(`<${tagName}${tagAttr}>`, `&lt;${tagName}${tagAttr}&gt;`)
    //     }
    //     // 次の検索へ openPattern(RegExpオブジェクト)がnullを返すようになったら検索終了
    //     found = openPattern.exec(xml);
    //   }
    //   return xml;
    // },
    xmlParse(file) {
      // console.log(file);
      // xmlパース 参考：https://zenn.dev/syon/articles/a24c5f4b3d2a7f デモ：https://naturalintelligence.github.io/fast-xml-parser/
      const xp = new XMLParser({
        // options 参考：https://github.com/NaturalIntelligence/fast-xml-parser/blob/HEAD/docs/v4/2.XMLparseOptions.md
        ignoreDeclaration: true, // '?xml': ''を除外
        ignoreAttributes: false, // タグの属性を無視しない
        attributeNamePrefix: '', // 属性のkeyにあたる箇所の見出し接頭辞の指定（デフォルトは"@_" + 見出し）
        textNodeName: 'Text' // 属性のtextにあたる箇所の見出しの指定（デフォルトは"#text"）
      });
      const xml = xp.parse(file);
      // console.log(xml);

      // パースしたObjectから不要な階層(Level)を除外
      const message = _.get(xml, 'Message');
      if (message && _.get(message, 'Header') && _.get(message, 'MessageBody')) {
        this.res = { ...message.Header, ...message.MessageBody };
      } else {
        this.res = xml;
      }
      // console.log(this.res);
    },
    // XMLダウンロード
    onDropDownClick(command) {
      if (command === 'xml') this.$emit('xml-download', this.item);
    },
    openToggle() {
      this.expand = !this.expand;
      if (this.expand) this.$emit('openAll');
      else this.$emit('closeAll');
    },
    getLabel(str) {
      if (typeof str !== 'string') return str;
      str =
        str.charAt(0).toUpperCase() +
        str
          .split(/(?=[A-Z])/)
          .join(' ')
          .slice(1);
      // 例外処理（キャメルケースとなっていないKey等）TODO: 増えたら辞書化する
      return str
        .replace(/I D/, 'ID')
        .replace(/ Id/, ' ID')
        .replace(/IDentifier/, 'Identifier')
        .replace(/Bl /, 'BL ')
        .replace(/L C/, 'LC')
        .replace(/I M O/, 'IMO')
        .replace(/I M D G/, 'IMDG')
        .replace(/U N D G/, 'UNDG')
        .replace(/E M S/, 'EMS')
        .replace(/U O M/, 'UOM')
        .replace(/S C A C/, 'SCAC');
    }
  }
};
</script>

<style lang="scss" scoped>
.bl_detail_inner {
  padding: 30px 35px;
  position: relative;
  height: 100vh;
  overflow-y: scroll;
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      font-size: 24px;
      margin-top: 8px;
    }
  }
  table.card {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    box-shadow: 0;
    overflow: hidden;
    margin-bottom: 24px;
  }
}
</style>
