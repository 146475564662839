import _ from 'lodash';
import BigNumber from 'bignumber.js';
import { FREE_CHARGE_FLG } from 'lib-tw-common';

/**
 * 商品集計用MixIn
 */
export default {
  computed: {
    // 集計に必要な値
    summary() {
      if (!this.cloneItems.linkageGoodsLine) {
        return [];
      }
      return _.map(this.cloneItems.linkageGoodsLine, goods => {
        const price = _.find(this.cloneItems.linkageGoodsLinePrice, p => {
          return p.keyGrp.goodsLineSeq === goods.keyGrp.goodsLineSeq;
        });
        return {
          unit1: goods.quantityGrp.unit1,
          currency: price ? price.priceGrp.currency : null,
          unit: price ? price.priceGrp.unit : null,
          quantity1: goods.quantityGrp.quantity1,
          price: price ? price.priceGrp.price : null,
          amount: price ? price.amountGrp.amount : null,
          noCommercialFlag: price ? price.commercialGrp.noCommercialFlag : null,
          goodsLineId: goods.keyGrp.goodsLineId,
          goodsLineSeq: goods.keyGrp.goodsLineSeq,
        };
      })
    },
    packingSummary() {
      if (!this.cloneItems.linkageGoodsLinePacking) {
        return [];
      }
      return _.map(this.cloneItems.linkageGoodsLinePacking, goods => {
        return {
          weightUnit: goods.netGrossMeasurementGrp.weightUnit,
          netTotal: goods.netGrossMeasurementGrp.netTotal,
          grossTotal: goods.netGrossMeasurementGrp.grossTotal,
          measurementUnit: goods.netGrossMeasurementGrp.measurementUnit,
          measurementTotal: goods.netGrossMeasurementGrp.grossMeasurementTotal
        };
      })
    },
    totalQuantityUnit() {
      return this.isSame(this.summary, 'unit1', 'quantity1') || false;
    },
    totalCurrency() {
      return this.isSame(this.summary, 'currency', 'amount') || false;
    },
    totalQuantity() {
      if (!this.totalQuantityUnit) {
        return false;
      }

      const quantities = _.compact(_.map(this.summary, goods => {
        return goods.quantity1;
      }));

      const result = BigNumber.sum.apply(null, quantities).toNumber();
      return result ? result : result === 0 ? 0 : null;
    },
    // 合計金額
    totalAmount() {
      if (!this.totalCurrency) {
        return false;
      }

      const amount = _.compact(_.map(this.summary, goods => {
        if (goods.noCommercialFlag === FREE_CHARGE_FLG.ON) {
          // 無償フラグが立っている時は合算しない
          return 0;
        }
        return goods.amount;
      }));

      const result = BigNumber.sum.apply(null, amount).toNumber();
      return result ? result : result === 0 ? 0 : null;
    },
    // 無償分総合計金額
    noCommercialValueAmount() {
      if (!this.totalCurrency) {
        return false;
      }

      const amount = _.compact(_.map(this.summary, goods => {
        if (goods.noCommercialFlag !== FREE_CHARGE_FLG.ON) {
          // 無償フラグが立っていない時は合算しない（未選択時も）
          return 0;
        }
        return goods.amount;
      }));

      const result = BigNumber.sum.apply(null, amount).toNumber();
      return result ? result : result === 0 ? 0 : null;
    },
    totalWeightUnit() {
      return this.isSame(this.packingSummary, 'weightUnit') || false;
    },
    // NET重量合計
    totalNetWeight() {
      if (!this.totalWeightUnit) {
        return false;
      }

      const netTotal = _.compact(_.map(this.packingSummary, goods => {
        return goods.netTotal;
      }));

      const result = BigNumber.sum.apply(null, netTotal).dp(4).toNumber();
      return result ? result : result === 0 ? 0 : null;
    },
    // GROSS重量合計
    totalGrossWeight() {
      if (!this.totalWeightUnit) {
        return false;
      }

      const grossTotal = _.compact(_.map(this.packingSummary, goods => {
        return goods.grossTotal;
      }));

      const result = BigNumber.sum.apply(null, grossTotal).dp(4).toNumber();
      return result ? result : result === 0 ? 0 : null;
    },
    totalMeasurementUnit() {
      return this.isSame(this.packingSummary, 'measurementUnit', 'measurementTotal') || false;
    },
    // 容積合計
    totalMeasurement() {
      if (!this.totalMeasurementUnit) {
        return false;
      }

      const measurementTotal = _.compact(_.map(this.packingSummary, goods => {
        return goods.measurementTotal;
      }));

      const result = BigNumber.sum.apply(null, measurementTotal).dp(6).toNumber();
      return result ? result : result === 0 ? 0 : null;
    },
    // 加減合計
    expensesTotalAmount() {
      const group = _.get(this.cloneItems, 'linkageContract.expensesGrp');
      if (!group) {
        return null;
      }
      const items = _.compact(_.filter(group, (value, key) => {
        return /expenses\d+Amount/.test(key) || key === 'expensesFreight' || key === 'expensesInsurance';
      }));
      const result = BigNumber.sum.apply(null, items).toNumber();
      return result ? result : result === 0 ? 0 : null;
    },
    // 契約合計金額＋加減合計
    adjustedAmount() {
      const group = _.get(this.cloneItems, 'linkageContract.expensesGrp');
      if (!group) {
        return null;
      }
      const result = BigNumber.sum.apply(null, _.compact([this.expensesTotalAmount, this.cloneItems.linkageContract.contractAmountGrp.contractTotalAmount])).toNumber();
      return result ? result : result === 0 ? 0 : null;
    },
    // 見積もり運賃合計金額
    estimateQuotationOfTotalFreight() {
      const group = _.get(this.cloneItems, 'processSeparate.estimateFareGrp');
      if (!group) {
        return null;
      }
      const values = [
        'estimateBaseFreight',
        'estimateBaseFreight2',
        'estimateBaseFreight3',
        'estimateBaf',
        'estimateCaf',
        'estimateCfsCharge',
        'estimateOthers',
      ];
      const items = _.compact(_.filter(group, (value, key) => {
        return values.includes(key);
      }));
      const result = BigNumber.sum.apply(null, items).toNumber();
      return result ? result : result === 0 ? 0 : null;
    },
    // 実質運賃合計金額
    actualQuotationOfTotalFreight() {
      const group = _.get(this.cloneItems, 'processSeparate.actualFareGrp');
      if (!group) {
        return null;
      }
      const values = [
        'actualBaseFreight',
        'actualBaseFreight2',
        'actualBaseFreight3',
        'actualBaf',
        'actualCaf',
        'actualCfsCharge',
        'actualOthers',
      ];
      const items = _.compact(_.filter(group, (value, key) => {
        return values.includes(key);
      }));
      const result = BigNumber.sum.apply(null, items).toNumber();
      return result ? result : result === 0 ? 0 : null;
    },
    // 見積運賃(AIR)合計金額
    estimateTotalCharge() {
      const group = _.get(this.cloneItems, 'processSeparate.estimateFreightAirGrp');
      if (!group) {
        return null;
      }
      const values = [
        'estimateAirfreight',
        'estimateFuelSurcharge',
        'estimateOtherCharge',
      ];
      const items = _.compact(_.filter(group, (value, key) => {
        return values.includes(key);
      }));
      const result = BigNumber.sum.apply(null, items).toNumber();
      return result ? result : result === 0 ? 0 : null;
    },
    // 実質運賃(AIR)合計金額
    actualTotalCharge() {
      const group = _.get(this.cloneItems, 'processSeparate.actualFreightAirGrp');
      if (!group) {
        return null;
      }
      const values = [
        'actualAirfreight',
        'actualFuelSurcharge',
        'actualOtherCharge',
      ];
      const items = _.compact(_.filter(group, (value, key) => {
        return values.includes(key);
      }));
      const result = BigNumber.sum.apply(null, items).toNumber();
      return result ? result : result === 0 ? 0 : null;
    },
  },
  watch: {
    'totalQuantity': {
      handler(value) {
        this.$emit('onTotalChange', value, 'totalQuantity');
      },
      // immediate: true,
    },
    'totalQuantityUnit': {
      handler(value) {
        this.$emit('onTotalChange', value);
      },
    },
    'totalAmount': {
      handler(value) {
        this.$emit('onTotalChange', value);
      },
      // immediate: true,
    },
    'noCommercialValueAmount': {
      handler(value) {
        this.$emit('onTotalChange', value);
      },
      // immediate: true,
    },
    'totalCurrency': {
      handler(value) {
        this.$emit('onTotalChange', value);
      },
    },
    'totalMeasurement': {
      handler(value) {
        this.$emit('onPackingMeasurementChange', value);
      },
      // immediate: true,
    },
    'totalMeasurementUnit': {
      handler(value) {
        this.$emit('onPackingMeasurementChange', value);
      },
    },
    'totalNetWeight': {
      handler(value) {
        this.$emit('onTotalPackingChange', value);
      },
      // immediate: true,
    },
    'totalGrossWeight': {
      handler(value) {
        this.$emit('onTotalPackingChange', value);
      },
      // immediate: true,
    },
    'totalWeightUnit': {
      handler(value) {
        this.$emit('onTotalPackingChange', value);
      },
    },
    'expensesTotalAmount': {
      handler(value) {
        this.$emit('onExpensesTotalAmountChange', value);
      },
    },
    'adjustedAmount': {
      handler(value) {
        this.$emit('onAdjustedAmountChange', value);
      },
    },
    'estimateQuotationOfTotalFreight': {
      handler(value) {
        this.$emit('onTotalFreightChange', value);
      },
    },
    'actualQuotationOfTotalFreight': {
      handler(value) {
        this.$emit('onTotalFreightChange', value);
      },
    },
  },
  methods: {
    isSame(list, key, value) {
      let ret = false;
      const isSame = _.every(list, (goods, index) => {
        const target = _.get(goods, key);
        const val = _.get(goods, value);
        if (!target && target !== 0) {
          if (!val) {
            // 単位と値の両方がないときは無視
            return true;
          } else {
            //
            return false;
          }
        }
        if (!val) {
          // 単位だけある時はfalse、値の引数がない時は続行
          if (value) {
            return false;
          }
        }
        if (index > 0 && ret && target !== ret) {
          return false;
        }
        ret = target;
        return true;
      });
      if (isSame) {
        return ret;
      }
      return false;
    },
  }
};
