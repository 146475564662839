<template>
  <div class="tw_mail">
    <tw-table-system :schemas="schemas" :height="380" :nopager="true" :items="items" @row-click="rowClick" @row-dbclick="rowDbClick" />
    <footer v-if="isEnable">
      <tw-button type="secondary" size="medium" @click="showNewMail">Send Email</tw-button>
    </footer>
  </div>  
</template>

<script>
import _ from 'lodash';
import { ABLE_FLG } from 'lib-tw-common';
import mixinRouteUtils from '@/utils/mixinRouteUtils.js';
import TwButton from '@/components/atoms/TwButton';
import TwTableSystem from '@/components/organisms/TwTableSystem';
import schemas from '@/dictionaries/processMailListSchema.json';

export default {
  name: 'TwMail',
  mixins: [mixinRouteUtils],
  inject: ['s'],
  components: {
    TwButton,
    TwTableSystem,
  },
  data() {
    return {
      schemas: schemas,
      selection: false,
      expand: false,
    }
  },
  computed: {
    items() {
      return _.get(this.s, 'res.emailTransmissionHistories') || [];
    },
    isEnable() {
      return !this.s.isArchive && _.get(this.s, 'tradeManagement.mailChatDocumentAbleFlg') === ABLE_FLG.TRUE;
    }
  },
  methods: {
    rowClick(row) {
      this.s.showMailDetail(row.noticeHistoryId);
    },
    rowDbClick(row) {
      this.linkToOtherWindow({
        name: 'MailDetailFullProcess',
        params: { noticeHistoryId: row.noticeHistoryId, processId: this.s.processId, entityId: this.s.entityId, processSeq: this.s.processSeq },
        query: { processTrx: this.s.processTrx},
      });
    },
    showNewMail() {
      this.s.showNewMail();
    }
  }
};
</script>

<style lang="scss" scoped>
.tw_mail {
  position: relative;
  // height: 473px;
  background: #FFFFFF;
  padding: 17px 16px 13px;
  z-index: 2;
  // overflow-y: auto;
  height: 472px;
}

footer {
  display: flex;
  justify-content: flex-end;
}

::v-deep .empty_message {
  height: 350px;
  margin-bottom: 20px;
}

::v-deep .tw_table {
  margin-bottom: 20px;

  tr td:first-child div.cell {
    padding-left: 6px;
  }

  td .cell {
    padding: 0 6px;
  }

  .avatars {
    padding-left: 3px!important;
    figure.avatar {
      margin-left: -6px;
    }
    &>div {
      display: none;
    }
  }

  td .cell span.customerName {
    justify-content: center;

    figure.avatar.table {
      margin: 0;
    }
  }

  td .cell span.customerName:after {
    content: none;
  }
}
</style>
