<template>
  <div class="preview_json" :class="{open: open}">
    <div class="head" @click="toggle">
      <img class="arrow" src="@/assets/images/icons/accordion_arrow.svg">
      <div class="group_name">{{title}}</div>
    </div>
    <transition
      name="content"
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave"
      @after-leave="afterLeave"
    >
      <div v-show="open" class="collapse">
        <TwPreviewJsonParent
          v-for="(value, key, index) in items"
          :key="index"
          :value="value"
          :parentKey="key"
          :initOpen="initOpen"
          :getLabel="getLabel"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { collapseTransition } from '@/utils/nextFrame';
import TwPreviewJsonParent from '@/components/molecules/TwPreviewJsonParent';
// import bookingWebhook from '@/../public/bookingWebhook.json'; // スタブ

export default {
  name: 'TwPreviewJson',
  components: {
    TwPreviewJsonParent,
  },
  props: {
    title: String,
    items: Object,
    initOpen: {
      Type: Boolean,
      default: true,
    },
    getLabel: Function,
  },
  mixins: [collapseTransition],
  data() {
    return {
      open: this.initOpen,
      // items: bookingWebhook, // スタブ
    }
  },
  computed: {},
  created() {
    this.$parent.$on('openAll', this.openAll);
    this.$parent.$on('closeAll', this.closeAll);
  },
  beforeDestroy() {
    this.$parent.$off('openAll', this.openAll);
    this.$parent.$off('closeAll', this.closeAll);
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    openAll() {
      this.open = true;
      this.$emit('openAll');
    },
    closeAll() {
      this.open = false;
      this.$emit('closeAll');
    },
  }
};
</script>

<style lang="scss">
.preview_json {
  font-size: 14px;
  width: 100%;
  .head {
    @include list_shadow;
    display: flex;
    align-items: center;
    padding: 12px 8px;
    cursor: pointer;
    width: 100%;
    height: auto;
    img {
      display: block;
      width: 12px;
      height: 12px;
      transition: transform 0.3s;
    }
    .group_name {
      // font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: $color_gray_800;
      margin-left: 8px;
    }
  }
  &.open {
    .head img.arrow {
      transform: rotate(90deg);
    }
  }
  .collapse {
    border-top: 1px solid #ededf4;
    padding: 24px 16px 24px;
    width: 100%;
  }

  .parent {
    margin: 0 0 12px 0;
    width: 100%;
    border-bottom: 1px solid $color_gray_300;
    &.open {
      > .key_wrap {
        & > img.arrow {
          transform: rotate(90deg);
        }
      }
    }
  }
  .child {
    margin: 0 0 12px 12px;
    &.open {
      > .key_wrap {
        & > img.arrow {
          transform: rotate(90deg);
        }
      }
    }
  }
  .key_wrap {
    display: flex;
    margin: 0 0px 12px 0;
    padding: 0 4px;
    width: 100%;
    > img.arrow {
      margin-right: 8px;
      vertical-align: middle;
      transition: transform 0.3s;
    }
    &.is_object_array {
      cursor: pointer;
      > span {
        // color: #000;
        font-weight: bold;
      }
    }
  }
  .key {
    display: flex;
    color: $color_gray_600;
    font-size: 12px;
    width: 120px;
    min-width: 120px;
    margin-right: 16px;
  }
  .is_object {
    display: block;
    // width: 100%;
    > .key_wrap {
      width: 100%;
    }
  }
  .is_array {
    & > .is_object {
      margin-left: 0;
    }
  }
  .content-enter-active,
  .content-leave-active {
    transition: height 0.1s;
  }
}
</style>
