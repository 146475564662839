<template>
<div class="tw_approval_list">
  <tw-collapse title="Approval Required">
    <div class="approval_list">
      <div class="approval_list_item" v-for="(item, index) in items" :key="item.wfRequestSeq + '_' + index">
        <header>
          <div v-if="item.approveUserId" class="column">
            <div class="user">
              <figure class="avatar table"><img :src="item.approveUserIcon"></figure>
              <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
                <div slot="content">{{item.approveUserName}}</div>
                <div class="from_name">{{item.approveUserName}}</div>
              </el-tooltip>
              <div v-if="item.eventCancel" class="delete">Delete</div>
            </div>
            <div class="date">{{item.approveDate | dateTimeFormat}}</div>
          </div>
          <div v-else class="column">
            <div class="user">
              <figure class="avatar table"><img :src="item.requestUserIcon"></figure>
              <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
                <div slot="content">{{item.requestUserName}}</div>
                <div class="from_name">{{item.requestUserName}}</div>
              </el-tooltip>
              <div v-if="item.eventCancel" class="delete">Delete</div>
            </div>
            <div class="date">{{item.requestDate | dateTimeFormat}}</div>
          </div>
          <tw-status-label v-if="getStatus(item.action, item.mainFlg)" :statusName="getStatus(item.action, item.mainFlg)" />
        </header>
        <div class="comment" v-if="item.approveComment" v-html="sanitize(item.approveComment)"></div>
      </div>
      <div v-if="!items.length" class="empty_message">{{$t('Message.NoResult1')}}</div>
    </div>
  </tw-collapse>
</div>

</template>

<script>
import _ from 'lodash';
import { MAIN_FLG, PROCESS_OPERATION } from 'lib-tw-common';
import TwStatusLabel from '@/components/atoms/TwStatusLabel';
import TwCollapse from '@/components/molecules/TwCollapse';

export default {
  name: 'TwApprovalList',
  inject: ['s'],
  components: {
    TwStatusLabel,
    TwCollapse,
  },
  data() {
    return {};
  },
  computed: {
    items() {
      return _.get(this, 's.res.requestApproveHisTrx') || [];
    },
    sanitize() {
      return string => {
        return this.$sanitize(string).replace(/\n/g, '<br>');
      }
    },
  },
  methods: {
    getStatus(action, mainFlg) {
      if ([PROCESS_OPERATION.F_APPROVE_REQ, PROCESS_OPERATION.T_APPROVE_REQ].includes(action)) {
        return 'REQUEST';
      } else if ([PROCESS_OPERATION.F_APPROVE, PROCESS_OPERATION.T_APPROVE].includes(action)) {
        if (mainFlg === MAIN_FLG.MAIN) {
          return 'APPROVER';
        } else {
          return 'DEPUTY_APPROVER';
        }
      } else if ([PROCESS_OPERATION.F_REMAND, PROCESS_OPERATION.F_PULL_BACK, PROCESS_OPERATION.T_REMAND, PROCESS_OPERATION.T_PULL_BACK, PROCESS_OPERATION.T_RECONFIRMATION].includes(action)) {
        return 'RETURN';
      } else {
        return '';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.tw_approval_list {
  margin-bottom: 16px;

  .approval_list {
    max-height: 410px;
    overflow-y: auto;
    padding: 0 16px;
  }

  .approval_list_item {
    border-bottom: 1px solid $color_gray_300;
    padding: 12px;
  }

  header {
    display: flex;
    justify-content: space-between;

    .column {
      width: calc(100% - 104px);
    }

    .user {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      font-size: 12px;
      line-height: 20px;
      color: $color_black;

      .from_name {
        white-space:nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .delete {
      display: inline-flex;
      align-items: center;
      font-weight: bold;
      flex-shrink: 0;
      height: 16px;
      font-size: 10px;
      line-height: 14px;
      border-radius: 8px;
      padding: 0 8px;
      margin-left: 8px;
      background: #E00001;
      color: $color_white;
    }

    .date {
      font-size: 10px;
      line-height: 14px;
      color: $color_gray_600;
    }

    .status {
      margin-left: 12px;
    }

    .tw_status_label {
      margin-top: 4px;
    }
  }

  .comment {
    padding-top: 8px;
    font-size: 14px;
    line-height: 20px;
  }

  .empty_message {
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: $color_black;
    margin-top: 33px;
    margin-bottom: 20px;
  }
}
</style>
