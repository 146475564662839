<template>
  <div class="tw_approval_list permit_history">
    <tw-collapse :title="`Booking History`" :initialOpen="items && items.length > 0 && Object.keys(items[0]).length > 0">
      <div class="approval_list">
        <div class="approval_list_item" v-show="items && items.length > 0 && Object.keys(items[0]).length > 0" v-for="(item, index) in items" :key="index">
          <header>
            <div v-if="item.createUserName" class="column">
              <div class="user">
                <figure class="avatar table"><img :src="item.createUserIcon"></figure>
                <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
                  <div slot="content">{{item.createUserName}}</div>
                  <div class="from_name">{{item.createUserName}}</div>
                </el-tooltip>
              </div>
              <div class="between">
                <div class="state">
                  <div class="date">{{item.createDate | dateTimeFormat}}</div>
                  <div class="badge">{{getBookingStatus(item.bookingStatus)}}</div>
                </div>
                <div v-show="checkCanOpenDrawer(item)" class="action" @click="openDrawer(item)">
                  <img src="@/assets/images/icons/booking_icon.svg">
                </div>
              </div>
            </div>
          </header>
        </div>
        <div v-if="!items.length || (items.length && Object.keys(items[0]).length === 0)" class="empty_message">{{$t('Message.NoResult1')}}</div>
      </div>
    </tw-collapse>

    <el-drawer :visible.sync="drawerShow" size="auto" :modal="false" :withHeader="false">
      <TwBookingDetailInner v-if="drawerShow" @close="close" :item="item" />
      <div v-if="drawerShow" class="circle" @click="close" />
    </el-drawer>
  </div>
</template>

<script>
import _ from 'lodash';
import TwCollapse from '@/components/molecules/TwCollapse';
import TwBookingDetailInner from '@/components/templates/TwBookingDetailInner';
import { BOOKING_STATUS_VARIABLES, BOOKING_STATUS } from 'lib-tw-common';

export default {
  name: 'TwBookingHistory',
  components: {
    TwCollapse,
    TwBookingDetailInner,
  },
  inject: ['s'],
  data() {
    return {
      drawerShow: false,
      // スタブ
      // items: [
      //   {
      //     'bookingStatus': 1,
      //     'bookingState': 'Confirmed',
      //     'createUserId': '1234567890',
      //     'createUserName': 'createUserName',
      //     'createUserIcon': '',
      //     'createDate': '2022-08-22',
      //     'bookingSeq': 1,
      //   },
      // ],
      item: {},
    };
  },
  computed: {
    items() {
      return _.get(this, 's.res.bookingHistory') || [];
    },
  },
  created() {},
  methods: {
    openDrawer(item) {
      this.item = item;
      this.drawerShow = true;
    },
    close() {
      this.drawerShow = false;
    },
    getBookingStatus(val) {
      const target = BOOKING_STATUS_VARIABLES.find(o => o.code === val);
      return target ? target.label : '';
    },
    checkCanOpenDrawer(item) {
      // ステータスがPending, Confirmed, Declined, ConditionallyAcceptedのときのみアイコンを表示しドロワーを開くことが可能
      return item.bookingSeq && (item.bookingStatus === BOOKING_STATUS.PENDING || item.bookingStatus === BOOKING_STATUS.CONFIRMED || item.bookingStatus === BOOKING_STATUS.DECLINED || item.bookingStatus === BOOKING_STATUS.CONFIRMED_CONDITIONALLY_ACCEPTED);
    }
  }
};
</script>

<style lang="scss" scoped>
.tw_approval_list {
  margin-bottom: 16px;
  .approval_list {
    max-height: 410px;
    overflow-y: auto;
    padding: 0 16px;
  }
  .approval_list_item {
    border-bottom: 1px solid $color_gray_300;
    padding: 12px;
  }
  header {
    display: flex;
    justify-content: space-between;
    .column {
      width: 100%;
    }
    .user {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      font-size: 12px;
      line-height: 20px;
      color: $color_black;
      .from_name {
        white-space:nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .between {
      display: flex;
      justify-content: space-between;
    }
    .state {
      .badge {
        font-size: 10px;
        padding: 2px 8px;
        text-align: center;
        background: #0500FF;
        border-radius: 15.5px;
        color: white;
        font-weight: bold;
      }
    }
    .date, .action {
      font-size: 10px;
      line-height: 14px;
      color: $color_gray_600;
    }
    .date {
      margin-bottom: 8px;
    }
    .action {
      cursor: pointer;
      display: flex;
      align-items: center;
      >img {
        margin-left: 8px;
        &.pdf {
          width: 20px;
        }
      }
    }
  }
  .empty_message {
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: $color_black;
    margin-top: 33px;
    margin-bottom: 20px;
  }
}
::v-deep .el-drawer__wrapper {
  background: rgba(0, 0, 0, 0.2);
  .el-drawer__body {
    width: auto;
    width: 990px;
    >.permit_detail_inner {
      padding: 30px 40px 30px 35px;
    }
  }
  .el-drawer,
  .el-drawer__body {
    overflow: visible;
  }

  .circle {
    position: absolute;
    top: 14px;
    left: -18px;
    width: 18px;
    height: 29px;
    background: $color_gray_800 url(../../assets/images/icons/chevron_right_white.svg) no-repeat 3px center;
    background-size: 16px auto;
    border-radius: 15px 0 0 15px;
    z-index: 11;
    cursor: pointer;
    &:hover {
      opacity: .5;
    }
  }
}
</style>
