<!-- eslint-disable vue/require-v-for-key -->
<template>
  <table class="goods_table" :class="{attach_page: page !== -1}">
    <template v-if="items && items.length">
      <tr>
        <th colspan="3">Goods Name</th>
        <th>Quantity1</th>
        <th>Unit1</th>
        <th>Price</th>
        <th>Currency</th>
        <th>Amount</th>
      </tr>
      <tr>
        <th>HS Code</th>
        <th>Reference1</th>
        <th>Reference2</th>
        <th>Quantity2</th>
        <th>Unit2</th>
        <th colspan="2">Origin</th>
        <th>No Commercial</th>
      </tr>
    </template>
    
    <template v-for="item in items">
      <tr class="goods_tr">
        <td colspan="3">{{item.goodsShortName}}</td>
        <td class="right">{{item.quantity1}}</td>
        <td>{{item.unit1}}</td>
        <td class="right">{{item.price}}</td>
        <td>{{item.currency}}</td>
        <td class="right">{{item.amount}}</td>
      </tr>
      <tr class="goods_tr">
        <td>{{item.hsCode}}</td>
        <td>{{item.goodsRefNo1}}</td>
        <td>{{item.goodsRefNo2}}</td>
        <td class="right">{{item.quantity2}}</td>
        <td>{{item.unit2}}</td>
        <td colspan="2">{{item.countryOfOriginName}}</td>
        <td>{{item.noCommercialFlag}}</td>
      </tr>
    </template>
    <template v-if="page === -1">
      <tr>
        <th colspan="3">Quantity Total</th>
        <th>Quantity Unit</th>
        <th colspan="3">Amount Total</th>
        <th>Currency</th>
      </tr>
      <tr>
        <td colspan="3" class="right">{{quantityTotal}}</td>
        <td>{{quantityUnit}}</td>
        <td colspan="3" class="right">{{amountTotal}}</td>
        <td>{{amountCurrency}}</td>
      </tr>
    </template>
    
    <template v-if="(page === -1 && !hasOtherExpenses) || (last && hasOtherExpenses)">
      <tr>
        <td colspan="4" style="border:0"></td>
        <th colspan="1">Freight</th>
        <td colspan="2" class="right">{{formatCurrency(expenses.expensesFreight)}}</td>
        <td colspan="1">{{adjustedCurrency}}</td>
      </tr>
      <tr>
        <td colspan="4" style="border:0"></td>
        <th colspan="1">Insurance</th>
        <td colspan="2" class="right">{{formatCurrency(expenses.expensesInsurance)}}</td>
        <td colspan="1">{{adjustedCurrency}}</td>
      </tr>
      <tr v-if="page === -1 && !hasOtherExpenses">
        <td colspan="4" style="border:0"></td>
        <th colspan="1">Expense Total</th>
        <td colspan="2" class="right">{{formatCurrency(expenses.expensesTotalAmount)}}</td>
        <td colspan="1">{{adjustedCurrency}}</td>
      </tr>
    </template>

    <template v-for="i in [1,2,3,4,5,6,7,8,9,10]">
      <tr v-if="last && (expenses[`expenses${i}Title`] || expenses[`expenses${i}Amount`])" class="expenses">
        <td colspan="4" style="width:140px;border:0"></td>
        <th colspan="1">{{expenses[`expenses${i}Title`] || `Expenses ${i} Title`}}</th>
        <td colspan="2" class="right">{{formatCurrency(expenses[`expenses${i}Amount`])}}</td>
        <td colspan="1">{{adjustedCurrency}}</td>
      </tr>
    </template>

    <tr v-if="last && hasOtherExpenses" class="expense_total">
      <td colspan="4" style="border-top:0"></td>
      <th colspan="1" style="">Expense Total</th>
      <td colspan="2" style="" class="right">{{formatCurrency(expenses.expensesTotalAmount)}}</td>
      <td colspan="1" style="">{{adjustedCurrency}}</td>
    </tr>
    
    <template v-if="page === -1">
      <tr>
        <th colspan="7">Adjusted Total</th>
        <th colspan="1">Currency</th>
      </tr>
      <tr>
        <td colspan="7" class="right">{{adjustedTotal}}</td>
        <td colspan="1">{{adjustedCurrency}}</td>
      </tr>
    </template>
  </table>
</template>

<script>
import _ from 'lodash';
import { PROCESS_TYPE } from 'lib-tw-common';
import { formatQuantity, formatCurrency } from '@/utils/searchUtil.js';

export default {
  name: 'TwIvPdfGoodsLineTable',
  inject: ['s'],
  props: {
    items: Array,
    page: Number,
    last: Boolean,
    expenses: Object,
    hasOtherExpenses: Boolean,
  },
  data() {
    return {
    };
  },
  computed: {
    quantityTotal() {
      if (this.s.processType === PROCESS_TYPE.ECREQ) {
        return formatQuantity(_.get(this.s.res, 'linkageExportCustoms.exCustomsAmountGrp.exCustomsTotalQuantity'));
      } else if (this.s.processType === PROCESS_TYPE.ICREQ) {
        return formatQuantity(_.get(this.s.res, 'linkageImportCustoms.imCustomsAmountGrp.imCustomsTotalQuantity'));
      }
      // 書類作成・書類送付プロセス
      return formatQuantity(_.get(this.s.res, 'linkageFinalDocs.finalQuantityAmountGrp.finalTotalQuantity'));
    },
    quantityUnit() {
      if (this.s.processType === PROCESS_TYPE.ECREQ) {
        return _.get(this.s.res, 'linkageExportCustoms.exCustomsAmountGrp.exCustomsTotalQuantityUnit') || '';
      } else if (this.s.processType === PROCESS_TYPE.ICREQ) {
        return _.get(this.s.res, 'linkageImportCustoms.imCustomsAmountGrp.imCustomsTotalQuantityUnit') || '';
      }
      // 書類作成・書類送付プロセス
      return _.get(this.s.res, 'linkageFinalDocs.finalQuantityAmountGrp.finalQuantityUnit') || '';
    },
    amountTotal() {
      if (this.s.processType === PROCESS_TYPE.ECREQ) {
        return formatCurrency(_.get(this.s.res, 'linkageExportCustoms.exCustomsAmountGrp.exCustomsTotalAmount'), null, this.s.getCurrencyMinorUnit(_.get(this.s.res, 'linkageExportCustoms.exCustomsAmountGrp.exCustomsCurrencyId')));
      } else if (this.s.processType === PROCESS_TYPE.ICREQ) {
        return formatCurrency(_.get(this.s.res, 'linkageImportCustoms.imCustomsAmountGrp.imCustomsTotalAmount'), null, this.s.getCurrencyMinorUnit(_.get(this.s.res, 'linkageImportCustoms.imCustomsAmountGrp.imCustomsCurrencyId')));
      }
      // 書類作成・書類送付プロセス
      return formatCurrency(_.get(this.s.res, 'linkageFinalDocs.finalQuantityAmountGrp.finalTotalAmount'), null, this.s.getCurrencyMinorUnit(_.get(this.s.res, 'linkageFinalDocs.finalQuantityAmountGrp.finalExchangeRateCurrencyId')));
    },
    amountCurrency() {
      if (this.s.processType === PROCESS_TYPE.ECREQ) {
        return _.get(this.s.res, 'linkageExportCustoms.exCustomsAmountGrp.exCustomsCurrency') || '';
      } else if (this.s.processType === PROCESS_TYPE.ICREQ) {
        return _.get(this.s.res, 'linkageImportCustoms.imCustomsAmountGrp.imCustomsCurrency') || '';
      }
      // 書類作成・書類送付プロセス
      return _.get(this.s.res, 'linkageFinalDocs.finalQuantityAmountGrp.finalExchangeRateCurrency') || '';
    },
    adjustedTotal() {
      return formatCurrency(_.get(this.s.res, 'linkageContract.expensesGrp.adjustedAmount'), null, this.s.getCurrencyMinorUnit(_.get(this.s.res, 'linkageContract.contractAmountGrp.contractCurrencyId')));
    },
    adjustedCurrency() {
      return _.get(this.s.res, 'linkageContract.contractAmountGrp.contractCurrency') || '';
    },
  },
  methods: {
    formatCurrency(value) {
      return formatCurrency(value, null, this.s.getCurrencyMinorUnit(_.get(this.s.res, 'linkageContract.contractAmountGrp.contractCurrencyId')));
    }
  }
};
</script>