/* eslint-disable */
const lcTypes = [
  {
    label: 'Original',
    keys: ['700', '701', '710', '711'],
  },
  {
    label: 'Cover',
    keys: ['CLT'],
  },
  {
    label: 'Amend',
    keys: ['707', '708', '799'],
  },
];
export default lcTypes;

// Original:700,701, 710,711
// Cover Letter
// Amend:707, 708, 799
