<template>
  <el-dialog class="tw-image-paste-and-upload-dialog" :visible="visible" append-to-body @update:visible="confirmClose">
    <tw-button type="secondary" size="medium" class="choose-file-button" @click="openFileExplorer">
      Choose Local File
      <input ref="fileInput" class="file-input" @change="chooseFile" type="file" :accept="`.${acceptableFileType.join(',.').replaceAll('image/', '')}`">
    </tw-button>
    <div class="image-paste-area" ref="imagePasteArea" contenteditable @input="checkInput" @paste="checkPaste"></div>
    <div class="warning-messages">
      <div>※ Please be careful not to register wrong image.</div>
      <div>※ 一度登録した画像は削除できないためご注意ください</div>
    </div>
    <div class="error-messages">
      <div class="error-message" v-show="isError.type">{{ errorMessages.type }}</div>
      <div class="error-message" v-show="isError.size">{{ errorMessages.size }}</div>
    </div>
    <div class="bottom-button-wrapper">
      <tw-button type="primary" size="medium" @click="$emit('emit-image-source', imageData)" :disabled="disabledEmitButton">{{ emitButtonLabel }}</tw-button>
    </div>
  </el-dialog>
</template>

<script>
import TwButton from '@/components/atoms/TwButton';

export default {
  name: 'TwImagePasteAndUploadDialog',
  components: {
    TwButton
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    acceptableFileType: {
      type: Array,
      required: false,
      default: () => ['image/png', 'image/jpeg', 'image/jpg', 'image/bmp']
    },
    acceptableFileSizeStr: {
      type: String,
      required: false,
      default: '10MB',
      validator: val => /\d*(byte|KB|MB|GB)$/.test(val)
    },
    emitButtonLabel: {
      type: String,
      required: false,
      default: 'Register'
    }
  },
  data() {
    return {
      imageData: null,
      recentComposing: false,
      isError: {
        type: false,
        size: false
      }
    }
  },
  computed: {
    errorMessages() {
      const messages = {
        type: '',
        size: `画像は${this.acceptableFileSizeStr}以下のものが登録可能です`
      }
      const types = this.acceptableFileType.map(type => type.split('/')?.[1]).join(',');
      messages.type = `画像は${types}の拡張子のものが登録可能です`;
      return messages;
    },
    acceptableFileSize() {
      if(this.acceptableFileSizeStr.includes('byte')) return +this.acceptableFileSizeStr.replace('byte', '');
      else if(this.acceptableFileSizeStr.includes('KB')) return +this.acceptableFileSizeStr.replace('KB', '') * 1024;
      else if(this.acceptableFileSizeStr.includes('MB')) return +this.acceptableFileSizeStr.replace('MB', '') * 1024**2;
      else if(this.acceptableFileSizeStr.includes('GB')) return +this.acceptableFileSizeStr.replace('GB', '') * 1024**3;
      return 0;
    },
    disabledEmitButton() {
      return this.imageData === null || this.isError.size;
    }
  },
  watch: {
    visible(val) {
      if(!val) this.reset();
    }
  },
  methods: {
    openFileExplorer() {
      const fileInput = this.$refs.fileInput;
      if(fileInput) {
        fileInput.value = '';
        fileInput.click();
      }
    },
    chooseFile(e) {
      const imagePasteAreaEl = this.$refs.imagePasteArea;
      const file = e.target?.files?.[0] || e.dataTransfer?.files?.[0];
      if(!this.acceptableFileType.includes(file.type)) {
        this.isError.type = true
        return;
      } else this.isError.type = false;
      imagePasteAreaEl.innerHTML = '';
      this.readFile(file, imagePasteAreaEl);
    },
    // スクショ画像の貼り付けの場合はpasteイベントとinputイベントが発火する（自動で貼り付けまで行われる）
    checkInput(event) {
      const imagePasteAreaEl = this.$refs.imagePasteArea;
      // 既存入力一掃のため一度削除
      imagePasteAreaEl.innerHTML = '';

      // 削除系の操作の場合は削除を行い、貼られていた履歴データも削除
      // ただし、isComposingの直後に「deleteContentBackward」が入ってしまうことがあるためそれは除外
      if(!this.recentComposing && ['deleteContentBackward', 'deleteContentForward'].includes(event.inputType)) {
        this.resetError();
        this.imageData = null;
      } else {
        if(event.type === 'paste' && this.acceptableFileType.includes(event?.clipboardData?.items[0]?.type)) {
          this.readFile(event.clipboardData.items[0].getAsFile(), imagePasteAreaEl);
        } else if(this.imageData !== null) {
          // 文字入力・文字のコピペが実施された場合は貼られていた画像を復元
          this.setImage(imagePasteAreaEl);
        }
      }
      this.recentComposing = event?.isComposing || false;
    },
    // ローカルファイル選択コピーから貼り付けの場合はinputイベントでは取れない
    checkPaste(event) {
      const imagePasteAreaEl = this.$refs.imagePasteArea;
      // 既存入力一掃のため一度削除
      imagePasteAreaEl.innerHTML = '';
      if(event.type === 'paste') {
        const type = event?.clipboardData?.items[0]?.type
        if(this.acceptableFileType.includes(type)) {
          this.isError.type = false;
          this.readFile(event.clipboardData.items[0].getAsFile(), imagePasteAreaEl);
        } else  {
          // 文字列のコピペの場合は拡張子エラーを出さない
          if(type !== 'text/html') this.isError.type = true;
          if(this.imageData !== null) this.setImage(imagePasteAreaEl);
        }
      }
    },
    readFile(file, target) {
      const fileReader = new FileReader();
      this.isError.size = file.size > this.acceptableFileSize;
      fileReader.readAsDataURL(file);

      fileReader.onload = e => {
        this.imageData = e.target.result;
        this.setImage(target);
      };
    },
    setImage(target) {
      target.innerHTML = '';
      const imgEl = document.createElement('img');
      imgEl.setAttribute('src', this.imageData);
      target.appendChild(imgEl);
      // カーソルを末尾に移動する
      const range = document.createRange();
      range.selectNodeContents(target);
      range.collapse(false);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    },
    confirmClose(close) {
      if(this.imageData === null) this.$emit('update:visible', close);
      else {
        this.$store
          .dispatch('SHOW_CONFIRM', 'Are you sure you want to leave?\nYour change will not be applied.')
          .then(() => {
              this.$emit('update:visible', close);
          })
          .catch(() => {});
      }
    },
    resetError() {
      for(const key of Object.keys(this.isError)) {
        this.isError[key] = false;
      }
    },
    reset() {
      const imagePasteAreaEl = this.$refs.imagePasteArea;
      imagePasteAreaEl.innerHTML = '';
      this.imageData = null;
      this.resetError();
    }
  }
}
</script>

<style lang="scss">
.tw-image-paste-and-upload-dialog {
  & .el-dialog__body {
    padding-bottom: 18px;
  }
  & .choose-file-button {
    margin-bottom: 24px;
  }

  & .image-paste-area {
    max-width: 100%;
    max-height: 55vh;
    overflow: auto;
    cursor: text;
    background-color: #F9F9FD;
    position: relative;

    & img {
      margin: 16px;
    }
    &:empty {
      height: 120px;
      padding: 50px;

      // 画像ペースト説明は、編集不可能要素かつ2行で文字サイズの変更などを行うためbefore, afterの要素として定義する
      &:before {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -40%);
        content: 'Paste Image Here';
        font-size: 16px;
      }
      &:after {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, calc(-40% + 24px));
        content: 'Click here and press Ctrl + V to paste image';
        font-size: 14px;
      }
      &:focus {
        &:before, &:after {
          opacity: .3;
        }
      }
    }
  }
  & .file-input {
    visibility: hidden;
    width: 0;
    height: 0;
  }
  & .warning-messages {
    margin-top: 8px;
  }
  & .error-messages {
    &:not(:empty) {
      margin-top: 8px;
    }
    & .error-message {
      color: $color_warning;
    }
  }
  & .bottom-button-wrapper {
    display: flex;
    justify-content: flex-end;
    padding-top: 16px;
  }
}
</style>