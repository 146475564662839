<!-- eslint-disable vue/require-v-for-key -->
<template>
  <table class="goods_table" :class="{attach_page: page !== -1}">
    <template v-if="items && items.length">
      <tr>
        <th colspan="6">Goods Name</th>
        <th colspan="4">TTL N. WT</th>
        <th colspan="3">TTL G. WT</th>
        <th colspan="3">Weight Unit</th>
        <th colspan="2">Num of Contents</th>
        <th colspan="2">Num of PKGS</th>
        <th colspan="4">Packing</th>
      </tr>
      <tr>
        <th colspan="2">Net L</th>
        <th colspan="2">Net W</th>
        <th colspan="2">Net H</th>
        <th colspan="4">Net MSMT</th>
        <th colspan="2">Gross L</th>
        <th colspan="2">Gross W</th>
        <th colspan="2">Gross H</th>
        <th colspan="2">LWH Unit</th>
        <th colspan="2">Gross MSMT</th>
        <th colspan="4">MSMT Unit</th>
      </tr>
    </template>
    
    <template v-for="item in items">
      <tr class="goods_tr">
        <td colspan="6">{{item.goodsShortName}}</td>
        <td colspan="4" class="right">{{item.netTotal}}</td>
        <td colspan="3" class="right">{{item.grossTotal}}</td>
        <td colspan="3">{{item.weightUnit}}</td>
        <td colspan="2" class="right">{{item.numberOfContents}}</td>
        <td colspan="2" class="right">{{item.numberOfPackages}}</td>
        <td colspan="4">{{item.packing}}</td>
      </tr>
      <tr class="goods_tr">
        <td colspan="2" class="right">{{item.netLength}}</td>
        <td colspan="2" class="right">{{item.netWidth}}</td>
        <td colspan="2" class="right">{{item.netHeight}}</td>
        <td colspan="4" class="right">{{item.netMeasurementTotal}}</td>
        <td colspan="2" class="right">{{item.grossLength}}</td>
        <td colspan="2" class="right">{{item.grossWidth}}</td>
        <td colspan="2" class="right">{{item.grossHeight}}</td>
        <td colspan="2">{{item.lwhUnit}}</td>
        <td colspan="2" class="right">{{item.grossMeasurementTotal}}</td>
        <td colspan="4">{{item.measurementUnit}}</td>
      </tr>
    </template>
    <template v-if="page === -1">
      <tr>
        <th colspan="6">Net Weight Total</th>
        <th colspan="6">Gross Weight Total</th>
        <th colspan="6">Net Measurement Total</th>
        <th colspan="6">Gross Measurement Total</th>
      </tr>
      <tr>
        <td colspan="6" class="right">{{netWeightTotal}}</td>
        <td colspan="6" class="right">{{grossWeightTotal}}</td>
        <td colspan="6" class="right">{{netMeasurementTotal}}</td>
        <td colspan="6" class="right">{{grossMeasurementTotal}}</td>
      </tr>
    </template>
  </table>
</template>

<script>
import _ from 'lodash';
import BigNumber from 'bignumber.js';
import { formatQuantity } from '@/utils/searchUtil.js';

export default {
  name: 'TwPlPdfGoodsLineTable',
  inject: ['s'],
  props: {
    items: Array,
    page: Number,
    last: Boolean,
  },
  data() {
    return {
    };
  },
  computed: {
    netWeightTotal() {
      return formatQuantity(_.get(this.s.res, 'linkageTransportation.weightMeasurementGrp.totalWeightNet'), _.get(this.s.res, 'linkageTransportation.weightMeasurementGrp.weightUnit'));
    },
    grossWeightTotal() {
      return formatQuantity(_.get(this.s.res, 'linkageTransportation.weightMeasurementGrp.totalWeightGross'), _.get(this.s.res, 'linkageTransportation.weightMeasurementGrp.weightUnit'));
    },
    netMeasurementTotal() {
      const measurementTotal = _.compact(_.map(this.s.res.linkageGoodsLinePacking, packing => {
        return packing.netGrossMeasurementGrp.netMeasurementTotal;
      }));

      const result = BigNumber.sum.apply(null, measurementTotal).dp(6).toNumber();
      return formatQuantity(result, _.get(this.s.res, 'linkageTransportation.weightMeasurementGrp.measurementUnit'));
    },
    grossMeasurementTotal() {
      return formatQuantity(_.get(this.s.res, 'linkageTransportation.weightMeasurementGrp.totalMeasurement'), _.get(this.s.res, 'linkageTransportation.weightMeasurementGrp.measurementUnit'), 6);
    },
  },
};
</script>