<template>
  <div v-loading='isLoading' class='edit-attached-files-section attachment'
       @dragover.prevent='dragEnter'
       @dragleave.prevent='dragLeave'
       @drop.prevent='dropFile'
       :class='{enter: dragin}'
  >
    <div class='sub-heading'>
      <tw-button type='secondary' size='medium' class='choose_file' @click='loadFile'>
        Choose Local File
        <input ref='fileInput' class='file_input' @change='setFile' type='file' multiple
               :accept="'.' + WHITE_LIST.join(',.')">
      </tw-button>
    </div>

    <!--  Files from API  -->
    <el-form :model='documentsForm' ref='documentsForm' @validate='onValidate'>
      <template v-for='(doc, index) in documentsForm.existingDocuments'>
        <div v-if='doc.documentEditFlg !== DOCUMENT_EDIT_FLG.DELETE' class='file' :key='`existing-files-${index}`'>
          <div class='inner'>
            <div class='user'>
              <figure v-if='doc.updateUserIcon' class='avatar table'><img :src='doc.updateUserIcon' alt=''></figure>

              <div class='from_name'>{{ doc.updateUserName }}</div>
            </div>

            <el-tooltip placement='top-start' popper-class='mcu' :tabindex='-1'>
              <div slot='content'>{{ doc.documentName }}</div>

              <span>
                <a class='file_name' @click.prevent='download(doc)' tabindex='-1'>{{ doc.documentName }}</a>
              </span>
            </el-tooltip>

            <span class='meta'>{{ doc.documentSize | fileSize }},&nbsp;{{ doc.updateDate | dateTimeFormat }}</span>

            <el-tooltip placement='top' popper-class='mcu' :tabindex='-1'>
              <div slot='content'>PDF Preview</div>

              <img v-if='doc.documentEditFlg !== DOCUMENT_EDIT_FLG.DELETE && doc.documentPath && s.isPdf(doc)'
                   @click='s.previewPdf(doc)' style='cursor:pointer;margin-left: 16px'
                   src='@/assets/images/icons/icon_pdf_preview.svg' alt=''>
            </el-tooltip>
          </div>

          <el-form-item :prop='`existingDocuments[${index}].documentCommentText`'
                        :rules="[{ max: 100, message: 'Free Textは100文字以内で入力してください', trigger: 'blur' }]">
            <el-input type='textarea' :autosize='{ minRows: 1, maxRows: 6}' v-model='doc.documentCommentText'
                      @change='onCommentChange(doc, index)'
                      maxlength='100' show-word-limit />
          </el-form-item>

          <!-- Todo: 削除フラグの動作確認 -->
          <img v-if='doc.documentDeletable === DELETE_ABLE_FLG.OK'
               class='close_times' src='@/assets/images/icons/times_black.svg'
               @click.stop.prevent='removeAttachedFile(doc, index)' alt=''>
        </div>
      </template>

      <!--  Uploaded files  -->
      <template v-for='(doc, index) in documentsForm.uploadedDocuments'>
        <div class='file uploaded-files' :key='`uploaded-files-${index}`'>
          <div class='inner'>
            <el-tooltip placement='top-start' popper-class='mcu' :tabindex='-1'>
              <div slot='content'>{{ doc.documentName }}</div>

              <span>
                  <a v-if='doc.documentValue' class='file_name' :href='doc.documentValue' :download='doc.documentName'>
                    {{ doc.documentName }}</a>
                  <a v-else class='file_name' @click.prevent='download(doc)' tabindex='-1'>{{ doc.documentName }}</a>
                </span>
            </el-tooltip>

            <span class='meta'>{{ doc.documentSize | fileSize }},&nbsp;{{ doc.updateDate | dateTimeFormat }}</span>

            <el-tooltip placement='top' popper-class='mcu' :tabindex='-1'>
              <div slot='content'>PDF Preview</div>
              <img v-if='s.isPdf(doc)' @click='s.previewPdf(doc)' style='cursor:pointer;margin-left: 16px'
                   src='@/assets/images/icons/icon_pdf_preview.svg' alt=''>
            </el-tooltip>
          </div>

          <el-form-item :prop='`uploadedDocuments[${index}].documentCommentText`'
                        :rules="[{ max: 100, message: 'Free Textは100文字以内で入力してください', trigger: 'blur' }]">
            <el-input type='textarea' :autosize='{ minRows: 1, maxRows: 6}' v-model='doc.documentCommentText'
                      maxlength='100' show-word-limit />
          </el-form-item>

          <img class='close_times' src='@/assets/images/icons/times_black.svg' @click.stop.prevent='removeFile(index)'
               alt=''>
        </div>
      </template>
    </el-form>

    <div class='drag_and_drop_area'>
      <img src='@/assets/images/dropfile.svg' alt=''>
      Drop files here to upload.
    </div>

    <div v-if='errorMessages' style='margin-top: 40px'>
      <el-alert
        title='Error'
        type='error'
      >
        <ul>
          <li v-for='(item, index) in errorMessages' :key='index' class=''>
            {{ item.message }}
          </li>
        </ul>
      </el-alert>
    </div>

    <div style='display: flex;justify-content: flex-end;padding-top: 16px'>
      <tw-button type='primary' size='medium' @click='register' :disabled="!fileActionOccurred">Register</tw-button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import {
  ATTACH_FILE_EXTENSION_CO_VARIABLES,
  ATTACH_FILE_EXTENSION_VARIABLES,
  PROCESS_TYPE, DOCUMENT_EDIT_FLG, EDITABLE_FLG, CO_DOCUMENT_ID_VARIABLES, DELETE_ABLE_FLG
} from 'lib-tw-common';
import TwButton from '@/components/atoms/TwButton';
import { $api } from '../../store/ApiClient';

export default {
  name: 'TwFileUploadSection',
  inject: ['s'],
  components: {
    TwButton
  },
  data() {
    return {
      WHITE_LIST: [],
      DOCUMENT_EDIT_FLG,
      EDITABLE_FLG,
      CO_DOCUMENT_ID_VARIABLES,
      DELETE_ABLE_FLG,
      documentsForm: {
        uploadedDocuments: [],
        existingDocuments: []
      },
      dragin: false,
      isLoading: false,
      initialUpdateDate: null,
      pathParams: {
        entityId: this.s.entityId,
        processId: this.s.processId,
        processSeq: this.s.processSeq
      },
      getParams: {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/process-documents/{entityId}/{processId}/{processSeq}'
        }
      },
      postParams: {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'post_/v1/process-documents/{entityId}/{processId}/{processSeq}'
        },
        data: {
          meta: {
            updateDate: null,
          },
          documents: undefined
        }
      },
      errorMessages: null,
      fileActionOccurred: false
    };
  },
  created() {
    // CO申請のみPDFファイルのみを受け付ける
    this.WHITE_LIST = (this.processType === PROCESS_TYPE.FDCOR) ? _.uniq(_.map(ATTACH_FILE_EXTENSION_CO_VARIABLES, o => o.label.toLowerCase())) : _.uniq(_.map(ATTACH_FILE_EXTENSION_VARIABLES, o => o.label.toLowerCase()));
  },
  methods: {
    resetDialog() {
      this.documentsForm.uploadedDocuments = [];
      this.documentsForm.existingDocuments = [];
      this.errorMessages = null;
    },
    // Fetch existing files
    fetchUploadedFiles() {
      this.isLoading = true;
      this.fileActionOccurred = false;

      const params = _.cloneDeep(this.getParams);
      params.lslConfig.path = this.pathParams;

      $api.request(params).then(response => {
        if (response?.documents) {
          this.documentsForm.existingDocuments = response?.documents;
        }
        if (response?.meta) {
          this.initialUpdateDate = response.meta.updateDate;
        }
      })
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
          if (this.$refs.twSearchFieldRef) this.$refs.twSearchFieldRef.onClearAllFiltersClick();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // Post new files and updated files
    postFiles() {
      this.loading = true;

      const params = _.cloneDeep(this.postParams);
      params.lslConfig.path = this.pathParams;

      const existingDocumentsRequestBody = this.documentsForm.existingDocuments.map(document => {
        return {
          documentEditFlg: document.documentEditFlg,
          documentManageId: document.documentManageId,
          documentTrx: document.documentTrx,
          documentName: document.documentName,
          documentValue: document.documentValue,
          documentCommentText: document.documentCommentText
        };
      });

      const uploadedDocumentsRequestBody = this.documentsForm.uploadedDocuments.map(document => {
        return {
          documentEditFlg: document.documentEditFlg,
          documentName: document.documentName,
          documentValue: document.documentValue,
          documentCommentText: document.documentCommentText
        };
      });

      params.data.documents = [...existingDocumentsRequestBody, ...uploadedDocumentsRequestBody];
      params.data.meta.updateDate = this.initialUpdateDate;

      this.$store.commit('START_PROCESS');
      return $api.request(params)
        .then(response => {
          if (response) {
            this.$emit('onSuccess');
            this.$emit('update-attached-files');
            this.$store.commit('END_PROCESS');
            this.$store.dispatch('SHOW_COMPLETED');
          }
        })
        .catch(err => {
          this.$store.commit('END_PROCESS');
          if (Array.isArray(err.response?.errors)) {
            this.errorMessages = err.response.errors;
            throw err;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    dragEnter() {
      this.dragin = true;
    },
    dragLeave() {
      this.dragin = false;
    },
    dropFile(e) {
      this.dragin = false;
      this.setFile(e);
    },
    loadFile() {
      this.$refs.fileInput.click();
    },
    setFile(e) {
      // PB-734 ファイルが選択されたことを起因にファイル操作発生フラグをtrue
      this.fileActionOccurred = true;

      const files = _.get(e, 'target.files[0]') ? e.target.files : e.dataTransfer.files;
      const _file = _.get(e, 'target.files[0]') || _.get(e, 'dataTransfer.files[0]');
      if (!_file) {
        return;
      }
      if (files.length + this.documentsForm.uploadedDocuments.length > 10) {
        this.$store.dispatch('SHOW_ALERT', 'Maximum number of files[10] exceeded.');
        return;
      }
      if (_.some(files, file => {
        if (file.name.length > 100) {
          this.$store.dispatch('SHOW_ALERT', 'Maximum length of file name[100(including extensions)] exceeded.');
          return true;
        }
      })) {
        return;
      }
      if (_.some(files, file => {
        if (file.size > 10485760) {
          this.$store.dispatch('SHOW_ALERT', 'Your file exceeds the maximum upload size: 10.0MB');
          return true;
        }
      })) {
        return;
      }
      if (_.some(files, file => {
        if (!this.WHITE_LIST.includes(this.s.getFileExtension(file.name))) {
          this.$store.dispatch('SHOW_ALERT', `Only files with the following extensions are allowed: ${this.WHITE_LIST.join(',')}`);
          return true;
        }
      })) {
        return;
      }

      if (typeof FileReader === 'function') {
        _.forEach(files, file => {
          const reader = new FileReader();
          reader.onload = event => {
            this.documentsForm.uploadedDocuments.push({
              documentSize: file.size,
              documentName: file.name,
              documentValue: event.target.result,
              documentEditFlg: DOCUMENT_EDIT_FLG.REGIST,
              documentCommentText: ''
            });
            e.target.value = '';
          };
          reader.readAsDataURL(file);
        });
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    // 添付ファイル削除（登録前）
    removeFile(index) {
      this.$store
        .dispatch('SHOW_CONFIRM', 'Are you sure you want to delete this file?')
        .then(() => {
          this.documentsForm.uploadedDocuments.splice(index, 1);
        })
        .catch(() => {
        });
    },
    // 添付ファイル削除（登録後）
    removeAttachedFile(doc, index) {

      this.$store
        .dispatch('SHOW_CONFIRM', 'Are you sure you want to delete this file?')
        .then(() => {
          // PB-734 ファイルが削除されたことを起因にファイル操作発生フラグをtrue
          this.fileActionOccurred = true;

          doc.documentEditFlg = DOCUMENT_EDIT_FLG.DELETE;
          this.$set(this.documentsForm.existingDocuments, index, doc);
        })
        .catch(() => {
        });
    },
    onCommentChange(doc, index) {
      // PB-734 コメントが修正されたことを起因にファイル操作発生フラグをtrue
      this.fileActionOccurred = true;

      doc.documentEditFlg = DOCUMENT_EDIT_FLG.UPDATE;
      this.$set(this.documentsForm.existingDocuments, index, doc);
    },
    documentsValidation() {
      return new Promise((resolve, reject) => {
        this.$refs.documentsForm.validate((valid) => {
          if (valid) {
            resolve();
          } else {
            this.$store.dispatch('SHOW_ALERT', 'Please check the input and try again.');
            reject();
          }
        });
      });
    },
    onValidate() {
      requestAnimationFrame(() => {
        const files = this.$refs.documentsForm.$el.querySelectorAll('.file');
        files.forEach(el => {
          if (el.querySelector('.is-error')) {
            el.classList.add('has_error');
          } else {
            el.classList.remove('has_error');
          }
        });
      });
    },
    register() {
      this.documentsValidation()
        .then(() => {
          this.postFiles();
        })
        .catch(() => {
          return false;
        });
    }
  }
};
</script>
<style lang='scss' scoped>
.edit-attached-files-section {
  position: relative;
  font-size: 14px;
  line-height: 20px;

  .sub-heading {
    display: flex;
    justify-content: flex-end;
    margin: 16px auto;
  }

  .file {
    position: relative;
    margin-top: 12px;
    border-radius: 6px;
    padding: 12px 48px 12px 16px;
    background: $color_gray_100;

    &:first-child {
      margin-top: 12px;
    }

    &.has_error {
      background: rgba(224, 0, 1, 0.03);
    }

    &.uploaded-files {
      background: rgba($color_dark_blue, 0.1);
    }

    .user {
      max-width: 100%;
      display: flex;
      align-items: center;

      font-size: 12px;
      line-height: 20px;
      color: $color_black;

      .from_name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .close_times {
      position: absolute;
      width: 24px;
      height: 24px;
      top: 50%;
      right: 16px;
      margin-top: -12px;
      cursor: pointer;
    }
  }

  .meta {
    flex-shrink: 0;
    margin-left: 8px;
  }

  a.file_name {
    color: $color_dark_blue;
    cursor: pointer;
    text-decoration: none;
  }

  .el-form-item {
    margin: 0;
  }

  .el-textarea, .el-select.document_id {
    display: block;
    width: 100%;
    margin-top: 8px;
  }

  ::v-deep .el-input__inner {
    background: white;
  }

  ::v-deep .el-textarea__inner {
    background: white;
    resize: none;
  }

  ::v-deep .el-form-item__error {
    position: relative;
  }

  ::v-deep .el-textarea.is-disabled .el-textarea__inner {
    background: $color_gray_200;
    color: $color_black;
    border: 1px solid $color_gray_300;
  }

  .choose_file {
    position: relative;
    display: inline-block;

    .file_input {
      position: absolute;
      width: 1px;
      height: 1px;
      z-index: -1;
      pointer-events: none;
    }
  }

  .drag_and_drop_area {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 138px;
    margin-top: 24px;
    background: $color_gray_100;
    border-radius: 6px;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    color: $color_dark_blue;
    pointer-events: none;
    z-index: 2;

    img {
      display: block;
      width: 84px;
      height: auto;
      margin-bottom: 9px;
    }
  }

  &.enter > * {
    pointer-events: none;
  }

  &.enter .drag_and_drop_area {
    border: 1px dashed $color_dark_blue;
  }
}
</style>
