import { render, staticRenderFns } from "./ProcessDetail.html?vue&type=template&id=7da4dd52&scoped=true&"
import script from "./ProcessTPRBL.vue?vue&type=script&lang=js&"
export * from "./ProcessTPRBL.vue?vue&type=script&lang=js&"
import style0 from "./ProcessDetail.scss?vue&type=style&index=0&id=7da4dd52&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7da4dd52",
  null
  
)

export default component.exports