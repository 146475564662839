const printStyles = `
<!-- 
<style type="text/css" media="screen">
.pdfpage {
  display: none;
}
</style>
<style type="text/css" media="print">
-->
<style type="text/css" media="screen, print">
  table {
    width: 100%;
    border-collapse: collapse;
    border: none;
    table-layout: fixed;
  }

  thead td {
    border: none;
  }

  tbody {
    border: 1px solid #333;
  }

  td, th {
    border: 1px solid #333;
    text-align: left;
    padding: 5px;
    font-size: 10px;
    word-break: break-all;
    vertical-align: top;
    line-height: 1.4;
  }

  h1 {
    text-align: center;
    font-size: 2em;
    margin: 1.5em 0 1em;
  }

  h2 {
    font-weight: bold;
    font-size: 1.16em;
    text-align: center;
    margin: 0.25em 0;
  }

  h3 {
    font-weight: bold;
    font-size: 1em;
    text-align: center;
    margin: 0 0 0.5em;
  }

  b {
    display: inline-block;
    font-weight: bold;
    font-size: 1.16em;
    margin-bottom: 0.5em;
  }

  thead b {
    font-size: 1.5em;
    margin-bottom: 0;
  }

  td.goods_table_wrap {
    padding: 0;
  }

  table.goods_table {
    table-layout: auto;
  }

  table.goods_table, table.goods_table thead, table.goods_table tbody {
    border: 0;
  }

  table.goods_table tr:first-child td, table.goods_table tr:first-child th {
    border-top: 0;
  }

  table.goods_table tr:last-child td, table.goods_table tr:last-child th {
    border-bottom: 0;
  }

  table.goods_table tr.expense_total td, table.goods_table tr.expense_total th {
    border-bottom: 1px solid #333;
  }

  table.goods_table tr td:first-child, table.goods_table tr th:first-child {
    border-left: 0;
  }

  table.goods_table tr td:last-child, table.goods_table tr th:last-child {
    border-right: 0;
  }

  table.goods_table th, table.goods_table td {
    font-size: 10px;
    font-weight: normal;
    height: 22px;
  }

  table.goods_table th {
    background: #dedede;
    text-align: center;
    vertical-align: middle;
    word-break: normal;
  }

  table.goods_table td {
    text-align: left;
  }

  table.goods_table td.right {
    text-align: right;
  }

  table.goods_table td.nowrap {
    white-space: nowrap;
  }

  table.goods_table tr.goods_tr:nth-child(even) td {
    background: #f9f9f9;
  }

  table.goods_table tr.expenses td, table.goods_table tr.expenses th, table.goods_table.attach_page tr.goods_tr:last-child td {
    border-bottom: 1px solid #333;
  }
  
  .invoice_body {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .pdfpage {
    display: block;
    /*A4縦*/
    width: 172mm;
    height: 251mm;
    page-break-after: always;
    line-height: 1.4;
  }
    /*最後のページは改ページを入れない*/
  .pdfpage:last-child {
    page-break-after: auto;
  }
  /* A4縦用のベース設定 */
  @page {
    size: A4 portrait; /* 横の場合はlandscape */
    margin: 0mm 0mm;
  }


  /* 各要素の余白リセット */
  * {
    margin: 0mm;
    padding: 0mm;
  }

  /* 印刷時の調子を整える */
  body {
    width: 210mm; /* 用紙の横幅を改めて指定 Chrome用 */
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    font-family: 'Times New Roman', Times, serif;
    line-height: 1.4;
    height: auto;
  }

  /* 印刷1ページ用のコンテンツはここで定義 */
  .pdfpage {
    width: 210mm; /* 用紙の横幅を改めて指定 */
    page-break-after: always;
    min-height: 296.5mm;
    height: auto; /* 高さには0.5mm余裕をもたせる */
    box-sizing: border-box;
    padding: 0;
    /* font-size: 10pt; */
  }

  .pdfpage .inner {
    padding: 10mm 15mm; /* 用紙の余白 */
  }
  }
</style>
`;

export default printStyles;