<template>
  <div class="tw_approval_list permit_history">
    <tw-collapse title="I/P, D/N" :initialOpen="items && items.length > 0">
      <div class="approval_list">
        <div class="approval_list_item" v-for="(item, index) in items" :key="index">
          <header>
            <div v-if="item.updateUserName" class="column">
              <div class="user">
                <figure class="avatar table"><img :src="item.updateUserIcon"></figure>
                <el-tooltip placement="top" popper-class="mcu" :tabindex="-1">
                  <div slot="content">{{item.updateUserName}}</div>
                  <div class="from_name">{{item.updateUserName}}</div>
                </el-tooltip>
              </div>
              <div class="between">
                <div class="date">{{item.updateDate | dateTimeFormat}}</div>
                <div class="action">
                  <img v-if="checkLatest(item) && item.ipDnLinkFlg === IP_DN_LINK_FLG.LINKED" src="@/assets/images/icons/link_chain_on.svg">
                  <img v-else-if="item.ipDnLinkFlg === IP_DN_LINK_FLG.LINKED" src="@/assets/images/icons/link_chain_on_gray.svg">
                  <img v-else src="@/assets/images/icons/link_chain_off.svg">
                </div>
              </div>
              <div class="ip_dn" :class="{ linking: checkLatest(item) && item.ipDnLinkFlg === IP_DN_LINK_FLG.LINKED }" @click="openDrawer(item)">
                <div class="left">
                  <div class="badge">
                    <span v-show="item.docType === 'IP'">I/P</span>
                    <span v-show="item.docType === 'DN'">D/N</span>
                  </div>
                </div>
                <div class="right">
                  <div class="detail">
                    <div>I/P No : {{item.insurancePolicyNo}}</div>
                    <div>Branch No : {{item.insurancePolicyBranchNo}}</div>
                    <div>{{getType(item.ipDnType)}}</div>
                  </div>
                  <figure v-if="checkLatest(item) && item.ipDnAttachedFileFlg" class="pdf"><img src="@/assets/images/icons/icon_attach_file.svg"></figure>
                  <figure v-else-if="item.ipDnAttachedFileFlg" class="pdf"><img src="@/assets/images/icons/icon_attach_file_off.svg"></figure>
                </div>
              </div>
            </div>
          </header>
        </div>
        <div v-if="!items.length" class="empty_message">{{$t('Message.NoResult1')}}</div>
      </div>
    </tw-collapse>

    <el-drawer :visible.sync="drawerShow" size="auto" :modal="false" :withHeader="false">
      <tw-ip-dn-archive-detail-inner v-if="drawerShow" @close="close" :item="item" :tradeManagement="tradeManagement" :documents="documents" />
      <div v-if="drawerShow" class="circle" @click="close" />
    </el-drawer>
  </div>
</template>

<script>
import _ from 'lodash';
import TwCollapse from '@/components/molecules/TwCollapse';
import TwIpDnArchiveDetailInner from '@/components/templates/TwIpDnArchiveDetailInner';
import { IP_DN_LINK_FLG, IP_DN_TYPE_VARIABLES } from 'lib-tw-common';

export default {
  name: 'TwIpDnHistory',
  components: {
    TwCollapse,
    TwIpDnArchiveDetailInner
  },
  inject: ['s'],
  data() {
    return {
      drawerShow: false,
      drawerItem: {},
      item: {},
      IP_DN_LINK_FLG: IP_DN_LINK_FLG
    };
  },
  computed: {
    items() {
      return _.get(this, 's.res.ipDnHistory') || [];
    },
    tradeManagement() {
      return _.get(this, 's.res.tradeManagement', {});
    },
    ipDnLatest() {
      return _.get(this, 's.res.ipDnLatest') || [];
    },
    documents() {
      return _.get(this, 's.res.documents') || [];
    }
  },
  created() {},
  methods: {
    openDrawer(item) {
      if (!(this.checkLatest(item) && item.ipDnLinkFlg === IP_DN_LINK_FLG.LINKED)) return;
      this.item = item;
      this.drawerShow = true;
    },
    close() {
      this.drawerShow = false;
    },
    getType(code) {
      return _.get(_.find(IP_DN_TYPE_VARIABLES, { code: code }), 'label', '');
    },
    // 最新の紐付けに含まれるかどうかの判定
    checkLatest(item) {
      return _.some(this.ipDnLatest, ipdn => {
        return ipdn.docType === item.docType && ipdn.senderCompanyId === item.senderCompanyId && ipdn.insurancePolicyNo === item.insurancePolicyNo && ipdn.insurancePolicyBranchNo === item.insurancePolicyBranchNo && ipdn.ipDnType === item.ipDnType && ipdn.trx === item.trx;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.tw_approval_list {
  margin-bottom: 16px;
  .approval_list {
    max-height: 410px;
    overflow-y: auto;
    padding: 0 16px;
  }
  .approval_list_item {
    border-bottom: 1px solid $color_gray_300;
    padding: 12px;
  }
  header {
    display: flex;
    justify-content: space-between;
    .column {
      width: 100%;
    }
    .user {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      font-size: 12px;
      line-height: 20px;
      color: $color_black;
      .from_name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .between {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
    }
    .date,
    .action {
      font-size: 10px;
      line-height: 14px;
      color: $color_gray_600;
    }
    .action {
      display: flex;
      align-items: center;
      color: $color_dark_blue;
      > img {
        margin: 0 2px 0 8px;
      }
    }
  }
  .empty_message {
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: $color_black;
    margin-top: 33px;
    margin-bottom: 20px;
  }

  .ip_dn {
    font-size: 12px;
    display: flex;
    color: $color_gray_600;
    &.linking {
      color: $color_dark_blue;
      cursor: pointer;
    }
    .left {
      margin-right: 8px;
      .badge {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 16px;
        left: 0px;
        top: 0px;
        background: #ff7c9c;
        color: $color_white;
        border-radius: 15.5px;
        font-size: 10px;
        font-weight: bold;
      }
    }
    .right {
      flex: auto;
      display: flex;
      justify-content: space-between;
      .pdf {
        margin: 0 0 0 4px;
      }
    }
  }
}
::v-deep .el-drawer__wrapper {
  background: rgba(0, 0, 0, 0.2);
  .el-drawer__body {
    width: auto;
    width: 990px;
    > .permit_detail_inner {
      padding: 30px 40px 30px 35px;
    }
  }
  .el-drawer,
  .el-drawer__body {
    overflow: visible;
  }

  .circle {
    position: absolute;
    top: 14px;
    left: -18px;
    width: 18px;
    height: 29px;
    background: $color_gray_800 url(../../assets/images/icons/chevron_right_white.svg) no-repeat 3px center;
    background-size: 16px auto;
    border-radius: 15px 0 0 15px;
    z-index: 11;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
}
</style>
