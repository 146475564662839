<template>
  <div class="parent" :class="{open: open}">
    <div class="key_wrap" :class="{ is_object_array: isObjectOrArray(value) }" @click="toggle">
      <img v-show="isObjectOrArray(value)" class="arrow" src="@/assets/images/icons/accordion_arrow.svg">
      <span class="key">{{getLabel(parentKey)}}</span>
      <span v-if="isStringOrNumber(value)">{{value}}</span>
    </div>

    <transition
      name="content"
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave"
      @after-leave="afterLeave"
    >
      <template v-if="isObjectOrArray(value)">
        <div v-show="open">
          <TwPreviewJsonChild
            v-for="(value1, key1, index1) in value"
            :key="index1"
            :value="value1"
            :parentKey="parentKey"
            :childKey="key1"
            :isArrayParent="isArray(value)"
            :initOpen="initOpen"
            :getLabel="getLabel"
          />
        </div>
      </template>
    </transition>
  </div>
</template>

<script>
import _ from 'lodash';
import { collapseTransition } from '@/utils/nextFrame';
// import bookingWebhook from '@/../public/bookingWebhook.json';
import TwPreviewJsonChild from '@/components/molecules/TwPreviewJsonChild';

export default {
  name: 'TwPreviewJsonParent',
  components: {
    TwPreviewJsonChild,
  },
  props: {
    parentKey: [Number, String],
    value: [Object, Array, Number, String, Boolean],
    initOpen: {
      Type: Boolean,
      default: true,
    },
    getLabel: Function,
  },
  mixins: [collapseTransition],
  data() {
    return {
      open: this.initOpen,
      // items: bookingWebhook,
    }
  },
  computed: {},
  created() {
    this.$parent.$on('openAll', this.openAll);
    this.$parent.$on('closeAll', this.closeAll);
  },
  beforeDestroy() {
    this.$parent.$off('openAll', this.openAll);
    this.$parent.$off('closeAll', this.closeAll);
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    openAll() {
      this.open = true;
      this.$emit('openAll');
    },
    closeAll() {
      this.open = false;
      this.$emit('closeAll');
    },
    isObjectOrArray(value) {
      return value !== null && typeof value === 'object';
    },
    isObject(value) {
      return value !== null && typeof value === 'object' && Array.isArray(value) === false;
    },
    isArray(value) {
      return _.isArray(value);
    },
    isStringOrNumber(value) {
      if (value === null) return false;
      else return _.isString(value) || _.isNumber(value);
    },
  }
};
</script>
